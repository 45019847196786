import axios from 'axios';

import { ENDPOINT_BASE } from 'constants/index';
import { CreateLoanExcelDocumentRequest } from './types';
import qs from 'qs';

const EXCEL_PATH = 'excel';
const baseUrl = `${ENDPOINT_BASE}`;

const client = axios.create({
  withCredentials: true,
  baseURL: baseUrl,
  responseType: 'blob',
  headers: {
    'Content-Type': 'application/json',
  },
});

export async function createLoanExcelDocument(
  request: CreateLoanExcelDocumentRequest
): Promise<Blob> {
  if (!request) {
    throw new Error('request is missing');
  }

  const cellDataJsonString = JSON.stringify(request.cellData);

  const query: string = request.query ? request.query : '';

  const response = await client.get(
    `${EXCEL_PATH}/loan?${qs.stringify(
      {
        criteria: query,
        sort: request.sort,
        cellData: cellDataJsonString,
      },
      { arrayFormat: 'indices' }
    )}`,
    {
      responseType: 'blob',
      data: request.sort,
    }
  );
  return response.data;
}
