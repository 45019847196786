import axios, { AxiosResponse } from 'axios';
import { ENDPOINT_BASE } from 'constants/index';

const AUTHENTICATION_PATH = 'authentication';
const AUTHENTICATED_PATH = 'authenticated';

const baseUrl = `${ENDPOINT_BASE}${AUTHENTICATION_PATH}`;

const client = axios.create({
  withCredentials: true,
  baseURL: baseUrl,
});

export type AuthenticationStatusResponse = {
  authenticated: boolean;
};

export async function authenticationStatus(): Promise<
  AxiosResponse<AuthenticationStatusResponse>
> {
  return client.get<AuthenticationStatusResponse>(`/${AUTHENTICATED_PATH}`);
}
