import React, { useState } from 'react';
import {
  useDeleteSetting,
  useGetSettings,
  useUpdateSetting,
} from 'hooks/withSetting';
import { Button, Modal, Popconfirm, Table } from 'antd';
import { Setting } from 'api/types';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import SettingForm from 'components/forms/SettingForm';
import { getDateStringInLocalTime } from 'utils/date';

const ListSettingsView: React.FC = () => {
  const [selectedSetting, setSelectedSetting] = useState();

  const [updateSetting, updateSettingStatis] = useUpdateSetting();

  const deleteSetting = useDeleteSetting();

  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);

  const handleDeleteSetting = (id) => {
    deleteSetting.mutate(id);
  };

  const handleEditSetting = (setting) => {
    setSelectedSetting(setting);
    setIsModalOpened(true);
  };

  const onUpdateSetting = (setting) => {
    updateSetting(setting);
    setIsModalOpened(false);
  };

  const onCancelUpdateSetting = (setting) => {
    setIsModalOpened(false);
  };

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Created',
      dataIndex: 'created',
      key: 'created',
      render: (data) => getDateStringInLocalTime(data),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      render: (loan) => (
        <div className="flex flex-row">
          <Popconfirm
            title="Please confirm"
            description="Are you sure you want to delete this setting?"
            onConfirm={() => handleDeleteSetting(loan.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">
              <DeleteOutlined style={{ color: 'red' }} />
            </Button>
          </Popconfirm>
          <Button type="link" onClick={() => handleEditSetting(loan)}>
            <EditOutlined />
          </Button>
        </div>
      ),
    },
  ];

  const [settings, isSettingsLoading] = useGetSettings();

  return (
    <div className="mt-10">
      <h1 className="mb-0 py-5">List settings</h1>
      <div className="flex flex-col mt-5">
        <Table
          dataSource={settings}
          loading={isSettingsLoading}
          columns={columns}
          pagination={{ defaultPageSize: 10 }}
          rowKey={(setting: Setting) => setting.id}
        />
        <Modal
          open={isModalOpened}
          onCancel={onCancelUpdateSetting}
          width={800}
          footer={null}
          destroyOnClose
          title="Update Setting"
        >
          <SettingForm
            initialData={selectedSetting}
            onUpdate={onUpdateSetting}
            onCreate={undefined}
          />
        </Modal>
      </div>
    </div>
  );
};
export default ListSettingsView;
