import {
  Button,
  Col,
  Divider,
  Row,
  Select,
  SelectProps,
  Table,
  Tabs,
} from 'antd';
import {
  useDaysInterestReports,
  useMonthInterestReports as useMonthsInterestReports,
  useQuarterInterestReports,
  useYearInterestReports,
} from 'hooks/withLoan';
import {
  DayReport,
  Journal,
  Loan,
  MonthReport,
  QuarterReport,
  ReportType,
  YearReport,
} from 'api/types';
import LoanReportStatistic from '../../../components/LoanReportStatistic';
import LoanReportBarChart from '../../../components/LoanReportBarChart';
import React, { useCallback, useEffect, useState } from 'react';
import { KeyboardDoubleArrowLeft } from '@mui/icons-material';
import {
  isDay,
  isMonth,
  isQuarter,
  isTopLevelReport,
  isYear,
} from 'utils/reports';
import { useEffectOnce } from 'react-use';
import LoanEventsTable from 'components/LoanEventsTable';
import { getDateString } from 'utils/date';
import NumberFormat, {
  NumberFormatConfig,
} from 'components/NumberFormat/NumberFormat';

const numberFormatConfig: NumberFormatConfig = {
  decimalScale: 2,
  decimalSeparator: ',',
  thousandSeparator: ' ',
};

type ListLoansExpandedProps = {
  loan: Loan;
};

enum Period {
  NONE,
  DAYS,
  MONTHS,
  QUARTERS,
  YEARS,
}

function removeDuplicates(arr: Journal[]): Journal[] {
  return arr.reduce(function (acc: Journal[], curr: Journal) {
    if (!acc.includes(curr)) acc.push(curr);
    return acc;
  }, []);
}

const options: SelectProps['options'] = [
  {
    value: 'MONTHS',
    label: 'Months',
  },
  {
    value: 'QUARTERS',
    label: 'Quarters',
  },
  {
    value: 'YEARS',
    label: 'Years',
  },
];

const columns = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Account',
    dataIndex: 'account',
    key: 'account',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Debit',
    dataIndex: 'debit',
    key: 'debit',
    render: (debit) => (
      <NumberFormat config={numberFormatConfig} inputValue={debit} />
    ),
  },
  {
    title: 'Credit',
    dataIndex: 'credit',
    key: 'credit',
    render: (credit) => (
      <NumberFormat config={numberFormatConfig} inputValue={credit} />
    ),
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Created',
    dataIndex: 'created',
    key: 'created',
    render: (data) => getDateString(data),
  },
];

const ListLoansExpandedView: React.FC<ListLoansExpandedProps> = ({ loan }) => {
  const [selectedReport, setSelectedReport] = useState<ReportType>();
  const [selectedPeriod, setSelectedPeriod] = useState<Period>(Period.NONE);
  const [selectedReports, setSelectedReports] = useState<ReportType[]>([]);
  const [selectedReportIndex, setSelectedReportIndex] = useState<number>(0);
  const [selectedJournalEntries, setSelectedJournalEntries] = useState<
    Journal[] | undefined
  >();

  const [daysEnabled, setDaysEnabled] = useState<boolean>(false);
  const [monthsEnabled, setMonthsEnabled] = useState<boolean>(false);
  const [quartersEnabled, setQuartersEnabled] = useState<boolean>(false);
  const [yearsEnabled, setYearsEnabled] = useState<boolean>(false);

  useEffect(() => {
    if (selectedPeriod == Period.DAYS) {
      setDaysEnabled(true);
    }
    if (selectedPeriod == Period.MONTHS) {
      setMonthsEnabled(true);
    }
    if (selectedPeriod == Period.QUARTERS) {
      setQuartersEnabled(true);
    }
    if (selectedPeriod == Period.YEARS) {
      setYearsEnabled(true);
    }
  }, [selectedPeriod]);

  const [daysInterestReport, isDaysInterestReportLoading] =
    useDaysInterestReports(() => daysEnabled, loan.id);

  const [monthInterestReport, isMonthInterestReportLoading] =
    useMonthsInterestReports(() => monthsEnabled, loan.id);

  const [quarterInterestReport, isQuarterInterestReportLoading] =
    useQuarterInterestReports(() => quartersEnabled, loan.id);

  const [yearInterestReport, isYearInterestReportLoading] =
    useYearInterestReports(() => yearsEnabled, loan.id);

  const [activeJournals, setActiveJournals] = useState<Journal[]>([]);

  const isLoadingData = useCallback((): boolean => {
    return (
      isDaysInterestReportLoading ||
      isMonthInterestReportLoading ||
      isQuarterInterestReportLoading ||
      isYearInterestReportLoading
    );
  }, [
    isDaysInterestReportLoading,
    isMonthInterestReportLoading,
    isQuarterInterestReportLoading,
    isYearInterestReportLoading,
  ]);

  useEffectOnce(() => {
    if (loan.interest.accruedInterestPeriod === 'DAYS') {
      setSelectedPeriod(Period.DAYS);
    } else if (loan.interest.accruedInterestPeriod === 'MONTH') {
      setSelectedPeriod(Period.MONTHS);
    } else if (loan.interest.accruedInterestPeriod === 'QUARTER') {
      setSelectedPeriod(Period.QUARTERS);
    } else if (loan.interest.accruedInterestPeriod === 'YEAR') {
      setSelectedPeriod(Period.YEARS);
    }
  });

  useEffect(() => {
    if (daysInterestReport && selectedPeriod === Period.DAYS) {
      setSelectedReportIndex(0);
      setSelectedReports([daysInterestReport]);
      setSelectedReport(daysInterestReport);
      setActiveJournals(daysInterestReport.journals);
      setSelectedJournalEntries(daysInterestReport.journals);
    }
  }, [daysInterestReport, selectedPeriod]);

  useEffect(() => {
    if (monthInterestReport && selectedPeriod === Period.MONTHS) {
      setSelectedReportIndex(0);
      setSelectedReports([monthInterestReport]);
      setSelectedReport(monthInterestReport);
      setActiveJournals(monthInterestReport.journals);
      setSelectedJournalEntries(monthInterestReport.journals);
    }
  }, [monthInterestReport, selectedPeriod]);

  useEffect(() => {
    if (quarterInterestReport && selectedPeriod === Period.QUARTERS) {
      setSelectedReportIndex(0);
      setSelectedReports([quarterInterestReport]);
      setSelectedReport(quarterInterestReport);
      setActiveJournals(quarterInterestReport.journals);
      setSelectedJournalEntries(quarterInterestReport.journals);
    }
  }, [quarterInterestReport, selectedPeriod]);

  useEffect(() => {
    if (yearInterestReport && selectedPeriod === Period.YEARS) {
      setSelectedReportIndex(0);
      setSelectedReports([yearInterestReport]);
      setSelectedReport(yearInterestReport);
      setActiveJournals(yearInterestReport.journals);
      setSelectedJournalEntries(yearInterestReport.journals);
    }
  }, [yearInterestReport, selectedPeriod]);

  useEffect(() => {
    if (!selectedReport) {
      console.debug(
        'The journal entries are undefined, which means none can be returned.'
      );
    } else if (isTopLevelReport(selectedReport)) {
      setSelectedJournalEntries(activeJournals ? activeJournals : []);
    } else if (isDay(selectedReport)) {
      const dayInterestReport: DayReport = selectedReport as DayReport;
      const dayJournalEntries: Journal[] = removeDuplicates(
        dayInterestReport.journals
      );
      setSelectedJournalEntries(dayJournalEntries);
    } else if (isMonth(selectedReport)) {
      const monthReport: MonthReport = selectedReport as MonthReport;
      const mothJournalEntries: Journal[] = removeDuplicates(
        monthReport.days.flatMap((day) => day.journals)
      );
      setSelectedJournalEntries(mothJournalEntries);
    } else if (isQuarter(selectedReport)) {
      const quarterReport: QuarterReport = selectedReport as QuarterReport;
      setSelectedJournalEntries(
        removeDuplicates(
          quarterReport.months
            .flatMap((month) => month.days)
            .flatMap((day) => day.journals)
        )
      );
    } else if (isYear(selectedReport)) {
      const yearReport: YearReport = selectedReport as YearReport;
      setSelectedJournalEntries(
        removeDuplicates(
          yearReport.quarters
            .flatMap((quarter) => quarter.months)
            .flatMap((month) => month.days)
            .flatMap((day) => day.journals)
        )
      );
    }
  }, [activeJournals, selectedReport]);

  const onSelectedReportChange = (newSelectedReport: ReportType) => {
    if (!isDay(newSelectedReport)) {
      if (selectedReport) {
        setSelectedReports([...selectedReports, selectedReport]);
        setSelectedReportIndex(selectedReportIndex + 1);
      }
      setSelectedReport(newSelectedReport);
    }
  };

  const onNavigateBack = () => {
    if (selectedReport && selectedReportIndex > 0) {
      setSelectedReports(selectedReports.slice(0, -1));
      setSelectedReport(selectedReports[selectedReportIndex]);
      setSelectedReportIndex(selectedReportIndex - 1);
    }
  };

  const onChangePeriod = useCallback((value) => {
    if (value === 'DAYS') {
      setSelectedPeriod(Period.DAYS);
    } else if (value === 'MONTHS') {
      setSelectedPeriod(Period.MONTHS);
    } else if (value === 'QUARTERS') {
      setSelectedPeriod(Period.QUARTERS);
    } else if (value === 'YEARS') {
      setSelectedPeriod(Period.YEARS);
    }
  }, []);

  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={3}>
          {selectedReport ? (
            <LoanReportStatistic report={selectedReport} loan={loan} />
          ) : (
            <></>
          )}
        </Col>
        <Col flex="auto">
          <Row>
            <Col flex="auto">
              {selectedReport && selectedJournalEntries ? (
                <LoanReportBarChart
                  loan={loan}
                  report={selectedReport}
                  onSelect={onSelectedReportChange}
                  onLoading={isLoadingData}
                />
              ) : (
                <></>
              )}
            </Col>
          </Row>
          <Row>
            <Col offset={2}>
              <Button
                size={'small'}
                disabled={selectedReportIndex === 0}
                onClick={onNavigateBack}
                loading={isLoadingData()}
              >
                <KeyboardDoubleArrowLeft />
              </Button>
            </Col>
            <Col offset={1} span={3}>
              <Select
                size={'small'}
                placeholder="Select period"
                value={Period[selectedPeriod]}
                onChange={onChangePeriod}
                style={{ width: 100 }}
                options={options}
              />
            </Col>
          </Row>
          <Divider orientation="center"></Divider>
          <Row>
            <Col flex="auto">
              <Tabs
                defaultActiveKey="1"
                style={{ marginBottom: 32 }}
                items={[
                  {
                    label: 'Loan Events',
                    key: '1',
                    children: loan.id ? (
                      <div>
                        <LoanEventsTable loanId={loan.id} />
                      </div>
                    ) : (
                      <></>
                    ),
                  },
                  {
                    label: 'Loan Journal',
                    key: '2',
                    children: (
                      <div>
                        <Table
                          dataSource={selectedJournalEntries}
                          columns={columns}
                          pagination={{ defaultPageSize: 10 }}
                          rowKey={(journalEntry) => journalEntry.id}
                          size="small"
                        />
                      </div>
                    ),
                  },
                ]}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
/** 
<Tab>
  <TabPane>
    <Table
      dataSource={selectedJournalEntries}
      columns={columns}
      pagination={{ pageSize: 10 }}
      rowKey={(journalEntry) => journalEntry.id}
      size="small"
    />
  </TabPane>
  <TabPane>
    <LoanEventsTable loan={loan} />
  </TabPane>
</Tab>
 */
export default ListLoansExpandedView;
