import { ReportType } from 'api/types';

export function isTopLevelReport(report: ReportType) {
  return (
    isDays(report) || isMonths(report) || isQuarters(report) || isYears(report)
  );
}

export function isDay(report: ReportType) {
  return report.type === 'DAY';
}

export function isDays(report: ReportType) {
  return report.type === 'DAYS';
}

export function isMonth(report: ReportType) {
  return report.type === 'MONTH';
}

export function isMonths(report: ReportType) {
  return report.type === 'MONTHS';
}

export function isQuarter(report: ReportType) {
  return report.type === 'QUARTER';
}

export function isQuarters(report: ReportType) {
  return report.type === 'QUARTERS';
}

export function isYear(report: ReportType) {
  return report.type === 'YEAR';
}

export function isYears(report: ReportType) {
  return report.type === 'YEARS';
}
