import axios from 'axios';
import qs from 'qs';
import {
  DaysReport,
  Loan,
  LoanDetails,
  LoanEvent,
  LoanReport,
  MonthsReport,
  PaginatedResult,
  PaginationQuery,
  QuartersReport,
  YearsReport,
} from 'api/types';

import { ENDPOINT_BASE } from 'constants/index';

const LOAN_PATH = 'loan';
const REPORT_PATH = 'report';
const LOAN_EVENT_PATH = 'loan-event/';
const PAGINATED_LOAN = 'page/loan';

const client = axios.create({
  withCredentials: true,
  baseURL: ENDPOINT_BASE,
});

/********************************************************************
 *  Loan
 *******************************************************************/
export async function createLoan(request: Loan): Promise<void> {
  await client.post<void>(LOAN_PATH, request);
}

export async function updateLoan(request: Loan): Promise<void> {
  await client.put<void>(LOAN_PATH, request);
}

export async function submitLoanEvent(request: LoanEvent): Promise<void> {
  await client.post<void>(LOAN_EVENT_PATH, request);
}

export async function getLoans(): Promise<Loan[]> {
  const response = await client.get<Loan[]>(LOAN_PATH);
  return response.data;
}

export async function getLoansPaginated(
  pagination: PaginationQuery,
  query = ''
): Promise<PaginatedResult<LoanReport>> {
  const response = await client.get<PaginatedResult<LoanReport>>(
    `${ENDPOINT_BASE}${PAGINATED_LOAN}?${qs.stringify(
      {
        criteria: query,
        ...pagination,
      },
      { arrayFormat: 'repeat' }
    )}`,
    { data: pagination }
  );
  return response.data;
}

export async function getLoansByIdentificationNumber(
  identificationNumber: string | undefined
): Promise<Loan[]> {
  if (!identificationNumber) {
    throw new Error('IdentificationNumber missing');
  }
  const response = await client.get<Loan[]>(
    `${LOAN_PATH}/${identificationNumber}`
  );
  return response.data;
}

export async function getLoansByNameContaining(name: string): Promise<Loan[]> {
  const response = await client.get<Loan[]>(`${LOAN_PATH}/query?name=${name}`);
  return response.data;
}

/********************************************************************
 *  Loan Reports
 *******************************************************************/
export async function getDaysInterestReport(
  loanId: number | undefined
): Promise<DaysReport> {
  if (!loanId) {
    throw new Error('Loan id is missing');
  }
  const response = await client.get<DaysReport>(
    `${REPORT_PATH}/days/${loanId}`
  );
  return response.data;
}

export async function getMonthsInterestReport(
  loanId: number | undefined
): Promise<MonthsReport> {
  if (!loanId) {
    throw new Error('Loan id is missing');
  }
  const response = await client.get<MonthsReport>(
    `${REPORT_PATH}/months/${loanId}`
  );
  return response.data;
}

export async function getQuartersInterestReport(
  loanId: number | undefined
): Promise<QuartersReport> {
  if (!loanId) {
    throw new Error('Loan id is missing');
  }
  const response = await client.get<QuartersReport>(
    `${REPORT_PATH}/quarters/${loanId}`
  );
  return response.data;
}

export async function getYearsInterestReport(
  loanId: number | undefined
): Promise<YearsReport> {
  if (!loanId) {
    throw new Error('Loan id is missing');
  }
  const response = await client.get<YearsReport>(
    `${REPORT_PATH}/years/${loanId}`
  );
  return response.data;
}

/********************************************************************
 *  Loan Details
 *******************************************************************/

export async function getLoanDetails(
  loanId: number | undefined
): Promise<LoanDetails> {
  if (!loanId) {
    throw new Error('IdentificationNumber missing');
  }
  const response = await client.get<LoanDetails>(
    `${LOAN_PATH}/details/${loanId}`
  );
  return response.data;
}
