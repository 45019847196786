import { message } from 'antd';
import {
  addOrganisation,
  getOrganisations,
  deleteOrganisation,
  updateOrganisation,
  getOrganisation,
} from 'api/organisationClient';
import { Loan, Organisation, UnidentifiedOrganisation } from 'api/types';

import {
  useQuery,
  useMutation,
  UseMutateFunction,
  useQueryClient,
} from '@tanstack/react-query';
import { getLoansByIdentificationNumber } from 'api/loanClient';

export const useSubmitOrganisation = (): [
  UseMutateFunction<Organisation, Error, UnidentifiedOrganisation, unknown>,
  'error' | 'idle' | 'pending' | 'success'
] => {
  const { mutate, status } = useMutation({
    mutationFn: addOrganisation,
    onError: () => {
      message.error('Error while creating the organisation!');
    },
    onSuccess: () => {
      message.success('Successfuly added organisation!');
    },
  });

  return [mutate, status];
};

export const useGetOrganisations = (
  disabled?: boolean
): [Organisation[], boolean] => {
  const query = useQuery({
    queryKey: [getOrganisations.name],
    queryFn: getOrganisations,
    initialData: [],
    enabled: !disabled,
  });

  return [query.data, query.isLoading];
};

export function useGetOrganisation(
  identficationNumber: string | undefined
): [Organisation | undefined, boolean] {
  const { data, isLoading } = useQuery({
    queryKey: [getOrganisation.name, identficationNumber],
    queryFn: () => getOrganisation(identficationNumber),
    enabled: !!identficationNumber,
  });
  return [data, isLoading];
}

export const useDeleteOrganisation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: deleteOrganisation,
    onError: () => {
      message.error('Error when deleting the organisation!');
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [getOrganisations.name] });
      message.success('Successfully deleted the organisation!');
    },
  });

  return mutation;
};

export const useUpdateOrganisation = (): [
  UseMutateFunction<Organisation, Error, Organisation, unknown>,
  'error' | 'idle' | 'pending' | 'success'
] => {
  const queryClient = useQueryClient();

  const { mutate, status } = useMutation({
    mutationFn: updateOrganisation,
    onError: () => {
      message.error('Error while updating the organisation!');
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [getOrganisations.name],
      });
      message.success('Successfully updated organisation!');
    },
  });

  return [mutate, status];
};
