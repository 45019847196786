import React, { useCallback, useMemo, useState } from 'react';

import { Button, Modal, Table } from 'antd';

import { v4 as uuidv4 } from 'uuid';

import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import {
  BankContact,
  ContactMedium,
  ContactMediumType,
  EmailContact,
  NamedEmailContact,
  NamedPostalContact,
  NamedTelephoneContact,
  PostalContact,
  TelephoneContact,
  WebContact,
} from 'api/types';

import ContactMediumModal from './ContactMediumModal';

type ContactMediumProps = {
  contacts?: ContactMedium[];
  onSubmit: (data: ContactMedium[]) => void;
  allowedContactMediaTypes: ContactMediumType[];
};

/** Extend the contact medium with an id that is unique for the client to be able to  add/remove from the table */
type ContactMediumTableEntry = ContactMedium & { tableId: string };

function extendWithId(object: ContactMedium): ContactMediumTableEntry {
  return { ...object, tableId: uuidv4() };
}

const ContactMediumDrawer: React.FC<ContactMediumProps> = ({
  contacts,
  onSubmit,
  allowedContactMediaTypes,
}) => {
  const [data, setData] = useState<ContactMediumTableEntry[]>(
    contacts?.map(extendWithId) ?? []
  );

  const [selectedId, setSelectedId] = useState<string | undefined>(
    () => undefined
  );

  const [selectedContactMedium, setSelectedContactMedium] = useState<
    ContactMedium | undefined
  >(() => undefined);

  const [isCreateModalVisible, setIsCreateOrEditModalVisible] = useState(false);

  const title = useMemo(() => {
    return selectedContactMedium !== undefined
      ? 'Edit contact medium'
      : 'Create contact medium';
  }, [selectedContactMedium]);

  const dataKeys = useMemo(
    () => data.map((entry) => ({ ...entry, key: uuidv4() })),
    [data]
  );

  /** Create contact */
  const closeModal = useCallback(() => {
    setIsCreateOrEditModalVisible(false);
  }, []);

  /** When the 'Create' button is pressed we clear any selected contact mediums */
  const showCreateModal = useCallback(() => {
    setSelectedId(undefined);
    setSelectedContactMedium(undefined);
    setIsCreateOrEditModalVisible(true);
  }, []);

  /** When the 'Edit' is pressed we set the selected id and contact medium  */
  const showEditModal = useCallback(
    (id: string, contact: ContactMedium) => () => {
      setSelectedId(id);
      setSelectedContactMedium(contact);
      setIsCreateOrEditModalVisible(true);
    },
    []
  );

  /** Gets called each time a contact is created or edited. If a selected contact exists its an edit otherwise a create */
  const handleContactCreatedOrEdited = useCallback(
    (newContact: ContactMedium) => {
      /** If the user choose to edit an existing contact medium the selected id and contact medum will be set */
      closeModal();
      if (selectedId !== undefined && selectedContactMedium !== undefined) {
        setData((values) =>
          values.map((existingContact) =>
            existingContact.tableId === selectedId
              ? extendWithId(newContact)
              : existingContact
          )
        );
        setSelectedId(undefined);
        setSelectedContactMedium(undefined);
      } else {
        setData((values) => [extendWithId(newContact), ...values]);
      }
    },
    [selectedId, selectedContactMedium, closeModal]
  );

  const handleContactRemoved = useCallback(
    (id: string) => () => {
      setData((values) => values.filter((v) => v.tableId !== id));
      closeModal();
    },
    [closeModal]
  );

  const getColumns = useCallback(() => {
    return [
      {
        title: 'Value',
        key: 'value',
        width: '400px',
        render: (_, contact: ContactMediumTableEntry) => {
          let contactData: ContactMedium | undefined = undefined;
          let text = '';

          switch (contact.type) {
            case ContactMediumType.EMAIL: {
              contactData = contact as EmailContact;
              text = contactData.address;
              break;
            }
            case ContactMediumType.POSTAL: {
              contactData = contact as PostalContact;
              text = `${contactData.street?.name} ${contactData.street?.segment}`;
              break;
            }
            case ContactMediumType.TELEPHONE: {
              contactData = contact as TelephoneContact;
              text = `${contactData.number} ${contactData.numberType}`;
              break;
            }
            case ContactMediumType.NAMED_EMAIL: {
              contactData = contact as NamedEmailContact;
              if ('name' in contactData) {
                text = `${contactData.name}: ${contactData.address}`;
              }
              break;
            }
            case ContactMediumType.NAMED_POSTAL: {
              contactData = contact as NamedPostalContact;
              if ('name' in contactData) {
                text = `${contactData.name}: ${contactData.street?.name} ${contactData.street?.segment}`;
              }
              break;
            }
            case ContactMediumType.NAMED_TELEPHONE: {
              contactData = contact as NamedTelephoneContact;
              if ('name' in contactData) {
                text = `${contactData.name}: ${contactData.number} ${contactData.numberType}`;
              }
              break;
            }
            case ContactMediumType.BANK: {
              contactData = contact as BankContact;
              text = `${contactData.bic} ${contactData.iban}`;
              break;
            }
            case ContactMediumType.WEB: {
              contactData = contact as WebContact;
              text = contactData.address;
              break;
            }
          }

          return contactData ? (
            <span
              onClick={showEditModal(contact.tableId, contactData)}
              className="hover:underline cursor-pointer"
            >
              {text}
            </span>
          ) : (
            <></>
          );
        },
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        render: (text: string) => <span>{text}</span>,
      },
      {
        title: '',
        key: 'remove',
        width: '80px',
        render: (_, contact: ContactMediumTableEntry) => (
          <Button
            type="text"
            className="justify-center"
            onClick={handleContactRemoved(contact.tableId)}
            icon={<DeleteOutlined />}
            danger
          />
        ),
      },
    ];
  }, [handleContactRemoved, showEditModal]);

  const handleSubmit = useCallback(() => {
    onSubmit(
      data.map(({ tableId, ...contact }) => ({ ...contact } as ContactMedium))
    );
  }, [data, onSubmit]);

  const columns = useMemo(() => getColumns(), [getColumns]);

  return (
    <div>
      <Button
        icon={<PlusOutlined />}
        className="w-min mb-3 flex"
        onClick={showCreateModal}
      >
        Add Contact
      </Button>
      <Table
        pagination={{ defaultPageSize: 10 }}
        columns={columns}
        dataSource={dataKeys}
      />
      <Button
        className="justify-center my-3"
        type="primary"
        onClick={handleSubmit}
      >
        Submit
      </Button>
      <Modal
        title={title}
        open={isCreateModalVisible}
        onCancel={closeModal}
        width="800px"
        footer={null}
        destroyOnClose
      >
        <ContactMediumModal
          allowedContactMediaTypes={allowedContactMediaTypes}
          onSubmit={handleContactCreatedOrEdited}
          initialData={selectedContactMedium}
        />
      </Modal>
    </div>
  );
};

export default ContactMediumDrawer;
