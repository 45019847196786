import React, { useCallback } from 'react';

import { UnidentifiedIndividual } from 'api/types';

import IndividualForm from 'components/forms/IndividualForm';
import { useSubmitIndividual } from 'hooks/withIndividual';

const CreateIndividualView: React.FC = ({}) => {
  const [handleCreateIndividual, status] = useSubmitIndividual();

  const onCreate = useCallback(
    (payload: UnidentifiedIndividual) => {
      handleCreateIndividual(payload);
    },
    [handleCreateIndividual]
  );

  return (
    <div className="mt-10">
      <h1 className="mb-0 py-5">Create Individual</h1>
      <div className="flex flex-col mt-10">
        <IndividualForm onCreate={onCreate} isLoading={status === 'pending'} />
      </div>
    </div>
  );
};

export default CreateIndividualView;
