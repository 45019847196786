import React from 'react';

const WelcomeView: React.FC = () => {
  return (
    <div>
      <div className="card">
        <h1 className="mb-0 py-5">
          Welcome to SBPs Backoffice lending system!
        </h1>
      </div>
    </div>
  );
};

export default WelcomeView;
