import {
  DayReport,
  DaysReport,
  Loan,
  MonthReport,
  MonthsReport,
  QuarterReport,
  QuartersReport,
  ReportType,
  YearReport,
  YearsReport,
} from 'api/types';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import React, { useCallback, useState } from 'react';
import {
  isDay,
  isDays,
  isMonth,
  isMonths,
  isQuarter,
  isQuarters,
  isYear,
  isYears,
} from 'utils/reports';

type LoanReportBarChartProps = {
  loan: Loan;
  report: ReportType;
  onSelect: (data: ReportType) => void;
  onLoading: () => boolean;
};

const getXAxisDataKey = (report: ReportType): string => {
  if (isDay(report)) {
    return '';
  } else if (isDays(report)) {
    return 'date';
  } else if (isMonth(report)) {
    return 'date';
  } else if (isMonths(report)) {
    return 'yearMonth';
  } else if (isQuarter(report)) {
    return 'yearMonth';
  } else if (isQuarters(report)) {
    return 'yearQuarter';
  } else if (isYear(report)) {
    return 'yearQuarter';
  } else if (isYears(report)) {
    return 'year';
  }
  throw new Error('Unknown period report provided.');
};

const getXAxisLabel = (report: ReportType, currency: string): string => {
  if (isDay(report)) {
    return '';
  } else if (isDays(report)) {
    return 'Accrued Interest per day in ' + currency;
  } else if (isMonth(report)) {
    return 'Accrued Interest per day in ' + currency;
  } else if (isMonths(report)) {
    return 'Accrued Interest per month in ' + currency;
  } else if (isQuarter(report)) {
    return 'Accrued Interest per month in ' + currency;
  } else if (isQuarters(report)) {
    return 'Accrued Interest per quarter in ' + currency;
  } else if (isYear(report)) {
    return 'Accrued Interest per quarter in ' + currency;
  } else if (isYears(report)) {
    return 'Accrued Interest per year in ' + currency;
  }
  throw new Error('Unknown period report provided.');
};

function getLoanInterest(payload): number | string {
  return payload[0]?.payload !== undefined && isDay(payload[0]?.payload)
    ? 'Loan Interest:' + (payload[0]?.payload as DayReport).loanInterest
    : '';
}

function getAdjustedInterest(payload): number | string {
  return payload[0]?.payload !== undefined && isDay(payload[0]?.payload)
    ? 'Adjusted loan Interest:' +
        (payload[0]?.payload as DayReport).adjustedInterest
    : '';
}

const LoanReportBarChartView: React.FC<LoanReportBarChartProps> = ({
  loan,
  report,
  onSelect,
  onLoading,
}) => {
  const [hooveredReport, setHooveredReport] = useState<ReportType>(report);

  const formatCurrencySwedish = useCallback(
    (value) => {
      return new Intl.NumberFormat('sv-SE', {
        style: 'currency',
        currency: loan.currency,
      })
        .format(value)
        .replace('kr', '');
    },
    [loan.currency]
  );

  const getAccruedInterest = useCallback(
    (payload): number | string => {
      return payload[0]?.payload?.accruedInterest
        ? 'Accrued interest for period: ' +
            formatCurrencySwedish(payload[0].payload.accruedInterest as number)
        : '0';
    },
    [formatCurrencySwedish]
  );

  const getReports = useCallback(():
    | DayReport[]
    | MonthReport[]
    | QuarterReport[]
    | YearReport[] => {
    if (report.type === 'DAY') {
      return [];
    } else if (isDays(report)) {
      const daysInterestReport: DaysReport = report as DaysReport;
      return daysInterestReport.days;
    } else if (isMonth(report)) {
      const monthInterestReport: MonthReport = report as MonthReport;
      return monthInterestReport.days;
    } else if (isMonths(report)) {
      const monthsInterestReport: MonthsReport = report as MonthsReport;
      return monthsInterestReport.months;
    } else if (isQuarter(report)) {
      const quarterInterestReport: QuarterReport = report as QuarterReport;
      return quarterInterestReport.months;
    } else if (isQuarters(report)) {
      const quartersInterestReport: QuartersReport = report as QuartersReport;
      return quartersInterestReport.quarters;
    } else if (isYear(report)) {
      const yearInterestReport: YearReport = report as YearReport;
      return yearInterestReport.quarters;
    } else if (isYears(report)) {
      const yearsInterestReport: YearsReport = report as YearsReport;
      return yearsInterestReport.years;
    }
    throw new Error('Unknown period report provided.');
  }, [report]);

  const onMouseClicked = (selectMonth) => {
    onSelect(selectMonth.tooltipPayload[0].payload);
  };

  const onMouseOver = (selectMonth) => {
    setHooveredReport(selectMonth.tooltipPayload[0].payload);
  };

  const labelFormatter = (value) => {
    return formatCurrencySwedish(value.accruedInterest);
  };

  function DisplayTooltip({
    payload,
    label,
    active,
  }): React.ReactElement | null {
    if (active) {
      return (
        <div className="custom-tooltip">
          <p className="label">{'Period: ' + label}</p>
          <p className="intro">{getAccruedInterest(payload)}</p>
          {isDay(hooveredReport) && (
            <p className="intro">{getLoanInterest(payload)}</p>
          )}
          {isDay(hooveredReport) && (
            <p className="intro">{getAdjustedInterest(payload)}</p>
          )}
        </div>
      );
    }

    return null;
  }

  return (
    <div style={onLoading() ? { pointerEvents: 'none', opacity: '0.4' } : {}}>
      <ResponsiveContainer width="95%" height={400}>
        <BarChart
          data={getReports()}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <Tooltip
            wrapperStyle={{ backgroundColor: 'rgba(255,255,255,0.5)' }}
            content={
              <DisplayTooltip
                payload={undefined}
                label={undefined}
                active={undefined}
              />
            }
          />
          <CartesianGrid strokeDasharray="1 1" />
          <XAxis dataKey={getXAxisDataKey(report)}>
            <Label
              value={getXAxisLabel(report, loan.currency)}
              offset={0}
              position="insideBottom"
            />
          </XAxis>
          <YAxis
            allowDataOverflow={false}
            tick={{ width: 75 }}
            tickFormatter={(val) => val.toLocaleString().replace(/,/g, ' ')}
          />
          <Bar
            barSize={100}
            maxBarSize={100}
            background={false}
            activeBar={{ stroke: 'white', strokeWidth: 2 }}
            isAnimationActive={true}
            type="number"
            dataKey="accruedInterest"
            fill="#448844"
            onClick={onMouseClicked}
            onMouseEnter={onMouseOver}
          >
            <LabelList
              valueAccessor={labelFormatter}
              angle={-90}
              position="insideTop"
              offset={30}
              fill="#333333"
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
export default LoanReportBarChartView;
