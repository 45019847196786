import { Button, DatePicker, Form, Input, Select } from 'antd';
import {
  Setting,
  SettingDescriptor,
  SettingType,
  UnidentifiedSetting,
} from 'api/types';
import { LAYOUT, SETTING_DESCRIPTORS } from 'constants/index';
import dayjs, { Dayjs } from 'dayjs';
import React, { useCallback, useState } from 'react';

type SettingFormProps = {
  initialData?: Setting;
  onCreate: ((data: UnidentifiedSetting) => void) | undefined;
  onUpdate: ((data: Setting) => void) | undefined;
};

const SettingForm: React.FC<SettingFormProps> = ({
  initialData,
  onCreate,
  onUpdate,
}) => {
  type FormData = {
    id: string;
    type: string;
    name: string;
    description: string;
    created: Dayjs;
    value: never;
  };
  const [form] = Form.useForm<FormData>();
  const isUndefined = initialData === undefined;
  const [selectedType, setSelectedType] = useState<SettingType>(
    SettingType.BIG_DECIMAL
  );
  const [selectedDescriptor, setSelectedDescriptor] =
    useState<SettingDescriptor>(SETTING_DESCRIPTORS[0]);

  const renderValue = useCallback(() => {
    switch (selectedType) {
      case SettingType.BIG_DECIMAL:
        return (
          <Form.Item
            name={['value']}
            label="Value"
            initialValue={selectedDescriptor.value}
            rules={[
              {
                required: true,
                message: 'A decimal value must be entered',
                pattern: new RegExp(/^[+-]?((\d+(\.\d*)?)|(\.\d+))$/),
              },
            ]}
          >
            <Input />
          </Form.Item>
        );
      case SettingType.INTEGER:
      case SettingType.LONG:
        return (
          <Form.Item
            name={['value']}
            label="Value"
            initialValue={selectedDescriptor.value}
            rules={[
              {
                required: true,
                message: 'An integer/long value must be entered',
                pattern: new RegExp(/^[+-]?\d+/),
              },
            ]}
          >
            <Input />
          </Form.Item>
        );
      default:
        return (
          <Form.Item
            name={['value']}
            label="Value"
            initialValue={selectedDescriptor.value}
          >
            <Input />
          </Form.Item>
        );
    }
  }, [selectedType, selectedDescriptor]);

  const handleSubmit = useCallback(
    (values: FormData) => {
      if (isUndefined && onCreate) {
        onCreate({
          type: selectedDescriptor.type,
          name: selectedDescriptor.name,
          description: values.description,
          created: values.created.toJSON(),
          value: (() => {
            switch (selectedType) {
              case SettingType.BIG_DECIMAL:
                return values.value;
              case SettingType.INTEGER:
                return values.value as number;
              case SettingType.INTEGER:
                return values.value as number;
              default:
                return values.value;
            }
          })(),
        } as UnidentifiedSetting);
      } else if (onUpdate) {
        onUpdate({
          type: selectedDescriptor.type,
          id: values.id,
          name: selectedDescriptor.name,
          description: values.description,
          created: values.created.toJSON(),
          value: (() => {
            switch (selectedType) {
              case SettingType.BIG_DECIMAL:
                return values.value;
              case SettingType.INTEGER:
                return values.value as number;
              case SettingType.INTEGER:
                return values.value as number;
              default:
                return values.value;
            }
          })(),
        } as Setting);
      } else {
        throw new Error('SettingsForm: no handler specified!');
      }
    },
    [isUndefined, onCreate, onUpdate, selectedType, selectedDescriptor]
  );

  const handleDescriptorSelected = useCallback(
    (name) => {
      const settingDesciptor: SettingDescriptor | undefined =
        SETTING_DESCRIPTORS.filter((sd) => sd.name === name).at(0);
      if (settingDesciptor !== undefined) {
        setSelectedDescriptor(settingDesciptor);
        form.setFieldValue('type', settingDesciptor.type);
        form.setFieldValue('name', settingDesciptor.name);
      }
    },
    [form]
  );

  return (
    <div>
      <Form
        {...LAYOUT}
        form={form}
        onFinish={handleSubmit}
        autoComplete="off"
        initialValues={{
          ...initialData,
          created: initialData?.created
            ? dayjs.utc(initialData?.created).local()
            : dayjs(),
        }}
      >
        <Form.Item hidden={true} name={['id']} label="Id">
          <Input />
        </Form.Item>
        <Form.Item
          name={['name']}
          label="Name"
          initialValue={selectedDescriptor.name}
          rules={[{ required: true, message: 'Please select a name!' }]}
        >
          <Select
            options={SETTING_DESCRIPTORS.map((sd) => ({
              value: sd.name,
              label: sd.name,
            }))}
            onSelect={handleDescriptorSelected}
          />
        </Form.Item>
        <Form.Item name={['description']} label="Description">
          <Input />
        </Form.Item>
        <Form.Item name="created" label="Created">
          <DatePicker showTime={{ use12Hours: false }} />
        </Form.Item>
        {renderValue()}
        <Form.Item label=" " colon={false}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SettingForm;
