import React from 'react';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useLocation, useNavigate } from 'react-router-dom';

import CreateAccount from './CreateAccount';
import FindLedger from './FindLedger';

const routesOrder = ['/ledger', '/ledger/find'];

const LedgerView = () => {
  const location = useLocation();

  const defaultIndex = routesOrder.indexOf(location.pathname);

  const navigate = useNavigate();

  return (
    <div>
      <div className="card">
        <Tabs
          onSelect={(idx) => {
            navigate(routesOrder[idx]);
          }}
          defaultIndex={defaultIndex === -1 ? 0 : defaultIndex}
        >
          <TabList>
            <Tab>Create Account</Tab>
            <Tab>Find Ledger</Tab>
          </TabList>

          <TabPanel>
            <CreateAccount />
          </TabPanel>
          <TabPanel>
            <FindLedger />
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

export default LedgerView;
