import { AxiosResponse } from 'axios';
import { message } from 'antd';

import {
  UseMutateFunction,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import {
  formLoginAuthenticate,
  FormLoginAuthenticateRequest,
  formLoginLogout,
} from 'api/formLoginClient';
import { authenticationStatus } from 'api/authenticationClient';

export const useFormLoginAuth = (): [
  UseMutateFunction<
    AxiosResponse,
    Error,
    FormLoginAuthenticateRequest | null | undefined,
    unknown
  >,
  boolean
] => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: [formLoginAuthenticate.name],
    mutationFn: formLoginAuthenticate,
    onError: () => {
      message.error('Authentication failed!');
    },
    onSuccess: () => {
      message.success('Authentication success');
      queryClient.invalidateQueries({
        queryKey: [authenticationStatus.name],
      });
    },
  });

  return [mutation.mutate, mutation.isPending];
};

export const useFormLoginLogout = (): [
  UseMutateFunction<AxiosResponse, Error, void, unknown>,
  boolean
] => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: [formLoginLogout.name],
    mutationFn: formLoginLogout,
    onError: () => {
      message.error('Failed to logout!');
    },
    onSuccess: () => {
      message.success('Logout successful');
      queryClient.invalidateQueries({
        queryKey: [authenticationStatus.name],
      });
    },
  });

  return [mutation.mutate, mutation.isPending];
};
