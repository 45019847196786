import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
export const DATE_FORMAT = 'YYYY-MM-DD HH:mm';

export const getDateString = (date?: string | null): string => {
  if (!date) {
    return '-';
  }

  return dayjs(date).format(DATE_FORMAT);
};

export const getDateStringInLocalTime = (date?: string | null): string => {
  if (!date) {
    return '-';
  }

  return dayjs.utc(date).local().format(DATE_FORMAT);
};

/*export const compareDates = (a?: string | null, b?: string | null) =>
  moment(a, DATE_FORMAT).unix() - moment(b, DATE_FORMAT).unix();*/
