import axios, { AxiosResponse } from 'axios';
import { ENDPOINT_BASE } from 'constants/index';

const baseUrl = `${ENDPOINT_BASE}`;

const client = axios.create({
  withCredentials: true,
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

export type FormLoginAuthenticateRequest = {
  username: string;
  password: string;
};

export async function formLoginAuthenticate(
  request: FormLoginAuthenticateRequest | undefined | null
): Promise<AxiosResponse> {
  if (!request) {
    throw new Error('AuthenticateRequest is missing on authenticate api call');
  }

  const formData = new URLSearchParams();
  formData.append('username', request.username);
  formData.append('password', request.password);
  return client.post(`login`, formData);
}

export async function formLoginLogout(): Promise<AxiosResponse> {
  return client.post(`logout`);
}
