import { Table } from 'antd';
import React from 'react';
import { ExplainReferenceType } from 'api/types';
import { useExplains } from 'hooks/withExplain';

type ExplainsTableProps = {
  eventId: number;
};
const ExplainsTable: React.FC<ExplainsTableProps> = ({ eventId }) => {
  const [explains, isExplainsLoading] = useExplains(
    eventId,
    ExplainReferenceType.LOAN_EVENT
  );

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Created',
      dataIndex: 'created',
      key: 'created',
    },
  ];

  return (
    <div className="mt-10">
      <div className="flex flex-col mt-5">
        <Table
          dataSource={explains}
          columns={columns}
          pagination={{ defaultPageSize: 10 }}
          loading={isExplainsLoading}
          rowKey={(explain) => explain.id}
          size="small"
        />
      </div>
    </div>
  );
};
export default ExplainsTable;
