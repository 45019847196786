import React from 'react';
import { Alert, Form, FormInstance, Input, Space } from 'antd';
import { LockTwoTone } from '@ant-design/icons';

/*
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};
*/

function validatePassword(pw): boolean {
  return (
    validatePasswordCase(pw) &&
    validatePasswordSpecialOrNumber(pw) &&
    validatePasswordLength(pw)
  );
}

function validatePasswordLength(pw): boolean {
  return pw?.length > 7;
}

function validatePasswordCase(pw): boolean {
  return /[a-z]/.test(pw) && /[A-Z]/.test(pw);
}

function validatePasswordSpecialOrNumber(pw): boolean {
  return /[^A-Za-z0-9]/.test(pw) || /[0-9]/.test(pw);
}

type FieldType = {
  password?: string;
  repeatPassword?: string;
};

const PasswordVerificationDisplayForm: React.FC<{
  form: FormInstance;
}> = ({ form }) => {
  return (
    <Form.Item>
      <p>Password</p>
      <Form.Item<FieldType>
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input a password!',
          },
          () => ({
            validator(_, value) {
              if (!validatePassword(value)) {
                return Promise.reject(
                  new Error('The password must fulfill the rules!')
                );
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <Input.Password prefix={<LockTwoTone />} placeholder="Password" />
      </Form.Item>
      <p>Repeat Password</p>
      <Form.Item<FieldType>
        name="repeatPassword"
        dependencies={['password']}
        rules={[
          {
            required: true,
            message: 'Repeat Password!',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!validatePassword(getFieldValue('password'))) {
                return Promise.resolve();
              }
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error('The new password that you entered do not match!')
              );
            },
          }),
        ]}
      >
        <Input.Password prefix={<LockTwoTone />} placeholder="Password" />
      </Form.Item>
      <p>Your password must include the following:</p>
      <Alert
        message=">8 characters"
        type={
          validatePasswordLength(form.getFieldValue(['password']))
            ? 'success'
            : 'warning'
        }
        showIcon
      />
      <Alert
        message="Upper & lowercase letters"
        type={
          validatePasswordCase(form.getFieldValue(['password']))
            ? 'success'
            : 'warning'
        }
        showIcon
      />
      <Alert
        message="At least one number or special character"
        type={
          validatePasswordSpecialOrNumber(form.getFieldValue(['password']))
            ? 'success'
            : 'warning'
        }
        showIcon
      />
    </Form.Item>
  );
};

export default PasswordVerificationDisplayForm;
