import { InternalRisk } from 'api/types';
import React from 'react';
import { Tag } from 'antd';

export function createTagFromInternalRisk(
  risk: InternalRisk | undefined
): React.ReactElement {
  let color = 'grey';
  let riskName = 'unknown';

  switch (risk) {
    case InternalRisk.LOW:
      color = 'green';
      riskName = 'Low';
      break;
    case InternalRisk.NORMAL:
      color = 'yellow';
      riskName = 'Normal';
      break;
    case InternalRisk.HIGH:
      color = 'red';
      riskName = 'High';
      break;
  }
  return <Tag color={color}>{riskName}</Tag>;
}
