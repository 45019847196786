import React from 'react';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useLocation, useNavigate } from 'react-router-dom';

import ListOrganisations from './ListOrganisations';
import LookupOrganisation from './LookupOrganisation';

import CreateOrganisationView from 'views/Party/Organisation/CreateOrganisation/CreateOrganisation';
import LookupIndividual from 'views/Party/Individual/LookupIndividual';
import CreateIndividualView from 'views/Party/Individual/CreateIndividual';
import ListIndividuals from 'views/Party/Individual/ListIndividuals';

const routesOrder = [
  '/organisations',
  '/organisations/create',
  '/organisations/list',
];

const OrganisationView = () => {
  const location = useLocation();

  const defaultIndex = routesOrder.indexOf(location.pathname);

  const navigate = useNavigate();

  return (
    <div>
      <div className="card">
        <Tabs
          onSelect={(idx) => {
            navigate(routesOrder[idx]);
          }}
          defaultIndex={defaultIndex === -1 ? 0 : defaultIndex}
        >
          <TabList>
            {/*<Tab>Lookup Organisation</Tab>*/}
            <Tab>Create Organisation</Tab>
            <Tab>List Organisations</Tab>
            {/*<Tab>Lookup Individual</Tab>*/}
            <Tab>Create Individual</Tab>
            <Tab>List Individuals</Tab>
          </TabList>

          {/* <TabPanel>
            <LookupOrganisation />
          </TabPanel>*/}
          <TabPanel>
            <CreateOrganisationView />
          </TabPanel>
          <TabPanel>
            <ListOrganisations />
          </TabPanel>

          {/*<TabPanel>
            <LookupIndividual />
          </TabPanel>*/}
          <TabPanel>
            <CreateIndividualView />
          </TabPanel>
          <TabPanel>
            <ListIndividuals />
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

export default OrganisationView;
