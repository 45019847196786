import { createContext, useState, useMemo, useContext } from 'react';

import sweJson from './swe.json';
import engJson from './eng.json';

const translationSets = [sweJson, engJson];

const getInitialTranslationSet = () => {
  const selectedLocale = translationSets.find(
    (it) => it.htmlLang === localStorage.getItem('selectedLocale')
  );

  if (selectedLocale) {
    return selectedLocale;
  }

  return (
    translationSets.find(
      (it) =>
        navigator.languages.includes(it.htmlLang) ||
        navigator.languages.includes(it.locale)
    ) || engJson
  );
};

const defaultContext = {
  translate: () => {},
  translationSets: [],
  activeTranslationSet: {},
  changeLanguage: () => {},
  activeLanguage: '',
};

const LocalizationContext = createContext(defaultContext);

const Localization = ({ children }) => {
  const [activeTranslationSet, setActiveTranslationSet] = useState(
    getInitialTranslationSet()
  );

  const contextValue = useMemo(() => {
    const translate = (...keys) => {
      if (!activeTranslationSet) {
        return keys[0];
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const key of keys) {
        if (activeTranslationSet[key]) {
          return activeTranslationSet[key];
        }
      }

      return keys[0];
    };

    const changeLanguage = (language) => {
      setActiveTranslationSet(
        translationSets.find((it) => it.htmlLang === language) || null
      );
      localStorage.setItem('selectedLocale', language);
    };

    return {
      translate,
      changeLanguage,
      translationSets,
      activeTranslationSet,
      activeLanguage: activeTranslationSet.htmlLang,
    };
  }, [activeTranslationSet]);

  return (
    <LocalizationContext.Provider value={contextValue}>
      {children}
    </LocalizationContext.Provider>
  );
};

export default Localization;

export const useLocalization = () => useContext(LocalizationContext);
