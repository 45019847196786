import React, { useCallback, useEffect, useState } from 'react';
import { Button, Drawer, Form, Input, Select } from 'antd';
import {
  ContactMedium,
  ContactMediumType,
  Gender,
  Organisation,
  OrganisationName,
  OrganisationType,
  PartyState,
  PartyType,
  UnidentifiedOrganisation,
} from 'api/types';
import ContactMediumsAccordion from 'components/accordion/ContactMediums';
import ContactMediumDrawer from 'components/drawer/ContactMediumDrawer';
import { LAYOUT, LAYOUT_TAIL } from 'constants/index';

type OrganisationFormProps = {
  initialData?: Organisation;
  onCreate?: (data: UnidentifiedOrganisation) => void;
  onUpdate?: (data: Organisation) => void;
  isLoading?: boolean;
  callback?: () => void;
};

type FormData = Omit<Organisation, 'contactMediums' | 'state'> & {
  state?: string;
};

const OrganisationForm: React.FC<OrganisationFormProps> = ({
  initialData,
  onCreate,
  onUpdate,
  isLoading,
  callback,
}) => {
  const [form] = Form.useForm<FormData>();

  const [iData, setIData] = useState(initialData);

  useEffect(() => {
    if (initialData) {
      setIData(initialData);
    }
  }, [initialData]);

  const isUndefined = initialData === undefined;

  /*****************************************************************************************
   * States
   ****************************************************************************************/
  const [contactMediums, setContactMediums] = useState<ContactMedium[]>(() => {
    return initialData?.contactMediums ?? [];
  });
  const [drawer, setDrawer] = useState<boolean>(false);

  const handleSubmit = useCallback(
    async (values: FormData) => {
      if (isUndefined && onCreate) {
        onCreate({
          id: values.id,
          name: new OrganisationName(
            iData ? iData.name.id : values.name.id,
            values.name.name
          ),
          type: PartyType.ORGANISATION,
          state: 'ACTIVE',
          identificationNumber: { number: values.identificationNumber },
          contactMediums: contactMediums,
          organisationType: values.organisationType,
        } as UnidentifiedOrganisation);
      } else if (onUpdate) {
        onUpdate({
          id: values.id,
          name: new OrganisationName(
            iData ? iData.name.id : values.name.id,
            values.name.name
          ),
          type: PartyType.ORGANISATION,
          state: values.state,
          version: values.version,
          identificationNumber: values.identificationNumber,
          contactMediums: contactMediums,
          organisationType: values.organisationType,
        } as Organisation);
      } else {
        throw new Error('OrganisationForm: no handler specified!');
      }
      if (callback) {
        callback();
      }
    },
    [isUndefined, onCreate, onUpdate, callback, iData, contactMediums]
  );

  const handleContactMediumRemoved = useCallback(
    (removedIndex: number) => () => {
      setContactMediums((cm) => [
        ...cm.filter((_, index) => index != removedIndex),
      ]);
    },
    []
  );

  const onShowDrawer = useCallback(() => {
    setDrawer(true);
  }, []);

  const onCloseDrawer = useCallback(() => {
    setDrawer(false);
  }, []);

  const handleSetContacts = useCallback(
    (data: ContactMedium[]) => {
      setContactMediums(data);
      onCloseDrawer();
    },
    [setContactMediums, onCloseDrawer]
  );

  /*****************************************************************************************
   * JSX
   ****************************************************************************************/
  return (
    <div>
      <Form
        {...LAYOUT}
        form={form}
        initialValues={{
          required: false,
          gender: Gender.UNKNOWN,
          state: 'ACTIVE',
          ...initialData,
        }}
        onFinish={handleSubmit}
        autoComplete="off"
      >
        {/** Id */}
        <Form.Item hidden={isUndefined} name={['id']} label="Id">
          <Input disabled />
        </Form.Item>

        {/** Id */}
        <Form.Item hidden={isUndefined} name={['version']} label="version">
          <Input disabled />
        </Form.Item>

        {/** Type */}
        <Form.Item hidden={true} name={['type']} label="Type">
          <Input />
        </Form.Item>

        {/** Name */}
        <Form.Item
          rules={[
            {
              required: true,
            },
          ]}
          name={['name', 'name']}
          label="Name"
        >
          <Input />
        </Form.Item>

        {/** OrganisationType */}
        <Form.Item
          rules={[
            {
              required: true,
            },
          ]}
          name={['organisationType']}
          label="Organisation Type"
        >
          <Select
            placeholder={'Select Organisation Type"'}
            options={Object.entries(OrganisationType).map(([value, name]) => ({
              label: name,
              value: value,
            }))}
          />
        </Form.Item>

        {/** Identification Number */}
        <Form.Item
          name={['identificationNumber']}
          label="Identification Number"
          required
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input type="string" />
        </Form.Item>

        {/** State */}
        <Form.Item
          rules={[
            {
              required: true,
            },
          ]}
          name={['state']}
          label="State"
        >
          <Select>
            <Select.Option value={PartyState.ACTIVE}>Active</Select.Option>
            <Select.Option value={PartyState.INACTIVE}>Inactive</Select.Option>
          </Select>
        </Form.Item>

        {/** Contact mediums */}
        <Form.Item label="Contacts" required>
          {contactMediums.length > 0 ? (
            <ContactMediumsAccordion
              contacts={contactMediums}
              handleContactRemoved={handleContactMediumRemoved}
            />
          ) : null}
          <Button type="link" onClick={onShowDrawer}>
            Manage Contacts
          </Button>
        </Form.Item>
        <Form.Item {...LAYOUT_TAIL}>
          <Button loading={isLoading} type="primary" htmlType="submit">
            {isUndefined ? 'Create' : 'Update'}
          </Button>
        </Form.Item>
      </Form>

      <Drawer
        title="Manage contacts"
        width={720}
        onClose={onCloseDrawer}
        visible={drawer}
        bodyStyle={{ paddingBottom: 80 }}
        destroyOnClose
      >
        <ContactMediumDrawer
          onSubmit={handleSetContacts}
          contacts={contactMediums}
          allowedContactMediaTypes={[
            ContactMediumType.EMAIL,
            ContactMediumType.POSTAL,
            ContactMediumType.TELEPHONE,
            ContactMediumType.WEB,
          ]}
        />
      </Drawer>
    </div>
  );
};

export default OrganisationForm;
