import { Table } from 'antd';
import React from 'react';
import { Loan, LoanEvent, LoanEventState } from 'api/types';
import { useLoanEvents } from 'hooks/withLoanEvent';
import { CheckCircleOutline } from '@mui/icons-material';
import { CheckCircleOutlined, ExceptionOutlined } from '@ant-design/icons';
import ExplainsTable from 'components/ExplainsTable';
import { getDateString } from 'utils/date';

type ListLoanEventsTableProps = {
  loanId: number;
};
const LoanEventsTable: React.FC<ListLoanEventsTableProps> = ({ loanId }) => {
  const [events, isEventsLoading] = useLoanEvents(loanId);

  const columns = [
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      render: (event) => {
        switch (event) {
          case LoanEventState.FAILED:
            return (
              <div>
                <ExceptionOutlined /> <span>Failed</span>
              </div>
            );
          case LoanEventState.PROCESSING:
            return (
              <div>
                <CheckCircleOutline /> <span>Processing</span>
              </div>
            );
          case LoanEventState.QUEUED:
            return (
              <div>
                <CheckCircleOutline /> <span>Queued</span>
              </div>
            );
          case LoanEventState.COMPLETED:
            return (
              <div>
                <CheckCircleOutlined color="green" /> <span>Completed</span>
              </div>
            );
        }
      },
    },
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Created',
      dataIndex: 'created',
      key: 'created',
      render: (data) => getDateString(data),
    },
    {
      title: 'Loan Id',
      dataIndex: 'loanId',
      key: 'loanId',
    },
  ];

  return (
    <Table
      dataSource={events}
      columns={columns}
      pagination={{ defaultPageSize: 10 }}
      loading={isEventsLoading}
      rowKey={(loanEvent) => loanEvent.id}
      size="small"
      expandable={{
        expandedRowRender: (event: LoanEvent) => (
          <ExplainsTable eventId={event.id} />
        ),
      }}
    />
  );
};
export default LoanEventsTable;
