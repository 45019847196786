import React, { useCallback } from 'react';

import { Button, Form, Input, Typography } from 'antd';

import { useParams } from 'react-router-dom';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

import { useFormLoginAuth } from 'hooks/withFormLogin';

type FieldType = {
  userId?: string;
  password?: string;
};

type IdParams = {
  userId: string;
};

const FormLoginView: React.FC = () => {
  const [formInstance] = Form.useForm();

  const { userId } = useParams<keyof IdParams>() as IdParams;

  const [handleLoginRequest, isLoginRequestInProgress] = useFormLoginAuth();

  const handleLogin = useCallback(
    ({ password, userId: username }) => {
      handleLoginRequest({ username, password });
    },
    [handleLoginRequest]
  );

  return (
    <Form
      name="login_form"
      onFinish={handleLogin}
      autoComplete="off"
      form={formInstance}
      initialValues={{ userId }}
    >
      <Typography.Text>User Id:</Typography.Text>
      <Form.Item<FieldType>
        name="userId"
        rules={[
          {
            required: true,
            message: 'Please input a valid login id!',
          },
        ]}
      >
        <Input prefix={<UserOutlined />} placeholder="Username" />
      </Form.Item>
      <Typography.Text>Password:</Typography.Text>
      <Form.Item<FieldType>
        name="password"
        rules={[{ required: true, message: 'Please input your password' }]}
      >
        <Input.Password prefix={<LockOutlined />} placeholder="Password" />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={isLoginRequestInProgress}
        >
          Log In
        </Button>
      </Form.Item>
    </Form>
  );
};

export default FormLoginView;
