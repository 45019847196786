import { Card, Col, Row } from 'antd';
import {
  BankContact,
  ContactMedium,
  ContactMediumType,
  NamedEmailContact,
  PartyRole,
  PostalContact,
  TelephoneContact,
  WebContact,
} from 'api/types';

type ListRolesModalProps = {
  partyRole: PartyRole;
};

function getName(contact: ContactMedium) {
  if ('name' in contact && contact.name) {
    return (
      <div>
        <b>Name:</b> {contact.name}
      </div>
    );
  }
}

const ListRolesExpanded: React.FC<ListRolesModalProps> = ({ partyRole }) => {
  return (
    <Row gutter={16}>
      {partyRole?.contactMediums?.length
        ? partyRole?.contactMediums?.map((contactMedium) => {
            switch (contactMedium.type) {
              case ContactMediumType.NAMED_EMAIL:
              case ContactMediumType.EMAIL:
                const email = contactMedium as NamedEmailContact;
                return (
                  <Col span={6}>
                    <Card bordered={false} title="Email">
                      <span>
                        {getName(email)}
                        <b>Address:</b> {email.address}
                      </span>
                    </Card>
                  </Col>
                );
              case ContactMediumType.NAMED_POSTAL:
              case ContactMediumType.POSTAL:
                const postal = contactMedium as PostalContact;
                return (
                  <Col span={6}>
                    <Card bordered={false} title="Postal Address">
                      {getName(postal)}
                      <div>
                        <b>Street:</b> {postal.street?.name}
                      </div>
                      <div>
                        <b>Segment:</b> {postal.street?.segment}
                      </div>
                      <div>
                        <b>City:</b> {postal.city.name}
                      </div>
                      <div>
                        <b>State:</b> {postal.stateOrProvince?.name}
                      </div>
                      <div>
                        <b>Country:</b> {postal.country?.name}
                      </div>
                    </Card>
                  </Col>
                );
              case ContactMediumType.NAMED_TELEPHONE:
              case ContactMediumType.TELEPHONE:
                const telephone = contactMedium as TelephoneContact;
                return (
                  <Col span={6}>
                    <Card bordered={false} title="Telephone">
                      {getName(telephone)}
                      <div>
                        <b>Number:</b> {telephone.number}
                      </div>
                      <div>
                        <b>Type:</b> {telephone.numberType}
                      </div>
                    </Card>
                  </Col>
                );
              case ContactMediumType.WEB:
                const web = contactMedium as WebContact;
                return (
                  <Col span={6}>
                    <Card bordered={false} title="Web">
                      {getName(web)}
                      <div>
                        <b>Address:</b> {web.address}
                      </div>
                    </Card>
                  </Col>
                );
              case ContactMediumType.BANK:
                const bank = contactMedium as BankContact;
                return (
                  <Col span={6}>
                    <Card bordered={false} title="Bank info">
                      {getName(bank)}
                      <div>
                        <b>BIC:</b> {bank.bic}
                      </div>
                      <div>
                        <b>IBAN:</b> {bank.iban}
                      </div>
                      <div>
                        <b>Bankgiro:</b> {bank.bankGiro}
                      </div>
                    </Card>
                  </Col>
                );
            }
          })
        : 'No Contact Mediums.'}
    </Row>
  );
};
export default ListRolesExpanded;
