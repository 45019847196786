import axios from 'axios';

import { ENDPOINT_BASE } from 'constants/index';
import { Setting, UnidentifiedSetting } from './types';
const SETTINGS_PATH = 'settings';
const baseUrl = `${ENDPOINT_BASE}${SETTINGS_PATH}`;

const client = axios.create({
  withCredentials: true,
  baseURL: baseUrl,
});

export async function addSetting(
  request: UnidentifiedSetting
): Promise<Setting> {
  const response = await client.post<Setting>('', request);
  return response.data;
}

export async function updateSetting(request: Setting): Promise<Setting> {
  const response = await client.put<Setting>('', request);
  return response.data;
}

export async function getSettings(): Promise<Setting[]> {
  const response = await client.get<Setting[]>('');
  return response.data;
}

export async function getSettingsByName(name: string): Promise<Setting[]> {
  const response = await client.get<Setting[]>(`/${name}`);
  return response.data;
}

export async function deleteSetting(id: string): Promise<void> {
  await client.delete(`/${id}`);
}
