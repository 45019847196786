import { PaginationQuery } from '../api/types';
import { Dayjs } from 'dayjs';
import type { FilterValue } from 'antd/lib/table/interface';

export function buildQuery(args: PaginationQuery & Record<string, string>) {
  const { page, size, sort, ...rest } = args;

  return {
    criteria: rest,
    page,
    size,
    sort,
  };
}

function createFromEnum(
  key: string,
  value: boolean | React.Key,
  enumeration: any
): string {
  let segment = '';
  const matchingEnumValues = getMatchingEnumValues(value, enumeration);

  for (let i = 0; i < matchingEnumValues.length; i++) {
    if (i == 0) {
      segment = `${key} = '${matchingEnumValues[i]}'`;
    } else if (i > 0) {
      segment = `${segment} OR ${key} = '${matchingEnumValues[i]}'`;
    }
  }

  return segment;
}

function getMatchingEnumValues(
  value: boolean | React.Key,
  enumeration: any
): string[] {
  const val = value ? value.toString() : '';
  return Object.entries(enumeration)
    .filter((t) => matchEntry(val, t))
    .map((t) => t[0]);
}

function matchEntry(value: string, entry: [string, unknown]): boolean {
  const stringValue = entry[1] ? entry[1].toString() : '';

  return (
    value != '' &&
    (entry[0].toUpperCase().includes(value.toUpperCase()) ||
      stringValue.toUpperCase().includes(value.toUpperCase()))
  );
}

export function convertToQuery(
  filters: Record<string, FilterValue | null>,
  stringKeys: Set<string>,
  quotedKeys: Set<string>,
  enumKeys: Map<string, any>,
  dateRanges: [start: Dayjs | null | undefined, end: Dayjs | null | undefined]
) {
  const querySegments: string[] = [];

  for (const key in filters) {
    const values = filters[key];

    if (!values) continue;

    for (const value of values) {
      let segment = '';
      if (stringKeys.has(key)) {
        segment = `${key} LIKE '%${value}%'`;
      } else if (quotedKeys.has(key)) {
        segment = `${key} = '${value}'`;
      } else if (enumKeys.has(key)) {
        segment = createFromEnum(key, value, enumKeys.get(key));
      } else {
        segment = `${key} = ${value}`;
      }
      querySegments.push(segment);
    }
  }

  if (dateRanges[0] && dateRanges[1]) {
    const datesFormat = 'YYYY-MM-DD';
    const start = dateRanges[0]?.format(datesFormat);
    const end = dateRanges[1]?.format(datesFormat);
    querySegments.push(`startDate <= '${start}' AND endDate >= '${end}'`);
  }

  if (querySegments.length === 0) return undefined;

  return querySegments.join(' AND ');
}
