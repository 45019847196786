import { SettingDescriptor, SettingType } from 'api/types';

export const ENDPOINT_BASE = window.__ENDPOINT_BASE__;

export const LAYOUT = { labelCol: { span: 6 }, wrapperCol: { span: 16 } };
export const LAYOUT_TAIL = { wrapperCol: { offset: 6, span: 16 } };

export const REFERENCE_INTEREST: SettingDescriptor =
{
  name: 'Reference Interest',
  enum: 'reference_interest',
  type: SettingType.BIG_DECIMAL,
  value: '0.23',
};

export const SETTING_DESCRIPTORS: SettingDescriptor[] = [REFERENCE_INTEREST];

export const SETTING_TYPES: SettingType[] = [
  SettingType.BIG_DECIMAL,
  SettingType.INTEGER,
  SettingType.LONG,
];


export const CAPITALISED_DEBT_LABEL = 'Capitalised debt';

export const ACCRUED_INTEREST_LABEL = 'Accrued interest';

export const DRAW_DOWN_LABEL = 'Draw downs';

export const SETUP_FEE_LABEL = 'Setup fees';

export const EXTENSION_FEE_LABEL = 'Extension fees';

export const PAYMENT_LABEL = 'Payments';

export const PAYOFF_LABEL = 'Payoffs';

export const URLS = {
  start: '/',
  scanQr: '/scan-qr',
  openDesktopApp: '/open-desktop',
  openMobileApp: '/open-mobile-app',
  resultSuccess: '/',
  resultFailed: '/result-failed',
  about: '/about',
};
