import React, { useState } from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Table, Button, Popconfirm, Modal } from 'antd';
import {
  useDeleteOrganisation,
  useGetOrganisations,
  useUpdateOrganisation,
} from 'hooks/withOrganisation';
import { Organisation } from 'api/types';
import { getDateString } from 'utils/date';

import OrganisationForm from 'components/forms/OrganisationForm';
import ListOrganisationModal from './ListOrganisationModal';

const ListOrganisationsView: React.FC = () => {
  const deleteOrganisation = useDeleteOrganisation();

  const handleDelete = (id) => {
    deleteOrganisation.mutate(id);
  };

  const [organisations, isOrganisationsLoading] = useGetOrganisations();

  const [originalOrganisation, setOriginalOrganisation] = useState<
    Organisation | undefined
  >(undefined);

  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);

  const [updateOrganisation, updateOrganisationStatus] =
    useUpdateOrganisation();

  const handleEditOrganisation = (organisation: Organisation) => {
    setOriginalOrganisation(organisation);
    setIsModalOpened(true);
  };

  const onClose = () => {
    setOriginalOrganisation(undefined);
    setIsModalOpened(false);
  };

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (item) => Object.values(item)[2],
    },

    {
      title: 'Identification Number',
      dataIndex: 'identificationNumber',
      key: 'identificationNumber',
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
    },
    {
      title: 'Created',
      dataIndex: 'created',
      key: 'created',
      render: (data) => getDateString(data),
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      render: (organisation) => (
        <div className="flex flex-row">
          <Popconfirm
            title="Please confirm"
            description="Are you sure you want to delete this organisation?"
            onConfirm={() => handleDelete(organisation.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">
              <DeleteOutlined style={{ color: 'red' }} />
            </Button>
          </Popconfirm>
          <Button
            type="link"
            onClick={() => handleEditOrganisation(organisation)}
          >
            <EditOutlined />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="mt-10">
      <h1 className="mb-0 py-5">List Organisations</h1>
      <div className="flex flex-col mt-5">
        <Table
          dataSource={organisations}
          columns={columns}
          pagination={{ defaultPageSize: 10 }}
          loading={isOrganisationsLoading}
          rowKey={(organisation) => organisation.id}
          expandable={{
            expandedRowRender: (organisation) => (
              <ListOrganisationModal organisation={organisation} />
            ),
          }}
        />
        <Modal
          open={isModalOpened}
          onCancel={onClose}
          width={800}
          footer={null}
          destroyOnClose
          title="Update Organisation"
        >
          <OrganisationForm
            initialData={originalOrganisation}
            onUpdate={updateOrganisation}
            isLoading={updateOrganisationStatus === 'pending'}
            callback={onClose}
          />
        </Modal>
      </div>
    </div>
  );
};

export default ListOrganisationsView;
