import React from 'react';

const FindLedgerView: React.FC = () => {
  return (
    <div className="mt-10">
      <h1 className="mb-0 py-5">Find Ledger</h1>
      <div className="flex flex-col mt-5">
        The user can choose a loan and see the ledger entries between a from and
        to date.
      </div>
    </div>
  );
};

export default FindLedgerView;
