import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { getInvoice } from 'api/invoiceClient';
import { CreateInvoiceRequest } from 'api/types';
import { message } from 'antd';

export const useCreateInvoice = (): [
  UseMutateFunction<unknown, Error, CreateInvoiceRequest, unknown>,
  'error' | 'idle' | 'pending' | 'success'
] => {
  const { mutate, status } = useMutation({
    mutationFn: getInvoice,
    onError: () => {
      message.error('Error while creating loan event request');
    },
    onSuccess: (data, variables) => {
      const href = URL.createObjectURL(data);

      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', `Invoice for loan ${variables.loanId}.pdf`);
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      message.success('Successfully generated invoice!');
    },
  });

  return [mutate, status];
};
