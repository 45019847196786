import React, { useCallback, useEffect, useState } from 'react';
import { Loan, LoanDetails } from 'api/types';
import { Button, Form, Space, Tabs, TabsProps } from 'antd';
import LoanDetailGeneral from 'components/LoanDetailGeneral';
import LoanDetailStatistics from 'components/LoanDetailStatistics';
import LoanDetailCurrentStatus from 'components/LoanDetailCurrentStatus';
import LoanDetailInterest from 'components/LoanDetailInterest';
import { useLoanDetails, useUpdateLoan } from 'hooks/withLoan';

type LoanDetailsProps = {
  loan: Loan | undefined;
  onCancel: () => void;
  onSubmit: () => void;
  setUpdateLoanStatus: React.Dispatch<
    React.SetStateAction<'error' | 'idle' | 'pending' | 'success'>
  >;
};

type FormData = {
  loan: Loan | undefined;
  loanDetails: LoanDetails | undefined;
};

const LoanDetailsView: React.FC<LoanDetailsProps> = ({
  loan,
  onCancel,
  onSubmit,
  setUpdateLoanStatus,
}) => {
  const [form] = Form.useForm<FormData>();

  const [loanDetails, isLoanDetailsLoading] = useLoanDetails(loan?.id);
  const [updateLoan, updateLoanStatus] = useUpdateLoan();

  useEffect(() => {
    setUpdateLoanStatus(updateLoanStatus);
  }, [setUpdateLoanStatus, updateLoanStatus]);

  const [formData, setFormData] = useState<FormData>();

  const mapCustomerManagerToSelect = useCallback(() => {
    return loan?.customerManager;
  }, [loan]);

  const mapCustomerSupportToSelect = useCallback(() => {
    return loan?.customerSupport;
  }, [loan]);

  useEffect(() => {
    setFormData({
      loan,
      loanDetails,
    });
  }, [
    loan,
    loanDetails,
    mapCustomerManagerToSelect,
    mapCustomerSupportToSelect,
  ]);

  const [currentLoan, setCurrentLoan] = useState(loan);

  const handleUpdateLoan = useCallback((updatedLoan: Loan) => {
    setCurrentLoan(updatedLoan);
  }, []);

  const handleSubmit = useCallback(() => {
    if (currentLoan) {
      updateLoan(currentLoan);
      onSubmit();
    }
  }, [currentLoan, onSubmit, updateLoan]);

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'General',
      children: (
        <LoanDetailGeneral
          form={form}
          loan={loan}
          loanDetails={loanDetails}
          submitLoan={handleUpdateLoan}
        />
      ),
    },
    {
      key: '2',
      label: 'Statistics',
      children: <LoanDetailStatistics loan={loan} />,
    },
    {
      key: '3',
      label: 'Current Status',
      children: (
        <LoanDetailCurrentStatus loan={loan} loanDetails={loanDetails} />
      ),
    },
    {
      key: '4',
      label: 'Interest',
      children: <LoanDetailInterest loan={loan} loanDetails={loanDetails} />,
    },
  ];

  return (
    <Form
      {...{ labelCol: { span: 8 }, wrapperCol: { span: 14 } }}
      form={form}
      onFinish={handleSubmit}
      disabled={isLoanDetailsLoading}
      initialValues={formData}
    >
      <Tabs defaultActiveKey="1" items={items} />
      <Space>
        <Button
          type="primary"
          htmlType="submit"
          disabled={updateLoanStatus == 'pending'}
        >
          Apply
        </Button>
        <Button onClick={onCancel}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default LoanDetailsView;
