import axios from 'axios';

import { ENDPOINT_BASE } from 'constants/index';
import { Lender } from './types';

const LENDER_PATH = 'lender';
const baseUrl = `${ENDPOINT_BASE}${LENDER_PATH}`;

const client = axios.create({
  withCredentials: true,
  baseURL: baseUrl,
});

export async function getLenders(): Promise<Lender[]> {
  const response = await client.get<Lender[]>('');
  return response.data;
}
