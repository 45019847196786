import { Loan } from 'api/types';
import React from 'react';

type LoanDetailsProps = {
  loan: Loan | undefined;
};

const LoanDetailStatistics: React.FC<LoanDetailsProps> = ({ loan }) => {
  return <>Statistics</>;
};

export default LoanDetailStatistics;
