import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useLocation, useNavigate } from 'react-router-dom';
import CreateSetting from './CreateSetting';
import ListSettings from './ListSettings';
import ReferenceInterest from './ReferenceInterest/ReferenceInterest';

const routesOrder = ['/settings/create', '/settings/list', '/settings/table'];

const SettingView = () => {
  const location = useLocation();

  const defaultIndex = routesOrder.indexOf(location.pathname);

  const navigate = useNavigate();

  return (
    <div>
      <div className="card">
        <Tabs
          onSelect={(idx) => {
            navigate(routesOrder[idx]);
          }}
          defaultIndex={defaultIndex === -1 ? 0 : defaultIndex}
        >
          <TabList>
            <Tab>Create setting</Tab>
            <Tab>List settings</Tab>
            <Tab>Reference Interest</Tab>
          </TabList>

          <TabPanel>
            <CreateSetting />
          </TabPanel>
          <TabPanel>
            <ListSettings />
          </TabPanel>
          <TabPanel>
            <ReferenceInterest />
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

export default SettingView;
