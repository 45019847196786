import React, { useCallback, useEffect, useState } from 'react';
import { Button, Drawer, Form, Input, Select } from 'antd';
import {
  AuthorizationRole,
  Borrower,
  BorrowerType,
  Broker,
  ContactMedium,
  ContactMediumType,
  Developer,
  Lender,
  Name,
  Party,
  PartyRole,
  PartyRoles,
  PartyRoleType,
  Risk,
  User,
} from 'api/types';
import ContactMediumsAccordion from 'components/accordion/ContactMediums';
import ContactMediumDrawer from 'components/drawer/ContactMediumDrawer';
import { LAYOUT, LAYOUT_TAIL } from 'constants/index';
import { useGetOrganisations } from 'hooks/withOrganisation';
import PasswordVerificationDisplay from 'components/forms/PasswordForm/PasswordVerificationDisplayForm';
import { useGetIndividuals } from 'hooks/withIndividual';
import type { Rule } from 'rc-field-form/lib/interface';

function getLabelName(name: Name): string {
  if ('firstName' in name) {
    return name.firstName + ' ' + name.lastName;
  } else if ('name' in name) {
    return name.name;
  }
  return 'Name type is not defined';
}

function getAllowedContactMediaTypes(
  partyRoleType: PartyRoleType | undefined
): ContactMediumType[] {
  switch (partyRoleType) {
    case PartyRoleType.LENDER:
      return [
        ContactMediumType.EMAIL,
        ContactMediumType.TELEPHONE,
        ContactMediumType.POSTAL,
        ContactMediumType.BANK,
        ContactMediumType.WEB,
      ];
    case PartyRoleType.DEVELOPER:
    case PartyRoleType.BORROWER:
      return [
        ContactMediumType.NAMED_EMAIL,
        ContactMediumType.NAMED_TELEPHONE,
        ContactMediumType.NAMED_POSTAL,
        ContactMediumType.WEB,
      ];

    case PartyRoleType.BROKER:
    case PartyRoleType.USER:
    default:
      return [
        ContactMediumType.EMAIL,
        ContactMediumType.TELEPHONE,
        ContactMediumType.POSTAL,
      ];
  }
}

/*export type CreateRoleRequest = {
  request: PartyRoles | undefined;
  party: Party;
};*/

type PartyRoleFormProps = {
  initialData?: PartyRoles;
  partyRoleType?: PartyRoleType;
  onCreateOrUpdate: (data: PartyRoles) => void;
  isUpdate: boolean;
  //onUpdate?: (data: CreatePartyRolesRequest) => void;
  isLoading?: boolean;
  callback?: () => void;
};

type FormData = PartyRoles & {
  selectParty: string;
  password: string;
};

const RoleForm: React.FC<PartyRoleFormProps> = ({
  initialData,
  partyRoleType,
  onCreateOrUpdate,
  isUpdate,
  isLoading,
  callback,
}) => {
  const [form] = Form.useForm<FormData>();

  // Watch all values
  const formValues = Form.useWatch([], form);

  const loadOrganisationData = useCallback((): boolean => {
    return PartyRoleType.USER != partyRoleType;
  }, [partyRoleType]);

  const [organisations, isOrganisationsLoading] = useGetOrganisations(
    !loadOrganisationData()
  );

  const [individuals, isIndividualsLoading] = useGetIndividuals(
    loadOrganisationData()
  );

  const getSelectedParty = useCallback(
    (values: FormData): Party => {
      if (loadOrganisationData()) {
        return organisations.find(
          (organisation) =>
            organisation.identificationNumber === values.selectParty
        ) as Party;
      } else {
        return individuals.find(
          (organisation) =>
            organisation.identificationNumber === values.selectParty
        ) as Party;
      }
    },
    [individuals, loadOrganisationData, organisations]
  );

  const partyOptions = useCallback(() => {
    if (loadOrganisationData()) {
      return organisations?.map((organisation) => ({
        value: organisation.identificationNumber,
        label: `${getLabelName(organisation.name)} ${
          organisation.identificationNumber
        }`,
      }));
    } else {
      return individuals?.map((individual) => ({
        value: individual.identificationNumber,
        label: `${getLabelName(individual.name)} ${
          individual.identificationNumber
        }`,
      }));
    }
  }, [individuals, loadOrganisationData, organisations]);

  const mapPartyToSelect = useCallback(() => {
    return initialData?.party.identificationNumber;
  }, [initialData]);

  const kycOptions = useCallback(() => {
    return [
      { value: true, label: 'Yes' },
      { value: false, label: 'No' },
    ];
  }, []);

  /*****************************************************************************************
   * States
   ****************************************************************************************/
  const [contactMediums, setContactMediums] = useState<ContactMedium[]>(() => {
    return initialData?.contactMediums ?? [];
  });
  const [drawer, setDrawer] = useState<boolean>(false);

  const [submittable, setSubmittable] = useState<boolean>(false);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch((reason) => {
        if (reason.errorFields.size > 0) {
          setSubmittable(false);
        } else {
          setSubmittable(true);
        }
      });
  }, [form, formValues]);

  const handleSubmit = useCallback(
    async (values: FormData) => {
      let request: PartyRoles;
      let initial;
      switch (partyRoleType) {
        case PartyRoleType.LENDER:
          initial = {
            id: values.id,
            type: PartyRoleType.LENDER,
            contactMediums: contactMediums,
          };

          request = {
            party: getSelectedParty(values),
            ...initial,
          } as Lender;
          break;
        case PartyRoleType.BORROWER:
          initial = {
            id: values.id,
            type: PartyRoleType.BORROWER,
            contactMediums: contactMediums,
            kyc: (values as Borrower).kyc,
            risk: (values as Borrower).risk,
            borrowerType: (values as Borrower).borrowerType,
            customerManagerId: (values as Borrower).customerManagerId,
            bankAccountNumber: (values as Borrower).bankAccountNumber,
          };

          request = {
            party: getSelectedParty(values),
            ...initial,
          } as Borrower;
          break;
        case PartyRoleType.BROKER:
          initial = {
            id: values.id,
            type: PartyRoleType.BROKER,
            contactMediums: contactMediums,
          };

          request = {
            party: getSelectedParty(values),
            ...initial,
          } as Broker;
          break;
        case PartyRoleType.DEVELOPER:
          initial = {
            id: values.id,
            type: PartyRoleType.DEVELOPER,
            contactMediums: contactMediums,
          };

          request = {
            party: getSelectedParty(values),
            ...initial,
          } as Developer;
          break;
        case PartyRoleType.USER:
          initial = {
            id: values.id,
            type: PartyRoleType.USER,
            contactMediums: contactMediums,
            authorizationRoles: (values as User).authorizationRoles,
            password: values.password,
          };

          request = {
            party: getSelectedParty(values),
            ...initial,
          } as User;
          break;
        default:
          throw new Error('PartyRoleForm: no party role type specified!');
      }
      onCreateOrUpdate(request);

      if (callback) {
        callback();
      }
    },
    [
      onCreateOrUpdate,
      callback,
      partyRoleType,
      getSelectedParty,
      contactMediums,
    ]
  );

  const handleContactMediumRemoved = useCallback(
    (removedIndex: number) => () => {
      setContactMediums((cm) => [
        ...cm.filter((_, index) => index != removedIndex),
      ]);
    },
    []
  );

  const onShowDrawer = useCallback(() => {
    setDrawer(true);
  }, []);

  const onCloseDrawer = useCallback(() => {
    setDrawer(false);
  }, []);

  const handleSetContacts = useCallback(
    (data: ContactMedium[]) => {
      setContactMediums(data);
      onCloseDrawer();
    },
    [setContactMediums, onCloseDrawer]
  );

  const ruleValidation = useCallback(
    (
      message: string,
      requiredForAllRoles: boolean | undefined,
      roleType?: PartyRoleType | undefined
    ): Rule[] => {
      const required = requiredForAllRoles || partyRoleType == roleType;

      return [
        {
          required: required,
          message: message,
        },
        () => ({
          validator(_, value) {
            if (required && value == undefined) {
              return Promise.reject(new Error());
            }
            return Promise.resolve();
          },
        }),
      ];
    },
    [partyRoleType]
  );

  const isUndefined = initialData === undefined;

  /*****************************************************************************************
   * JSX
   ****************************************************************************************/
  return (
    <div>
      <Form
        {...LAYOUT}
        form={form}
        initialValues={{
          required: false,
          selectParty: mapPartyToSelect(),
          //selectKyc: mapKycToSelect(),
          ...initialData,
        }}
        onFinish={handleSubmit}
        autoComplete="off"
        hidden={partyRoleType === undefined}
      >
        {/** Id */}
        <Form.Item hidden={isUndefined} name={['id']} label="Id">
          <Input disabled />
        </Form.Item>

        {/** Party */}
        <Form.Item
          label="Party"
          name="selectParty"
          rules={ruleValidation('Please select a party!', true)}
        >
          <Select
            showSearch
            placeholder="Party"
            loading={isOrganisationsLoading || isIndividualsLoading}
            filterOption
            options={partyOptions()}
            optionFilterProp="label"
          />
        </Form.Item>

        {/* KYC for Borrower */}
        <Form.Item
          label="KYC Approved"
          hidden={PartyRoleType.BORROWER != partyRoleType}
          name="kyc"
          rules={ruleValidation(
            'Please denote KYC Approved state!',
            false,
            PartyRoleType.BORROWER
          )}
        >
          <Select
            options={kycOptions()}
            optionFilterProp="label"
            filterOption
          />
        </Form.Item>

        {/* Risk for Borrower */}
        <Form.Item
          label="Risk"
          hidden={PartyRoleType.BORROWER != partyRoleType}
          name="risk"
          rules={ruleValidation(
            'Please state the risk!',
            false,
            PartyRoleType.BORROWER
          )}
          //rules={[{ required: true, message: 'Please state the risk!' }]}
        >
          <Select
            options={Object.entries(Risk).map(([value, name]) => ({
              label: name,
              value: value,
            }))}
          />
        </Form.Item>

        {/* Type for Borrower */}
        <Form.Item
          label="Borrower Type"
          hidden={PartyRoleType.BORROWER != partyRoleType}
          name="borrowerType"
          rules={ruleValidation(
            'Please state the borrower type!',
            false,
            PartyRoleType.BORROWER
          )}
          /*rules={[
            { required: true, message: 'Please state the borrower type!' },
          ]}*/
        >
          <Select
            options={Object.entries(BorrowerType).map(([value, name]) => ({
              label: name,
              value: value,
            }))}
          />
        </Form.Item>

        {/* Customer Manager for Borrower */}
        <Form.Item
          label="Customer Manager Id"
          hidden={PartyRoleType.BORROWER != partyRoleType}
          name="customerManagerId"
          rules={ruleValidation(
            'Please state the customer manager id!',
            false,
            PartyRoleType.BORROWER
          )}
        >
          <Input type="text"></Input>
        </Form.Item>

        {/* Account Number for Borrower */}
        <Form.Item
          label="Bank Account Number"
          hidden={PartyRoleType.BORROWER != partyRoleType}
          name="bankAccountNumber"
        >
          <Input type="text"></Input>
        </Form.Item>

        {/* Authorization Roles for User */}
        <Form.Item
          label="Authorization Roles"
          hidden={PartyRoleType.USER != partyRoleType}
          name="authorizationRoles"
          rules={ruleValidation(
            'Please state the authorization roles!',
            false,
            PartyRoleType.USER
          )}
        >
          <Select
            mode="multiple"
            options={Object.entries(AuthorizationRole).map(([value, name]) => ({
              label: name,
              value: value,
            }))}
          />
        </Form.Item>

        {/* Password for User */}
        {PartyRoleType.USER == partyRoleType && !isUpdate ? (
          <Form.Item label="Password" name="password">
            <PasswordVerificationDisplay form={form} />
          </Form.Item>
        ) : (
          <></>
        )}

        {/** Contact mediums */}
        <Form.Item label="Contacts" required>
          {contactMediums.length > 0 ? (
            <ContactMediumsAccordion
              contacts={contactMediums}
              handleContactRemoved={handleContactMediumRemoved}
            />
          ) : null}
          <Button type="link" onClick={onShowDrawer}>
            Manage Contacts
          </Button>
        </Form.Item>
        <Form.Item {...LAYOUT_TAIL}>
          <Button
            loading={isLoading}
            type="primary"
            htmlType="submit"
            disabled={!submittable}
          >
            {isUpdate ? 'Update' : 'Create'}
          </Button>
        </Form.Item>
      </Form>

      <Drawer
        title="Manage contacts"
        width={720}
        onClose={onCloseDrawer}
        open={drawer}
        bodyStyle={{ paddingBottom: 80 }}
        destroyOnClose
      >
        <ContactMediumDrawer
          onSubmit={handleSetContacts}
          contacts={contactMediums}
          allowedContactMediaTypes={getAllowedContactMediaTypes(partyRoleType)}
        />
      </Drawer>
    </div>
  );
};

export default RoleForm;
