import React, { useCallback, useState } from 'react';
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  RadioChangeEvent,
  Select,
  Switch,
} from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import {
  Loan,
  LoanEventState,
  LoanEventType,
  UnidentifiedLoanEvent,
} from 'api/types';

import { LAYOUT, LAYOUT_TAIL } from 'constants/index';

type LoanEventFormProps = {
  isLoading?: boolean;
  loan?: Loan;
  onSubmit: (data: UnidentifiedLoanEvent) => void;
};

type FormData = {
  id: number;
  loanId: number;
  type: LoanEventType;
  state: LoanEventState;
  created: Dayjs;
  capitalizedInterest: boolean;
  drawDown: number;
  feeOrPercentage: string;
  value: number;
  fee: number;
  endDate: Dayjs;
};

const LoanEventForm: React.FC<LoanEventFormProps> = ({
  isLoading,
  loan,
  onSubmit,
}) => {
  const [form] = Form.useForm<FormData>();

  const [type, setType] = useState<LoanEventType>(LoanEventType.CAPITALISED);

  const handleSubmit = useCallback(
    (values: FormData) => {
      if (type === LoanEventType.CAPITALISED) {
        onSubmit({
          type: values.type,
          loanId: values.loanId,
          capitalizedInterest: values.capitalizedInterest,
        });
      } else if (type === LoanEventType.DRAW_DOWN) {
        onSubmit({
          type: values.type,
          loanId: values.loanId,
          drawDown: values.drawDown,
        });
      } else if (type === LoanEventType.EXTEND) {
        onSubmit({
          type: values.type,
          loanId: values.loanId,
          percentage:
            values.feeOrPercentage === 'percentage' ? values.value : 0,
          fee: values.feeOrPercentage === 'fee' ? values.value : 0,
          endDate: values.endDate.toJSON(),
        });
      } else if (type === LoanEventType.SET_INTEREST) {
        onSubmit({
          type: values.type,
          loanId: values.loanId,
          interest: values.value,
        });
      } else if (type === LoanEventType.SETUP_FEE) {
        onSubmit({
          type: values.type,
          loanId: values.loanId,
          percentage:
            values.feeOrPercentage === 'percentage' ? values.value : 0,
          fee: values.feeOrPercentage === 'fee' ? values.value : 0,
        });
      }
      //created: initialData?.at ? dayjs.utc(initialData?.created).local() : dayjs(),
      //<DatePicker showTime={{ use12Hours: false }} />;
    },
    [onSubmit, type]
  );

  const handleSetType = useCallback(
    (event: RadioChangeEvent) => {
      const value = event?.target?.value;
      form.setFieldValue('type', value);
      if (event?.target?.value) {
        setType(value);
      }
    },
    [form]
  );

  const formatNumberSwedish = useCallback((value) => {
    return new Intl.NumberFormat('sv-SE', {
      style: 'decimal',
    }).format(value);
  }, []);

  /*****************************************************************************************
   * JSX
   ****************************************************************************************/
  return (
    <div>
      <Form
        {...LAYOUT}
        form={form}
        initialValues={{
          loanId: loan?.id,
          type: LoanEventType.CAPITALISED,
        }}
        onFinish={handleSubmit}
        autoComplete="off"
      >
        <Form.Item {...LAYOUT} label="Type">
          <Radio.Group onChange={handleSetType} value={type}>
            {Object.entries(LoanEventType).map(([value, name]) => (
              <Radio.Button value={value} key={value}>
                {name}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Form.Item>

        <Form.Item name="loanId" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="type" hidden>
          <Input />
        </Form.Item>

        {(() => {
          switch (type) {
            case LoanEventType.CAPITALISED: {
              return (
                <>
                  <Form.Item
                    name="capitalizedInterest"
                    label="Capitalized Interest"
                    initialValue={loan?.interest.capitalised}
                    required
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Switch />
                  </Form.Item>
                </>
              );
            }
            case LoanEventType.DRAW_DOWN: {
              return (
                <>
                  <Form.Item
                    name="drawDown"
                    label="Draw Down"
                    required
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      min={0}
                      decimalSeparator=","
                      formatter={formatNumberSwedish}
                    />
                  </Form.Item>
                </>
              );
            }
            case LoanEventType.EXTEND: {
              return (
                <>
                  <Form.Item
                    name="feeOrPercentage"
                    label="Fee or percentage"
                    initialValue="fee"
                  >
                    <Select
                      defaultValue="fee"
                      style={{ width: 120 }}
                      options={[
                        { value: 'fee', label: 'Fee' },
                        { value: 'percentage', label: 'Percentage' },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item
                    name="value"
                    label="Value"
                    required
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input type="number" min={0} step={0.1} />
                  </Form.Item>
                  <Form.Item
                    label="End Date"
                    name="endDate"
                    initialValue={dayjs(loan?.endDate)}
                    rules={[{ required: true, message: 'Please input!' }]}
                  >
                    <DatePicker />
                  </Form.Item>
                </>
              );
            }
            case LoanEventType.SET_INTEREST: {
              return (
                <>
                  <Form.Item
                    name="value"
                    label="Interest"
                    required
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input type="number" min={0} step={0.1} />
                  </Form.Item>
                </>
              );
            }
            case LoanEventType.SETUP_FEE: {
              return (
                <>
                  <Form.Item
                    name="feeOrPercentage"
                    label="Fee or percentage"
                    initialValue="fee"
                  >
                    <Select
                      defaultValue="fee"
                      value="fee"
                      style={{ width: 120 }}
                      options={[
                        { value: 'fee', label: 'Fee' },
                        { value: 'percentage', label: 'Percentage' },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item
                    name="value"
                    label="Value"
                    required
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      min={0}
                      decimalSeparator=","
                      formatter={formatNumberSwedish}
                    />
                  </Form.Item>
                </>
              );
            }
          }
        })()}

        <Form.Item {...LAYOUT_TAIL}>
          <Button loading={isLoading} type="primary" htmlType="submit">
            Create
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default LoanEventForm;

/*
const feeOrPercentageValue = {
  fee: [
    { required: false, message: 'Required' },
    (formInstance: { getFieldValue: (arg0: string) => unknown }) => ({
      message: 'Lower must not be greater than upper',
      validator(rule, value) {
        if (value === null) {
          return Promise.resolve();
        }

        if (formInstance.getFieldValue('percentage') !== undefined) {
          return Promise.reject(new Error());
        }

        const percentage: number = formInstance.getFieldValue(
          'percentage'
        ) as number;
        if (value > percentage) {
          return Promise.reject(new Error());
        }
        return Promise.resolve();
      },
    }),
  ],
  value: [{ required: true, message: 'Required' }],
  percentage: [{ required: true, message: 'Required' }],
};
*/
