import { message } from 'antd';
import { Setting, UnidentifiedSetting } from 'api/types';

import {
  useQuery,
  useMutation,
  UseMutateFunction,
  useQueryClient,
} from '@tanstack/react-query';
import {
  addSetting,
  deleteSetting,
  getSettings,
  getSettingsByName,
  updateSetting,
} from 'api/settingsClient';

export const useCreateSetting = (): [
  UseMutateFunction<Setting, Error, UnidentifiedSetting, unknown>,
  'error' | 'idle' | 'pending' | 'success'
] => {
  const { mutate, status } = useMutation({
    mutationFn: addSetting,
    onError: () => {
      message.error('Error while creating the setting!');
    },
    onSuccess: () => {
      message.success('Successfully creating the setting!');
    },
  });
  return [mutate, status];
};

export const useGetSettings = (): [Setting[], boolean] => {
  const query = useQuery({
    queryKey: [getSettings.name],
    queryFn: () => getSettings(),
    initialData: [],
  });

  return [query.data, query.isLoading];
};

export const useGetSettingsByName = (name: string): [Setting[], boolean] => {
  const query = useQuery({
    queryKey: [getSettingsByName.name],
    queryFn: () => getSettingsByName(name),
    initialData: [],
  });

  return [query.data, query.isLoading];
};

export const useDeleteSetting = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: deleteSetting,
    onError: () => {
      message.error('Error when deleting the setting!');
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [getSettings.name] });
      message.success('Successfully deleted the setting!');
    },
  });

  return mutation;
};

export const useUpdateSetting = (): [
  UseMutateFunction<Setting, Error, Setting, unknown>,
  'error' | 'idle' | 'pending' | 'success'
] => {
  const queryClient = useQueryClient();

  const { mutate, status } = useMutation({
    mutationFn: updateSetting,
    onError: () => {
      message.error('Error while updating the setting!');
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [getSettings.name],
      });
      message.success('Successfully updated setting!');
    },
  });

  return [mutate, status];
};
