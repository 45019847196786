import Localization from './localization/Localization';
import Sidebar from './sidebar/Sidebar';

const Contexts = ({ children }) => (
  <Localization>
    <Sidebar>{children}</Sidebar>
  </Localization>
);

export default Contexts;
