import React, { useCallback, useEffect, useState } from 'react';
import { Button, DatePicker, Drawer, Form, Input, Select } from 'antd';
import {
  ContactMedium,
  ContactMediumType,
  Gender,
  Individual,
  IndividualName,
  PartyState,
  PartyType,
  UnidentifiedIndividual,
} from 'api/types';
import ContactMediumsAccordion from 'components/accordion/ContactMediums';
import ContactMediumDrawer from 'components/drawer/ContactMediumDrawer';
import { LAYOUT, LAYOUT_TAIL } from 'constants/index';
import dayjs from 'dayjs';

type IndividualFormProps = {
  initialData?: Individual;
  onCreate?: (data: UnidentifiedIndividual) => void;
  onUpdate?: (data: Individual) => void;
  isLoading?: boolean;
  callback?: () => void;
};

type FormData = Omit<Individual, 'contactMediums' | 'state'> & {
  state?: string;
  bDate: string;
};

const dateFormat = 'YYYY-MM-DD';

function toDate(sDate: string | undefined): dayjs.Dayjs {
  if (!sDate) {
    sDate = '2000-01-01';
  }
  return dayjs(sDate?.toString(), dateFormat);
}

const IndividualForm: React.FC<IndividualFormProps> = ({
  initialData,
  onCreate,
  onUpdate,
  isLoading,
  callback,
}) => {
  const [form] = Form.useForm<FormData>();

  const [iData, setIData] = useState(initialData);

  useEffect(() => {
    if (initialData) {
      setIData(initialData);
    }
  }, [initialData]);

  const isUndefined = initialData === undefined;

  /*****************************************************************************************
   * States
   ****************************************************************************************/
  const [contactMediums, setContactMediums] = useState<ContactMedium[]>(() => {
    return initialData?.contactMediums ?? [];
  });
  const [drawer, setDrawer] = useState<boolean>(false);

  const handleSubmit = useCallback(
    async (values: FormData) => {
      if (isUndefined && onCreate) {
        onCreate({
          id: values.id,
          name: new IndividualName(
            iData ? iData.name.id : values.name.id,
            values.name.firstName,
            values.name.lastName
          ),
          birthDate: values.bDate,
          citizenships: values.citizenships,
          gender: values.gender,
          type: PartyType.INDIVIDUAL,
          state: 'ACTIVE',
          identificationNumber: { number: values.identificationNumber },
          contactMediums: contactMediums,
        } as UnidentifiedIndividual);
      } else if (onUpdate) {
        onUpdate({
          id: values.id,
          name: new IndividualName(
            iData ? iData.name.id : values.name.id,
            values.name.firstName,
            values.name.lastName
          ),
          birthDate: values.bDate,
          citizenships: values.citizenships,
          gender: values.gender,
          type: PartyType.INDIVIDUAL,
          state: values.state,
          version: values.version,
          identificationNumber: values.identificationNumber,
          contactMediums: contactMediums,
        } as Individual);
      } else {
        throw new Error('IndividualForm: no handler specified!');
      }
      if (callback) {
        callback();
      }
    },
    [isUndefined, onCreate, onUpdate, callback, iData, contactMediums]
  );

  const handleContactMediumRemoved = useCallback(
    (removedIndex: number) => () => {
      setContactMediums((cm) => [
        ...cm.filter((_, index) => index != removedIndex),
      ]);
    },
    []
  );

  const onShowDrawer = useCallback(() => {
    setDrawer(true);
  }, []);

  const onCloseDrawer = useCallback(() => {
    setDrawer(false);
  }, []);

  const handleSetContacts = useCallback(
    (data: ContactMedium[]) => {
      setContactMediums(data);
      onCloseDrawer();
    },
    [setContactMediums, onCloseDrawer]
  );

  /*****************************************************************************************
   * JSX
   ****************************************************************************************/
  return (
    <div>
      <Form
        {...LAYOUT}
        form={form}
        initialValues={{
          required: false,
          gender: Gender.UNKNOWN,
          state: 'ACTIVE',
          bDate: toDate(initialData?.birthDate),
          ...initialData,
        }}
        onFinish={handleSubmit}
        autoComplete="off"
      >
        {/** Id */}
        <Form.Item hidden={isUndefined} name={['id']} label="Id">
          <Input disabled />
        </Form.Item>

        {/** Id */}
        <Form.Item hidden={isUndefined} name={['version']} label="version">
          <Input disabled />
        </Form.Item>

        {/** Type */}
        <Form.Item hidden={true} name={['type']} label="Type">
          <Input />
        </Form.Item>

        {/** First Name */}
        <Form.Item
          rules={[
            {
              required: true,
            },
          ]}
          name={['name', 'firstName']}
          label="First Name"
        >
          <Input />
        </Form.Item>

        {/** Last Name */}
        <Form.Item
          rules={[
            {
              required: true,
            },
          ]}
          name={['name', 'lastName']}
          label="Last Name"
        >
          <Input />
        </Form.Item>

        {/** Identification Number */}
        <Form.Item
          name={['identificationNumber']}
          label="Identification Number"
          required
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input type="string" />
        </Form.Item>

        {/** Birth Date */}
        <Form.Item
          name={['bDate']}
          label="Birth Date"
          required
          rules={[
            {
              required: true,
              message: 'Birth Date is required',
            },
          ]}
        >
          <DatePicker />
        </Form.Item>

        {/** Gender */}
        <Form.Item
          name={['gender']}
          label="Gender"
          required
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder={'Select gender'}
            options={Object.entries(Gender).map(([value, name]) => ({
              label: name,
              value: value,
            }))}
          />
        </Form.Item>

        {/** State */}
        <Form.Item
          rules={[
            {
              required: true,
            },
          ]}
          name={['state']}
          label="State"
        >
          <Select>
            <Select.Option value={PartyState.ACTIVE}>Active</Select.Option>
            <Select.Option value={PartyState.INACTIVE}>Inactive</Select.Option>
          </Select>
        </Form.Item>

        {/** Contact mediums */}
        <Form.Item label="Contacts" required>
          {contactMediums.length > 0 ? (
            <ContactMediumsAccordion
              contacts={contactMediums}
              handleContactRemoved={handleContactMediumRemoved}
            />
          ) : null}
          <Button type="link" onClick={onShowDrawer}>
            Manage Contacts
          </Button>
        </Form.Item>
        <Form.Item {...LAYOUT_TAIL}>
          <Button loading={isLoading} type="primary" htmlType="submit">
            {isUndefined ? 'Create' : 'Update'}
          </Button>
        </Form.Item>
      </Form>

      <Drawer
        title="Manage contacts"
        width={720}
        onClose={onCloseDrawer}
        visible={drawer}
        bodyStyle={{ paddingBottom: 80 }}
        destroyOnClose
      >
        <ContactMediumDrawer
          onSubmit={handleSetContacts}
          contacts={contactMediums}
          allowedContactMediaTypes={[
            ContactMediumType.EMAIL,
            ContactMediumType.POSTAL,
            ContactMediumType.TELEPHONE,
          ]}
        />
      </Drawer>
    </div>
  );
};

export default IndividualForm;
