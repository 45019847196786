import axios from 'axios';

import { ENDPOINT_BASE } from 'constants/index';
import { PartyRoles, PartyRoleType } from './types';

const ROLES_PATH = 'roles';
const baseUrl = `${ENDPOINT_BASE}${ROLES_PATH}`;

const client = axios.create({
  withCredentials: true,
  baseURL: baseUrl,
});

export async function getPartyRolesOfType(
  roleType: PartyRoleType
): Promise<PartyRoles[]> {
  const response = await client.get<PartyRoles[]>(`/${roleType}`);
  return response.data;
}

export async function createPartyRole(
  request: PartyRoles
): Promise<PartyRoles> {
  const response = await client.post<PartyRoles>('', request);
  return response.data;
}

export async function updatePartyRole(
  request: PartyRoles
): Promise<PartyRoles> {
  const response = await client.put<PartyRoles>('', request);
  return response.data;
}

export async function deletePartyRole(id: string): Promise<void> {
  await client.delete(`/${id}`);
}
