import { LoanEvent, LoanEventState, UnidentifiedLoanEvent } from 'api/types';
import {
  Query,
  QueryClient,
  UseMutateFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { message } from 'antd';
import { createLoanEvent, getLoanEvents } from 'api/loanEventClient';
import { getMonthsInterestReport as getMonthInterestReports } from 'api/loanClient';
import { useState } from 'react';

export function useLoanEvents(id: number): [LoanEvent[], boolean] {
  const [hasBeenIncomplete, setHasBeenIncomplete] = useState<boolean>(false);

  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery({
    queryKey: [getLoanEvents.name, id],
    queryFn: () => getLoanEvents(id),
    refetchInterval: (
      query: Query<LoanEvent[], Error, LoanEvent[], (string | number)[]>
    ) => {
      return refetchIntervalHandler(
        query,
        queryClient,
        setHasBeenIncomplete,
        hasBeenIncomplete
      );
    },
    initialData: [],
  });
  return [data, isLoading];
}

function refetchIntervalHandler(
  query: Query<LoanEvent[], Error, LoanEvent[], (string | number)[]>,
  queryClient: QueryClient,
  setHasBeenIncomplete: React.Dispatch<React.SetStateAction<boolean>>,
  hasBeenIncomplete: boolean
): false | number {
  if (!query.state.data) {
    return false;
  }
  if (allIsCompeted(query.state.data)) {
    if (hasBeenIncomplete) {
      setHasBeenIncomplete(false);
      invalidateReports(queryClient, query.queryKey);
    }
    return false;
  } else {
    setHasBeenIncomplete(true);
    return 2_000;
  }
}

function allIsCompeted(data: LoanEvent[]): boolean {
  return !data.some(
    (loanEvent: LoanEvent) => LoanEventState.COMPLETED != loanEvent.state
  );
}

function invalidateReports(
  queryClient: QueryClient,
  request: (string | number)[]
) {
  queryClient.invalidateQueries({
    queryKey: [getMonthInterestReports.name, request.at(1)],
  });
}

export const useCreateLoanEvent = (): [
  UseMutateFunction<LoanEvent, Error, UnidentifiedLoanEvent, unknown>,
  'error' | 'idle' | 'pending' | 'success'
] => {
  const queryClient = useQueryClient();

  const { mutate, status } = useMutation({
    mutationFn: createLoanEvent,
    onError: () => {
      message.error('Error while creating loan event request');
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: [getLoanEvents.name, variables.loanId],
      });
      message.success('Successfully created loan event!');
    },
  });

  return [mutate, status];
};
