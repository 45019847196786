import { Spinner, Text } from '@chakra-ui/react';
import { useIsFetching } from '@tanstack/react-query';

export function Loading() {
  const isFetching = useIsFetching(); //Return the number of calls in the fetching state. 0 is falsy meaning display will become
  const display = isFetching ? 'inherit' : 'none';

  return (
    <Spinner
      thickness="4px"
      speed="0.65s"
      emptyColor="gray.200"
      color="blue.500"
      size="xl"
      role="status"
      position="fixed"
      zIndex="9999"
      top="50%"
      left="50%"
      transform="translate(-50%,-50%)"
      display={display}
    >
      <Text display="none">Loading...</Text>
    </Spinner>
  );
}
