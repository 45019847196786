import React from 'react';

import { Button, Collapse } from 'antd';

import { DeleteOutlined } from '@ant-design/icons';
import {
  BankContact,
  ContactMedium,
  ContactMediumType,
  EmailContact,
  PostalContact,
  TelephoneContact,
  WebContact,
} from 'api/types';

type ContactMediumsAccordionProps = {
  contacts: ContactMedium[];
  handleContactRemoved: (removedIndex: number) => () => void;
};

function getNameIfExist(contactMedium: ContactMedium): string {
  if ('name' in contactMedium && contactMedium.name) {
    return contactMedium.name + ': ';
  } else {
    return '';
  }
}

function getNameParagraph(contactMedium: ContactMedium): React.ReactElement {
  if ('name' in contactMedium) {
    return (
      <p className="m-0 p-0">
        <b>Name:</b> {contactMedium.name}
      </p>
    );
  } else {
    return <></>;
  }
}

const ContactMediumsAccordian: React.FC<ContactMediumsAccordionProps> = ({
  contacts,
  handleContactRemoved,
}) => {
  return (
    <Collapse accordion className="max-h-80 overflow-y-scroll">
      {contacts.map((contact, i) => {
        const key = JSON.stringify(contact).replace(' ', '');
        let headerText = '';
        switch (contact.type) {
          case ContactMediumType.NAMED_EMAIL:
          case ContactMediumType.EMAIL: {
            const data = contact as EmailContact;
            headerText = getNameIfExist(data) + data.address;
            return (
              <Collapse.Panel
                header={headerText}
                key={key}
                className="contacts"
              >
                {getNameParagraph(data)}
                <p className="m-0 p-0">
                  <b>Email:</b> {data.address}
                </p>
                <p>
                  <Button
                    type="text"
                    className="justify-center"
                    onClick={handleContactRemoved(i)}
                    icon={<DeleteOutlined />}
                    danger
                  />
                </p>
              </Collapse.Panel>
            );
          }
          case ContactMediumType.NAMED_POSTAL:
          case ContactMediumType.POSTAL: {
            const data = contact as PostalContact;
            headerText = getNameIfExist(data) + data.street?.name;
            return (
              <Collapse.Panel
                header={headerText}
                key={key}
                className="contacts"
              >
                {getNameParagraph(data)}
                <p className="m-0 p-0">
                  <b>Street Name:</b> {data.street.name}
                </p>
                <p className="m-0 p-0">
                  <b>Street Segment:</b> {data.street.segment}
                </p>
                <p className="m-0 p-0">
                  <b>Postal Code:</b> {data.postalCode.code}
                </p>
                <p className="m-0 p-0">
                  <b>City:</b> {data.city.name}
                </p>
                <p className="m-0 p-0">
                  <b>State:</b> {data.stateOrProvince.name}
                </p>
                <p className="m-0 p-0">
                  <b>Country:</b> {data?.country?.name}
                </p>
                <p>
                  <Button
                    type="text"
                    className="justify-center"
                    onClick={handleContactRemoved(i)}
                    icon={<DeleteOutlined />}
                    danger
                  />
                </p>
              </Collapse.Panel>
            );
          }
          case ContactMediumType.NAMED_TELEPHONE:
          case ContactMediumType.TELEPHONE: {
            const data = contact as TelephoneContact;
            headerText = getNameIfExist(data) + data.number;
            return (
              <Collapse.Panel
                header={headerText}
                key={key}
                className="contacts"
              >
                {getNameParagraph(data)}
                <p className="m-0 p-0">
                  <b>Number:</b> {data.number}
                </p>
                <p className="m-0 p-0">
                  <b>Number type:</b> {data.numberType}
                </p>
                <p>
                  <Button
                    type="text"
                    className="justify-center"
                    onClick={handleContactRemoved(i)}
                    icon={<DeleteOutlined />}
                    danger
                  />
                </p>
              </Collapse.Panel>
            );
          }
          case ContactMediumType.BANK: {
            const data = contact as BankContact;
            headerText = getNameIfExist(data) + data.bic;
            return (
              <Collapse.Panel
                header={headerText}
                key={key}
                className="contacts"
              >
                {getNameParagraph(data)}
                <p className="m-0 p-0">
                  <b>BIC:</b> {data.bic}
                </p>
                <p className="m-0 p-0">
                  <b>IBAN:</b> {data.iban}
                </p>
                <p className="m-0 p-0">
                  <b>Bankgiro:</b> {data.bankGiro}
                </p>
                <p>
                  <Button
                    type="text"
                    className="justify-center"
                    onClick={handleContactRemoved(i)}
                    icon={<DeleteOutlined />}
                    danger
                  />
                </p>
              </Collapse.Panel>
            );
          }
          case ContactMediumType.WEB: {
            const data = contact as WebContact;
            headerText = getNameIfExist(data) + data.address;
            return (
              <Collapse.Panel
                header={headerText}
                key={key}
                className="contacts"
              >
                {getNameParagraph(data)}
                <p className="m-0 p-0">
                  <b>Web Address:</b> {data.address}
                </p>
                <p>
                  <Button
                    type="text"
                    className="justify-center"
                    onClick={handleContactRemoved(i)}
                    icon={<DeleteOutlined />}
                    danger
                  />
                </p>
              </Collapse.Panel>
            );
          }
        }
      })}
    </Collapse>
  );
};
export default ContactMediumsAccordian;
