import axios from 'axios';
import { LoanEvent, Setting, UnidentifiedLoanEvent } from 'api/types';

import { ENDPOINT_BASE } from 'constants/index';

const LOAN_EVENT_PATH = 'loan-event';
const baseUrlLoanEvents = `${ENDPOINT_BASE}${LOAN_EVENT_PATH}`;

const client = axios.create({
  withCredentials: true,
  baseURL: baseUrlLoanEvents,
});

/********************************************************************
 *  Loan Events
 *******************************************************************/
export async function createLoanEvent(
  request: UnidentifiedLoanEvent
): Promise<LoanEvent> {
  const response = await client.post<LoanEvent>('', request);
  return response.data;
}

export async function getLoanEvents(id: number): Promise<LoanEvent[]> {
  const response = await client.get<LoanEvent[]>(`/${id}`);
  return response.data;
}
