import { Card, Form, FormInstance, Input } from 'antd';
import {
  ContactMedium,
  ContactMediumType,
  Loan,
  LoanDetails,
  LoanTerm,
  PostalContact,
} from 'api/types';
import React, { ReactElement, useEffect, useState } from 'react';
import RoleInput from 'components/forms/RoleInput';
import {
  createCreateRoleRequest,
  CreateRoleRequests,
  LoanRoleType,
} from 'components/forms/RoleInput/RoleInput';

type LoanDetailsProps = {
  form: FormInstance;
  loan: Loan | undefined;
  loanDetails: LoanDetails | undefined;
  submitLoan: (loan: Loan) => void;
};

function getPluralSuffix(count: number, text: string): string {
  if (count == 1) {
    return count + ' ' + text;
  } else if (count == 0) {
    return '';
  } else {
    return count + ' ' + text + 's';
  }
}

function getTerm(loanTerm: LoanTerm | undefined): string {
  if (loanTerm) {
    return (
      getPluralSuffix(loanTerm.years, 'Year') +
      ' ' +
      getPluralSuffix(loanTerm.months, 'Month') +
      ' ' +
      getPluralSuffix(loanTerm.days, 'Day')
    );
  }
  return 'unknown';
}

function getBillingAddress(loan: Loan | undefined): ReactElement {
  if (loan) {
    const contactMedium: ContactMedium | undefined =
      loan.borrower?.contactMediums.find((contact) => {
        return (
          contact.type == ContactMediumType.POSTAL ||
          contact.type == ContactMediumType.NAMED_POSTAL
        );
      });
    if (contactMedium) {
      const postalContact = contactMedium as PostalContact;
      return (
        <>
          {loan?.borrower?.party.name.name}
          <br />
          {postalContact.street.name}
          <br />
          {postalContact.postalCode.code + ' ' + postalContact.city.name}
          <br />
          {postalContact.country.name}
          <br />
        </>
      );
    }
  }
  return <>'unknown'</>;
}

const readonlyStyle = { backgroundColor: 'rgb(240,240,240)' };

const LoanDetailGeneral: React.FC<LoanDetailsProps> = ({
  form,
  loan,
  loanDetails,
  submitLoan,
}) => {
  const [createRoleRequests, setCreateRoleRequests] =
    useState<CreateRoleRequests>(createCreateRoleRequest(loan));

  useEffect(() => {
    if (loan) {
      const updatedLoan: Loan = {
        ...loan,
        borrower: createRoleRequests.borrower,
        broker: createRoleRequests.broker,
        customerManager: createRoleRequests.customerManager,
        customerSupport: createRoleRequests.customerSupport,
        developer: createRoleRequests.developer,
      };
      submitLoan(updatedLoan);
    }
  }, [createRoleRequests, loan, submitLoan]);

  return (
    <>
      <Form.Item
        name={['loan', 'name']}
        label="Loan Name"
        initialValue={loan?.name}
      >
        <Input readOnly style={readonlyStyle} />
      </Form.Item>
      <Form.Item label="Loan Number">
        <Input readOnly style={readonlyStyle} value={loan?.id} />
      </Form.Item>

      <Form.Item label="Borrower organisation id">
        <Input
          readOnly
          style={readonlyStyle}
          value={loan?.borrower?.party.identificationNumber}
        />
      </Form.Item>
      <Form.Item label="Borrower name">
        <Input
          readOnly
          style={readonlyStyle}
          value={loan?.borrower?.party.name.name}
        />
      </Form.Item>
      <Form.Item
        name={['loan', 'developer', 'party', 'identificationNumber']}
        label="Developer organisation id"
        initialValue={loan?.developer?.party.identificationNumber}
      >
        <Input readOnly style={readonlyStyle} />
      </Form.Item>
      <Form.Item
        name={['loan', 'developer', 'party', 'name', 'name']}
        label="Developer name"
        initialValue={loan?.developer?.party.name.name}
      >
        <Input readOnly style={readonlyStyle} />
      </Form.Item>
      <Form.Item label="Active">
        <Input
          readOnly
          style={readonlyStyle}
          value={String(loanDetails?.active)}
        />
      </Form.Item>
      <Form.Item label="Ongoing">
        <Input
          readOnly
          style={readonlyStyle}
          value={String(loanDetails?.ongoing)}
        />
      </Form.Item>
      <Form.Item label="Sign Date">
        <Input readOnly style={readonlyStyle} value={loan?.signDate} />
      </Form.Item>
      <Form.Item label="Start Date">
        <Input readOnly style={readonlyStyle} value={loan?.startDate} />
      </Form.Item>
      <Form.Item label="End Date">
        <Input readOnly style={readonlyStyle} value={loan?.endDate} />
      </Form.Item>
      <Form.Item label="Current Loan term">
        <Input
          readOnly
          style={readonlyStyle}
          value={getTerm(loanDetails?.currentLoanTerm)}
        />
      </Form.Item>
      <Form.Item label="Original Loan term">
        <Input
          readOnly
          style={readonlyStyle}
          value={getTerm(loanDetails?.originalLoanTerm)}
        />
      </Form.Item>
      <Form.Item label="Remaining Loan term">
        <Input
          readOnly
          style={readonlyStyle}
          value={getTerm(loanDetails?.remainingLoanTerm)}
        />
      </Form.Item>

      <RoleInput
        form={form}
        label="Customer Manager"
        loanRoleType={LoanRoleType.CUSTOMER_MANAGER}
        required={false}
        setCreateRoleRequests={setCreateRoleRequests}
        initialData={createRoleRequests}
      />
      <RoleInput
        form={form}
        label="Customer Support"
        loanRoleType={LoanRoleType.CUSTOMER_SUPPORT}
        required={false}
        setCreateRoleRequests={setCreateRoleRequests}
        initialData={createRoleRequests}
      />
      <Form.Item label="Billing Address">
        <Card
          style={readonlyStyle}
          bodyStyle={{
            paddingLeft: '10px',
            paddingTop: '5px',
            paddingBottom: '5px',
          }}
        >
          {getBillingAddress(loan)}
        </Card>
      </Form.Item>
      <Form.Item
        name={['loan', 'borrower', 'bankAccountNumber']}
        label="Bank Account"
        initialValue={loan?.borrower?.bankAccountNumber}
      >
        <Input readOnly style={readonlyStyle} />
      </Form.Item>
    </>
  );
};

export default LoanDetailGeneral;
