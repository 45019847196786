import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { SiMicrosoftexcel } from 'react-icons/si';
import { IconContext } from 'react-icons';
import {
  Button,
  DatePicker,
  Input,
  InputRef,
  Modal,
  Popconfirm,
  Radio,
  RadioChangeEvent,
  Select,
  Space,
  Table,
} from 'antd';
import { Explicit } from '@mui/icons-material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { SearchOutlined } from '@ant-design/icons';
import type { ColumnType } from 'antd/lib/table';
import { useQueryClient } from '@tanstack/react-query';
import type { FilterConfirmProps, FilterValue } from 'antd/lib/table/interface';

import { useLoansQuery } from 'hooks/withLoan';

import LoanEventForm from 'components/forms/LoanEventForm';
import {
  CellData,
  InternalRisk,
  Loan,
  LoanReport,
  Risk,
  UnidentifiedLoanEvent,
} from 'api/types';
import { useCreateLoanEvent } from 'hooks/withLoanEvent';
import useSorter from 'hooks/useSorter';
import usePagination from 'hooks/usePagination';

import { convertToQuery } from 'utils/index';

import ListLoansExpanded from '../ListLoansExpanded';
import InvoiceForm from 'components/forms/InvoiceForm';
import { useCreateInvoice } from 'hooks/withInvoice';
import { useCreateLoanExcelDocument } from 'hooks/withGenerateDocument';
import LoanDetails from 'views/Loans/LoanDetails/LoanDetails';
import { getLoansPaginated } from 'api/loanClient';
import { Dayjs } from 'dayjs';
import { getDateStringInLocalTime } from 'utils/date';
import { createTagFromInternalRisk } from 'utils/risk';

const { RangePicker } = DatePicker;

const stringFilteredKeys = new Set([
  'borrower.name',
  'developer.name',
  'broker.name',
  'customerManager.name',
  'customerSupport.name',
]);

const quotedKeys = new Set(['startDate', 'endDate', 'active', 'ongoing']);

const enumKeys = new Map<string, typeof Risk | typeof InternalRisk>([
  ['borrower.risk', Risk],
  ['risk', InternalRisk],
]);

function convertColumnsToKeyValue(columns: ColumnType<LoanReport>[]): {
  value: string;
  label: string;
}[] {
  return columns
    .filter((column) => column.title && column.key)
    .map((column) => ({
      value: column!.key?.toString() ?? '',
      label: column.title?.toString() ?? '',
    }));
}

function mergeAndRemoveDuplicates(
  arrayOne: string[],
  arrayTwo: string[]
): string[] {
  const merged = [...arrayOne, ...arrayTwo];

  return merged.filter((item, index) => merged.indexOf(item) === index);
}

const ListLoansView: React.FC = () => {
  const queryClient = useQueryClient();

  const [activityFilter, setActivityFilter] = useState(0);

  const activityFilters = [
    { active: ['true'], ongoing: ['false'] },
    { active: ['true'], ongoing: ['true'] },
    { active: ['false'], ongoing: ['false'] },
  ];

  const [filters, setFilters] = useState<Record<string, FilterValue | null>>({
    ...activityFilters[activityFilter],
  });

  const [createLoanEvent, isCreateLoanEventLoading] = useCreateLoanEvent();

  const [createInvoice, isCreateInvoiceLoading] = useCreateInvoice();

  const [createExcelDocument, isCreateExcelDocumentLoading] =
    useCreateLoanExcelDocument();

  const { sort, handleSort } = useSorter<LoanReport>();
  const { page, size, handleChangePage } = usePagination();

  const [dateRanges, setDateRanges] = useState<
    [start: Dayjs | null | undefined, end: Dayjs | null | undefined]
  >([null, null]);

  const [loansResult, isLoansLoading, isError] = useLoansQuery(
    {
      page: page - 1,
      sort,
      size,
    },
    convertToQuery(
      filters,
      stringFilteredKeys,
      quotedKeys,
      enumKeys,
      dateRanges
    )
  );

  const confirmCreateExcel = () => {
    createExcelDocument({
      sort: sort,
      query: convertToQuery(
        filters,
        stringFilteredKeys,
        quotedKeys,
        enumKeys,
        dateRanges
      ),
      cellData: getCellData,
    });
  };

  const [selectedLoan, setSelectedLoan] = useState<Loan>();

  /*
   * Loan event
   * */
  const [isLoanEventModalOpened, setIsLoanEventModalOpened] =
    useState<boolean>(false);

  const onDisplayLoanEvent = useCallback((loan: Loan) => {
    setSelectedLoan(loan);
    setIsLoanEventModalOpened(true);
  }, []);

  const onSubmitLoanEvent = useCallback(
    (event: UnidentifiedLoanEvent) => {
      const unidentifiedLoanEvent = event as UnidentifiedLoanEvent;
      createLoanEvent(unidentifiedLoanEvent);
      setIsLoanEventModalOpened(false);
    },
    [createLoanEvent]
  );

  const onCancelLoanEvent = useCallback(() => {
    setIsLoanEventModalOpened(false);
  }, []);

  /*
   * Create Invoice
   * */
  const [isCreateInvoiceModalOpened, setIsCreateInvoiceModalOpened] =
    useState<boolean>(false);

  const [createInvoiceSubmitted, setCreateInvoiceSubmitted] = useState(false);

  const onDisplayCreateInvoice = useCallback((loan: Loan) => {
    setSelectedLoan(loan);
    setIsCreateInvoiceModalOpened(true);
  }, []);

  useEffect(() => {
    if (createInvoiceSubmitted && isCreateInvoiceLoading != 'pending') {
      setIsCreateInvoiceModalOpened(false);
      setCreateInvoiceSubmitted(false);
    }
  }, [createInvoiceSubmitted, isCreateInvoiceLoading]);

  const onSubmitCreateInvoice = useCallback(
    (loanId: number, from: string, to: string) => {
      createInvoice({ loanId, from, to });
      setCreateInvoiceSubmitted(true);
    },
    [createInvoice]
  );

  const onCancelCreateInvoiceEvent = useCallback(() => {
    setIsCreateInvoiceModalOpened(false);
  }, []);

  /*
   * Loan Details
   * */
  const [isLoanDetailsModalOpened, setIsLoanDetailsOpened] =
    useState<boolean>(false);

  const onCloseLoanDetails = useCallback(() => {
    setIsLoanDetailsOpened(false);
  }, []);

  const [onSubmitLoanTrigger, setOnSubmitLoanTrigger] = useState(false);
  const [updateLoanStatus, setUpdateLoanStatus] = useState<
    'error' | 'idle' | 'pending' | 'success'
  >('idle');

  useEffect(() => {
    if (onSubmitLoanTrigger && updateLoanStatus == 'success') {
      queryClient.resetQueries({
        queryKey: [
          getLoansPaginated.name,
          JSON.stringify(
            convertToQuery(
              filters,
              stringFilteredKeys,
              quotedKeys,
              enumKeys,
              dateRanges
            )
          ),
          JSON.stringify({
            page: page - 1,
            sort,
            size,
          }),
        ],
      });
      setOnSubmitLoanTrigger(false);
    }
  }, [
    filters,
    page,
    queryClient,
    size,
    sort,
    onSubmitLoanTrigger,
    updateLoanStatus,
    dateRanges,
  ]);

  const onSubmitLoan = useCallback(() => {
    setOnSubmitLoanTrigger(true);
    setIsLoanDetailsOpened(false);
  }, []);

  const onDisplayLoanDetails = useCallback((loan: Loan) => {
    setSelectedLoan(loan);
    setIsLoanDetailsOpened(true);
  }, []);

  const formatCurrencySwedish = useCallback(
    (value: number | bigint | undefined, loan: Loan) => {
      return new Intl.NumberFormat('sv-SE', {
        style: 'currency',
        currency: loan?.currency ? loan.currency : 'SEK',
      })
        .format(value ? value : 0)
        .replace('kr', '');
    },
    []
  );

  const columns = useMemo(
    () =>
      getColumns({
        onDisplayLoanEvent,
        onDisplayCreateInvoice,
        onDisplayLoanDetails,
        formatCurrencySwedish,
      }),
    [
      formatCurrencySwedish,
      onDisplayCreateInvoice,
      onDisplayLoanDetails,
      onDisplayLoanEvent,
    ]
  );

  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void
  ) => {
    confirm();
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
  };

  const getColumnSearchProps = useCallback(
    (dataIndex: string[], key: string): ColumnType<LoanReport> => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        if (!dataIndex) {
          return null;
        }

        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={searchInput}
              placeholder={`Search ${key}`}
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() =>
                handleSearch(selectedKeys as string[], confirm)
              }
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys as string[], confirm)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button
                onClick={() => clearFilters && handleReset(clearFilters)}
                size="small"
                style={{ width: 90 }}
              >
                Reset
              </Button>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({ closeDropdown: false });
                }}
              >
                Filter
              </Button>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({ closeDropdown: true });
                }}
              >
                Close
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered: boolean) =>
        dataIndex ? (
          <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
        ) : null,
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
    }),
    []
  );

  const columnsExtended = useMemo(
    () =>
      columns.map((column) => {
        if (!column.sorter) {
          return column;
        }

        return {
          ...getColumnSearchProps(column.dataIndex, column.key),
          ...column,
        };
      }),
    [columns, getColumnSearchProps]
  );

  const keyLabelsColumn = convertColumnsToKeyValue(columnsExtended);

  const [visibleColumns, setVisibleColumns] = useState<string[]>(
    columns
      .filter((column) => column.key)
      .filter((column) => column.default)
      .map((column) => column.key)
  );

  useEffect(() => {
    if (dateRanges[0] && dateRanges[1]) {
      const calculatingColumns = columns
        .filter((column) => column.calculationColumn)
        .map((column) => column.key);

      setVisibleColumns((prevState) =>
        mergeAndRemoveDuplicates(prevState, calculatingColumns)
      );
    }
  }, [columns, dateRanges]);

  const filteredColumns = useMemo(
    () =>
      columnsExtended.filter(
        (col) => visibleColumns.includes(col.key) || col.key == ''
      ),
    [visibleColumns, columnsExtended]
  );

  const getCellData: CellData[] = useMemo(() => {
    return filteredColumns
      .filter((column) => column.title)
      .filter((column) => column.dataIndex)
      .filter((column) => column.dataIndex.length > 0)
      .map((column) => {
        return { heading: column.title, dataIndex: column.dataIndex };
      });
  }, [filteredColumns]);

  return (
    <div className="mt-10 overflow-y-auto">
      <div className="flex">
        <h1 className="mb-0 py-5">List loans</h1>
        <Popconfirm
          title="Generate Excel Report"
          description="Confirm generate Excel report"
          onConfirm={confirmCreateExcel}
          okText="Yes"
          cancelText="No"
        >
          <Button
            type="link"
            title="Generate Excel report"
            loading={isCreateExcelDocumentLoading == 'pending'}
          >
            <IconContext.Provider value={{ color: 'green' }}>
              <SiMicrosoftexcel size={30} />
            </IconContext.Provider>
          </Button>
        </Popconfirm>
      </div>
      <div>
        <Radio.Group
          onChange={(e: RadioChangeEvent) => {
            setFilters((prev) => {
              return { ...prev, ...{ ...activityFilters[e.target.value] } };
            });
            setActivityFilter(e.target.value);
          }}
          value={activityFilter}
        >
          <Space direction="vertical">
            <Radio value={0}>Active Only</Radio>
            <Radio value={1}>Active & Ongoing</Radio>
            <Radio value={2}>Not Active & Not Ongoing</Radio>
          </Space>
        </Radio.Group>
      </div>
      <div className="flex flex-col mt-5">
        <div className="mb-3 w-full flex">
          <Select
            options={keyLabelsColumn}
            onChange={(keys: string[]) => {
              setVisibleColumns([...keys]);
            }}
            value={visibleColumns}
            allowClear={false}
            showSearch
            mode="multiple"
            placeholder="Choose columns to show"
            style={{ width: '100%' }}
          />
        </div>

        <Space direction="horizontal">
          <RangePicker
            value={dateRanges}
            allowEmpty={[true, true]}
            onCalendarChange={(newDates) => {
              setDateRanges(newDates);
            }}
          />
        </Space>

        <Table
          sortDirections={['ascend', 'descend']}
          dataSource={isError ? [] : loansResult.content}
          columns={filteredColumns}
          loading={isLoansLoading}
          rowKey={(loan) => (loan.id ? loan.id?.toString() : '')}
          size="small"
          onChange={(pagination, newFilters, s, extra) => {
            if (extra.action === 'sort') {
              handleSort(s);
            } else if (extra.action === 'filter') {
              setFilters(newFilters);
            }
          }}
          expandable={{
            expandedRowRender: (loan) => <ListLoansExpanded loan={loan} />,
          }}
          pagination={{
            total: loansResult.totalElements,
            onChange: handleChangePage,
            pageSize: size,
            current: page,
          }}
        />
        <Modal
          open={isLoanEventModalOpened}
          onCancel={onCancelLoanEvent}
          width={800}
          footer={null}
          destroyOnClose
          title="Create Loan Event"
        >
          <LoanEventForm
            isLoading={isCreateLoanEventLoading === 'pending'}
            loan={selectedLoan}
            onSubmit={onSubmitLoanEvent}
          />
        </Modal>
        <Modal
          open={isCreateInvoiceModalOpened}
          onCancel={onCancelCreateInvoiceEvent}
          width={800}
          footer={null}
          destroyOnClose
          title="Create Loan Event"
        >
          <InvoiceForm
            isLoading={isCreateInvoiceLoading === 'pending'}
            loan={selectedLoan}
            onSubmit={onSubmitCreateInvoice}
          />
        </Modal>

        <Modal
          open={isLoanDetailsModalOpened}
          onCancel={onCloseLoanDetails}
          width={800}
          footer={null}
          destroyOnClose
          title="Loan Details"
        >
          <LoanDetails
            loan={selectedLoan}
            onSubmit={onSubmitLoan}
            onCancel={onCloseLoanDetails}
            setUpdateLoanStatus={setUpdateLoanStatus}
          />
        </Modal>
      </div>
    </div>
  );
};

export default ListLoansView;

const getColumns = ({
  onDisplayLoanEvent,
  onDisplayCreateInvoice,
  onDisplayLoanDetails,
  formatCurrencySwedish,
}: {
  onDisplayLoanEvent: (loan: Loan) => void;
  onDisplayCreateInvoice: (loan: Loan) => void;
  onDisplayLoanDetails: (loan: Loan) => void;
  formatCurrencySwedish: (value: number | bigint, loan: Loan) => string;
}) =>
  [
    {
      title: 'Id',
      dataIndex: ['id'],
      key: 'id',
      default: true,
    },
    {
      title: 'Internal Risk',
      dataIndex: ['risk'],
      key: 'risk',
      default: true,
      render: (val) => createTagFromInternalRisk(val),
    },
    {
      title: 'Borrower Risk',
      dataIndex: ['borrower', 'risk'],
      key: 'borrower.risk',
      default: false,
      render: (val) => Risk[val],
    },
    {
      title: 'Borrower Name',
      dataIndex: ['borrower', 'party', 'name', 'name'],
      key: 'borrower.name',
      default: true,
    },
    {
      title: 'Borrower Identification Number',
      dataIndex: ['borrower', 'party', 'identificationNumber'],
      key: 'borrower.party.identificationNumber',
      sorter: false,
    },
    {
      title: 'Borrower State',
      dataIndex: ['borrower', 'party', 'state'],
      key: 'borrower.party.state',
      sorter: false,
    },
    {
      title: 'Borrower Created At',
      dataIndex: ['borrower', 'party', 'created'],
      key: 'borrower.party.created',
      render: (val) => getDateStringInLocalTime(val),
      sorter: false,
    },
    {
      title: 'Developer Name',
      dataIndex: ['developer', 'party', 'name', 'name'],
      key: 'developer.party.name.name',
      sorter: false,
      default: true,
    },
    {
      title: 'Capitalised Debt',
      dataIndex: ['capitalisedDebt'], //Need dataIndex for excel exporter
      key: 'capitalisedDebt',
      render: (currentLoan) =>
        formatCurrencySwedish(currentLoan.capitalisedDebt, currentLoan),
      default: true,
    },
    {
      title: 'Syndicated',
      dataIndex: ['syndicated'],
      key: 'syndicated',
      default: true,
    },
    {
      title: 'Broker',
      dataIndex: ['broker', 'party', 'name', 'name'],
      key: 'broker.name',
      default: true,
    },
    {
      title: 'Limit',
      dataIndex: ['limit'], //Need dataIndex for excel exporter
      key: 'limit',
      render: (currentLoan) =>
        formatCurrencySwedish(currentLoan.limit, currentLoan),
      default: true,
    },
    {
      title: 'Start Date',
      dataIndex: ['startDate'],
      key: 'startDate',
      default: true,
    },
    {
      title: 'Extended',
      dataIndex: ['extensionFee'],
      key: 'extensionFee',
      sorter: false,
      render: (extensionFee) => Boolean(extensionFee > 0).toString(),
      default: true,
    },
    {
      title: 'End Date',
      dataIndex: ['endDate'],
      key: 'endDate',
      default: true,
    },
    {
      title: 'Registration Date',
      dataIndex: ['registrationDate'],
      key: 'registrationDate',
      render: (val) => getDateStringInLocalTime(val),
    },
    {
      title: 'Sign Date',
      dataIndex: ['signDate'],
      key: 'signDate',
      render: (val) => getDateStringInLocalTime(val),
    },
    {
      title: 'Setup Fee',
      dataIndex: ['setupFee'], //Need dataIndex for excel exporter
      key: 'setupFee',
      render: (currentLoan) =>
        formatCurrencySwedish(currentLoan.setupFee, currentLoan),
      default: true,
    },
    {
      title: 'Interest',
      dataIndex: ['interest', 'interest'],
      key: 'interest',
      default: true,
    },
    {
      title: 'Customer Manager Id',
      dataIndex: ['borrower', 'customerManagerId'],
      key: 'borrower.customerManagerId',
      sorter: false,
    },
    {
      title: 'Borrower Bank Account Number',
      dataIndex: ['borrower', 'bankAccountNumber'],
      key: 'borrower.bankAccountNumber',
      sorter: false,
    },
    {
      title: 'Borrower Organisation Type',
      dataIndex: ['borrower', 'party', 'organisationType'],
      key: 'borrower.party.organisationType',
      sorter: false,
    },
    {
      title: 'Customer Manager Name',
      dataIndex: ['customerManager', 'party', 'name', 'name'],
      key: 'customerManager.party.name.name',
      sorter: false,
      default: true,
    },
    {
      title: 'Customer Manager State',
      dataIndex: ['customerManager', 'party', 'state'],
      key: 'customerManager.party.state',
      sorter: false,
    },
    {
      title: 'Customer Manager Created At',
      dataIndex: ['customerManager', 'party', 'created'],
      key: 'customerManager.party.created',
      render: (val) => getDateStringInLocalTime(val),
      sorter: false,
    },
    {
      title: 'Customer Support Name',
      dataIndex: ['customerSupport', 'party', 'name', 'name'],
      key: 'customerSupport.party.name.name',
      sorter: false,
      default: true,
    },
    {
      title: 'Customer Support Identification Number',
      dataIndex: ['customerSupport', 'party', 'identificationNumber'],
      key: 'customerSupport.party.identificationNumber',
      sorter: false,
    },
    {
      title: 'Customer Support State',
      dataIndex: ['customerSupport', 'party', 'state'],
      key: 'customerSupport.party.state',
      sorter: false,
    },
    {
      title: 'Customer Support Created At',
      dataIndex: ['customerSupport', 'party', 'created'],
      key: 'customerSupport.party.created',
      render: (val) => getDateStringInLocalTime(val),
      sorter: false,
    },
    {
      title: 'Developer Identification Number',
      dataIndex: ['developer', 'party', 'identificationNumber'],
      key: 'developer.party.identificationNumber',
      sorter: false,
    },
    {
      title: 'Developer State',
      dataIndex: ['developer', 'party', 'state'],
      key: 'developer.party.state',
      sorter: false,
    },
    {
      title: 'Developer Created At',
      dataIndex: ['developer', 'party', 'created'],
      key: 'developer.party.created',
      render: (val) => getDateStringInLocalTime(val),
      sorter: false,
    },
    {
      title: 'Lender Name',
      dataIndex: ['lender', 'party', 'name', 'name'],
      key: 'lender.party.name.name',
      sorter: false,
    },
    {
      title: 'Lender Identification Number',
      dataIndex: ['lender', 'party', 'identificationNumber'],
      key: 'lender.party.identificationNumber',
      sorter: false,
    },
    {
      title: 'Lender State',
      dataIndex: ['lender', 'party', 'state'],
      key: 'lender.party.state',
      sorter: false,
    },
    {
      title: 'Lender Created At',
      dataIndex: ['lender', 'party', 'created'],
      key: 'lender.party.created',
      render: (val) => getDateStringInLocalTime(val),
      sorter: false,
    },
    {
      title: 'Broker Type',
      dataIndex: ['broker', 'type'],
      key: 'broker.type',
      sorter: false,
    },
    {
      title: 'Type',
      dataIndex: ['type'],
      key: 'type',
    },
    {
      title: 'Currency',
      dataIndex: ['currency'],
      key: 'currency',
    },
    {
      title: 'Defaulting Interest',
      dataIndex: ['defaultingInterest'],
      key: 'defaultingInterest',
      sorter: false,
    },
    {
      title: 'Active',
      dataIndex: ['active'],
      key: 'active',
      render: (val) => val.toString(),
    },
    {
      title: 'Ongoing',
      dataIndex: ['ongoing'],
      key: 'ongoing',
      render: (val) => val.toString(),
    },
    {
      title: 'Days',
      dataIndex: ['days'],
      key: 'days',
      sorter: false,
      calculationColumn: true,
    },
    {
      title: 'Capitalised Debt Start',
      dataIndex: ['capitalisedDebtAtStart'], //Need dataIndex for excel exporter
      key: 'capitalisedDebtAtStart',
      render: (currentLoan) =>
        formatCurrencySwedish(currentLoan.capitalisedDebtAtStart, currentLoan),
      sorter: false,
      calculationColumn: true,
    },
    {
      title: 'Capitalised Debt End',
      dataIndex: ['capitalisedDebtAtEnd'], //Need dataIndex for excel exporter
      key: 'capitalisedDebtAtEnd',
      render: (currentLoan) =>
        formatCurrencySwedish(currentLoan.capitalisedDebtAtEnd, currentLoan),
      sorter: false,
      calculationColumn: true,
    },
    {
      title: 'Accrued Interest',
      dataIndex: ['accruedInterest'], //Need dataIndex for excel exporter
      key: 'accruedInterest',
      render: (currentLoan) =>
        formatCurrencySwedish(currentLoan.accruedInterest, currentLoan),
      sorter: false,
      calculationColumn: true,
    },
    {
      title: 'Payout',
      dataIndex: ['payout'], //Need dataIndex for excel exporter
      key: 'payout',
      render: (currentLoan) =>
        formatCurrencySwedish(currentLoan.payout, currentLoan),
      sorter: false,
      calculationColumn: true,
    },
    {
      title: 'Payment',
      dataIndex: ['payment'], //Need dataIndex for excel exporter
      key: 'payment',
      render: (currentLoan) =>
        formatCurrencySwedish(currentLoan.payment, currentLoan),
      sorter: false,
      calculationColumn: true,
    },
    {
      title: 'Building Completed',
      dataIndex: ['building', 'completed'],
      key: 'building.completed',
      render: (val) => val.toString(),
      sorter: false,
    },
    {
      title: 'Building Renovation',
      dataIndex: ['building', 'renovation'],
      key: 'building.renovation',
      render: (val) => val.toString(),
      sorter: false,
    },
    {
      title: 'Building Number Apartments',
      dataIndex: ['building', 'numberOfApartments'],
      key: 'building.numberOfApartments',
      sorter: false,
    },
    {
      title: 'Building Seniority',
      dataIndex: ['building', 'seniority'],
      key: 'building.seniority',
      sorter: false,
    },
    {
      title: 'Building Environment Classifications',
      dataIndex: ['building', 'environmentalClassifications'],
      key: 'building.environmentalClassifications',
      render: (val) => val.join(','),
      sorter: false,
    },
    {
      title: 'Building Social Aspects',
      dataIndex: ['building', 'socialAspects'],
      key: 'building.socialAspects',
      render: (val) => val.join(','),
      sorter: false,
    },
    {
      title: 'Building Foundation Material',
      dataIndex: ['building', 'foundationMaterial'],
      key: 'building.foundationMaterial',
      sorter: false,
    },
    {
      title: 'Building Energy Class',
      dataIndex: ['building', 'energyClass'],
      key: 'building.energyClass',
      sorter: false,
    },
    {
      title: '',
      key: '',
      dataIndex: [],
      sorter: false,
      render: (currentLoan) => (
        <div className="flex flex-row">
          <Button
            type="link"
            onClick={() => onDisplayLoanDetails(currentLoan)}
            title="Loan Details"
          >
            <MoreVertIcon />
          </Button>
        </div>
      ),
    },
    {
      title: '',
      key: '',
      dataIndex: [],
      sorter: false,
      render: (currentLoan) => (
        <div className="flex flex-row">
          <Button
            type="link"
            onClick={() => onDisplayLoanEvent(currentLoan)}
            title="Send loan event"
          >
            <Explicit />
          </Button>
        </div>
      ),
    },
    {
      title: '',
      key: '',
      dataIndex: [],
      sorter: false,
      render: (currentLoan) => (
        <div className="flex flex-row">
          <Button
            type="link"
            onClick={() => onDisplayCreateInvoice(currentLoan)}
            title="Generate Invoice"
          >
            <PictureAsPdfIcon />
          </Button>
        </div>
      ),
    },
  ].map((v) => ({ sorter: true, ...v }));
