import React, { useCallback, useState } from 'react';
import { useGetSettings, useGetSettingsByName } from 'hooks/withSetting';
import { Col, Row, Select, Table } from 'antd';
import { Setting, SettingDescriptor } from 'api/types';
import { BarChart, CartesianGrid, XAxis, Label, YAxis, Bar } from 'recharts';
import { REFERENCE_INTEREST, SETTING_DESCRIPTORS } from 'constants/index';

const ReferenceInterestView: React.FC = () => {
  const [selectedSettings, setSelectedSettings] = useState<Setting[]>([]);
  const [settings, isSettingsLoading] = useGetSettingsByName(
    REFERENCE_INTEREST.name
  );
  //const [selectedDescriptor, setSelectedDescriptor] = useState<SettingDescriptor>(REFERENCE_INTEREST);

  const labelFormatter = (value) => {
    return value;
  };

  return (
    <div className="mt-10">
      <h1 className="mb-0 py-5">Reference Interest</h1>
      <div className="flex flex-col mt-5">
        <BarChart width={1000} height={400} data={settings}>
          <CartesianGrid strokeDasharray="1 1" />
          <XAxis
            dataKey="created"
            tickFormatter={(val) => (val as string).substring(0, 10)}
          />
          <YAxis
            allowDataOverflow={false}
            tick={{ width: 75 }}
            tickFormatter={(val) => val.toLocaleString().replace(/,/g, ' ')}
          />
          <Bar
            barSize={100}
            maxBarSize={100}
            background={false}
            activeBar={{ stroke: 'white', strokeWidth: 2 }}
            isAnimationActive={false}
            type="number"
            dataKey="value"
            fill="#448844"
            onMouseEnter={(s) => {}}
            label={{
              position: 'insideTop',
              formatter: labelFormatter,
              fill: 'dark-grey',
            }}
          ></Bar>
        </BarChart>
      </div>
    </div>
  );
};
export default ReferenceInterestView;

/*
  const labelFormatterXAxiTicks = (value) => {
    return (value as string).substring(0, 4);
  };
  <Select
      options={SETTING_DESCRIPTORS.map((s) => ({
        value: s.name,
        label: `${s.name}`,
      }))}
      style={{
        width: 300,
      }}
      filterOption={filterOption}
      optionFilterProp="children"
      loading={isSettingsLoading}
      showSearch
    />

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  const onSelect = useCallback(
    (settingName) => {
      const settingDesciptor: SettingDescriptor | undefined =
        SETTING_DESCRIPTORS.filter((sd) => sd.name === settingName).at(0);
      if (settingDesciptor !== undefined) {
        setSelectedDescriptor(settingDesciptor);
      }
      setSelectedSettings(
        settings.filter((setting) => {
          return setting.name === settingName;
        })
      );
      console.log(JSON.stringify(selectedSettings));
    },
    [settings, selectedSettings]
  );
  */
