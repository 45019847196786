import { useQuery } from '@tanstack/react-query';

import { getLenders } from 'api/lenderClient';

export const useGetLenders = () => {
  return useQuery({
    queryKey: [getLenders.name],
    queryFn: getLenders,
  });
};
