import React from 'react';
import { NumericFormat } from 'react-number-format';

export type NumberFormatConfig = {
  thousandSeparator?: string | undefined;
  decimalScale?: number | undefined;
  decimalSeparator?: string | undefined;
  prefix?: string | undefined;
  suffix?: string | undefined;
};

type NumberFormatProps = {
  inputValue: string | number | null | undefined;
  config?: NumberFormatConfig | undefined;
};

const NumberFormat: React.FC<NumberFormatProps> = ({ inputValue, config }) => {
  return (
    <NumericFormat
      value={inputValue}
      thousandSeparator={
        config?.thousandSeparator ? config.thousandSeparator : ' '
      }
      decimalScale={config?.decimalScale ? config.decimalScale : 2}
      decimalSeparator={
        config?.decimalSeparator ? config.decimalSeparator : ','
      }
      prefix={config?.prefix ? config.prefix : undefined}
      suffix={config?.suffix ? config.suffix : undefined}
      fixedDecimalScale
    />
  );
};

export default NumberFormat;
