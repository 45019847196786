import { useQuery } from '@tanstack/react-query';
import { getExplains } from 'api/explainClient';
import { Explain, ExplainReferenceType } from 'api/types';

export function useExplains(
  id: number,
  type: ExplainReferenceType
): [Explain[], boolean] {
  const { data, isLoading } = useQuery({
    queryKey: [getExplains.name, id],
    queryFn: () => getExplains(id, type),
    initialData: [],
  });
  return [data, isLoading];
}
