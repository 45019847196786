import React from 'react';

import { Form, Input, Select } from 'antd';
import { COUNTRIES } from 'api/types';

type PostalContactFormProps = {
  named: boolean;
};

const PostalContactForm: React.FC<PostalContactFormProps> = ({ named }) => {
  return (
    <>
      <Form.Item
        hidden={!named}
        name={['name']}
        label="Name"
        required
        rules={[
          {
            required: named,
          },
        ]}
      >
        <Input type="string" />
      </Form.Item>
      <Form.Item
        name={['street', 'name']}
        label="Street name"
        required
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input type="string" />
      </Form.Item>
      <Form.Item
        name={['street', 'segment']}
        label="Street segment"
        required
        rules={[
          {
            required: false,
          },
        ]}
      >
        <Input type="string" />
      </Form.Item>
      <Form.Item
        name={['postalCode', 'code']}
        label="Postal Code"
        required
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input type="string" />
      </Form.Item>
      <Form.Item
        name={['city', 'name']}
        label="City"
        required
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input type="string" />
      </Form.Item>
      <Form.Item
        name={['stateOrProvince', 'name']}
        label="State"
        required
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input type="string" />
      </Form.Item>
      <Form.Item name="country" label="Country" required>
        <Select
          options={COUNTRIES.map(({ countryCode, name }) => ({
            label: name,
            value: countryCode,
          }))}
          allowClear
          style={{ width: '100%' }}
        />
      </Form.Item>
    </>
  );
};
export default PostalContactForm;
