export interface TypeAware {
  type: string;
}

/************************************************************************************
 * Agreemnt domain
 ************************************************************************************/
export interface Agreement {
  type: string;
  id: number;
  status: string;
  validFrom: string;
  validTo: string;
  asset: Asset;
}

/************************************************************************************
 * Asset domain
 ************************************************************************************/
interface Asset {
  id: number;
  type: string;
  name: string;
  description: string;
  fileReference: FileReference;
}

export interface FileReference {
  name: string;
  fileStore: string;
  mediaType: string;
}

/************************************************************************************
 * Explain domain
 ************************************************************************************/
export enum ExplainReferenceType {
  'LOAN_EVENT' = 'LOAN_EVENT',
}

export interface ExplainReference {
  id: number;
  type: ExplainReferenceType;
}

export interface Explain {
  id: number;
  reference: ExplainReference;
  description: string;
  created: string;
}

/************************************************************************************
 * Loan domain
 ************************************************************************************/
export interface Interest {
  capitalised: boolean;
  accruedInterestPeriod: string;
  interest: number;
}

export enum EnvironmentalClassifications {
  LEED_CERTIFIED = 'LEED_CERTIFIED',
  LEED_SILVER = 'LEED_SILVER',
  LEED_GOLD = 'LEED_GOLD',
  LEED_PLATINUM = 'LEED_PLATINUM',
  BREEAM_ACCEPTABLE = 'BREEAM_ACCEPTABLE',
  BREEAM_PASS = 'BREEAM_PASS',
  BREEAM_GOOD = 'BREEAM_GOOD',
  BREEAM_VERY_GOOD = 'BREEAM_VERY_GOOD',
  BREEAM_EXCELLENT = 'BREEAM_EXCELLENT',
  BREEAM_OUTSTANDING = 'BREEAM_OUTSTANDING',
  BREEAM_IN_USE_UNCLASSIFIED = 'BREEAM_IN_USE_UNCLASSIFIED',
  BREEAM_IN_USE_ACCEPTABLE = 'BREEAM_IN_USE_ACCEPTABLE',
  BREEAM_IN_USE_PASS = 'BREEAM_IN_USE_PASS',
  BREEAM_IN_USE_GOOD = 'BREEAM_IN_USE_GOOD',
  BREEAM_IN_USE_VERY_GOOD = 'BREEAM_IN_USE_VERY_GOOD',
  BREEAM_IN_USE_EXCELLENT = 'BREEAM_IN_USE_EXCELLENT',
  NORDIC_SWAN_ECOLABEL = 'NORDIC_SWAN_ECOLABEL',
  MILJÖBYGGNAD_BRONS = 'MILJÖBYGGNAD_BRONS',
  MILJÖBYGGNAD_SILVER = 'MILJÖBYGGNAD_SILVER',
  MILJÖBYGGNAD_GULD = 'MILJÖBYGGNAD_GULD',
  GREEN_BUILDING = 'GREEN_BUILDING',
  PASSIVE_HOUSE = 'PASSIVE_HOUSE',
}

export enum FoundationMaterial {
  WOOD = 'WOOD',
  NOT_WOOD = 'NOT_WOOD',
}

export enum EnergyClass {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
}

export enum SocialAspects {
  SAFETY_LIGHTING = 'SAFETY_LIGHTING',
  COMMUNITY_SPACES = 'COMMUNITY_SPACES',
  POSSIBILITY_FOR_SOCIAL_INTERACTIONS = 'POSSIBILITY_FOR_SOCIAL_INTERACTIONS',
  PHYSICAL_AVAILABILITY = 'PHYSICAL_AVAILABILITY',
  HOUSING_SHORTAGE_WITHIN_THE_COMMUNE = 'HOUSING_SHORTAGE_WITHIN_THE_COMMUNE',
  CONTRIBUTES_TOWARDS_LOCAL_ECONOMICAL_DEVELOPMENT = 'CONTRIBUTES_TOWARDS_LOCAL_ECONOMICAL_DEVELOPMENT',
}

export enum Seniority {
  SENIOR = 'SENIOR',
  JUNIOR = 'JUNIOR',
}

export interface Building {
  completed: boolean;
  numberOfApartments: number;
  seniority: Seniority;
  environmentalClassifications: EnvironmentalClassifications[];
  foundationMaterial: FoundationMaterial;
  energyClass: EnergyClass;
  socialAspects: SocialAspects[];
  renovation: boolean;
}

export interface Loan {
  borrower: Borrower | undefined;
  broker: Broker | undefined;
  capitalisedDebt?: number;
  currency: string;
  customerManager: User | undefined;
  customerSupport: User | undefined;
  defaultingInterest: number;
  developer: Developer | undefined;
  endDate: string;
  extensionFee?: number;
  id?: number;
  interest: Interest;
  lender: Lender | undefined;
  limit: number;
  name: string;
  registrationDate: string;
  risk: InternalRisk;
  setupFee?: number;
  signDate?: string;
  startDate: string;
  syndicated?: number;
  type: string;
  building: Building;
}

/************************************************************************************
 * Loan details domain
 ************************************************************************************/

export type LoanDetails = {
  active: boolean;
  ongoing: boolean;
  currentLoanTerm: LoanTerm;
  remainingLoanTerm: LoanTerm;
  originalLoanTerm: LoanTerm;
  originalInterest: number;
  originalLimit: number;
};

export type LoanTerm = {
  years: number;
  months: number;
  days: number;
};

/************************************************************************************
 * Loan event domain
 ************************************************************************************/
export type LoanEvent =
  | CapitalisedLoanEvent
  | CompositeLoanEvent
  | DrawDownLoanEvent
  | ExtendLoanEvent
  | SetupFeeLoanEvent;

export enum LoanEventState {
  'QUEUED' = 'QUEUED',
  'PROCESSING' = 'PROCESSING',
  'COMPLETED' = 'COMPLETED',
  'FAILED' = 'FAILED',
}

export enum LoanEventType {
  'CAPITALISED' = 'CAPITALISED',
  'DRAW_DOWN' = 'DRAW_DOWN',
  'EXTEND' = 'EXTEND',
  'SET_INTEREST' = 'SET_INTEREST',
  'SETUP_FEE' = 'SETUP_FEE',
}

export type AbstractLoanEvent = {
  id: number;
  loanId: number;
  type: LoanEventType;
  state: LoanEventState;
  created: string;
};

export type CapitalisedLoanEvent = AbstractLoanEvent & {
  capitalizedInterest: boolean;
};
export type CompositeLoanEvent = AbstractLoanEvent & { events: LoanEvent[] };
export type DrawDownLoanEvent = AbstractLoanEvent & { drawDown: number };
export type ExtendLoanEvent = AbstractLoanEvent & {
  percentage: number;
  fee: number;
  endDate: string;
};
export type SetupFeeLoanEvent = AbstractLoanEvent & {
  percentage: number;
  fee: number;
};

export type UnidentifiedLoanEvent =
  | UnidentifiedCapitalisedLoanEvent
  | UnidentifiedCompositeLoanEvent
  | UnidentifiedDrawDownLoanEvent
  | UnidentifiedExtendLoanEvent
  | UnidentifiedSetInterestLoanEvent
  | UnidentifiedSetupFeeLoanEvent;

export type AbstractUnidentifiedLoanEvent = {
  type: LoanEventType;
  loanId: number;
};

export type UnidentifiedCapitalisedLoanEvent = AbstractUnidentifiedLoanEvent & {
  capitalizedInterest: boolean;
};
export type UnidentifiedCompositeLoanEvent = AbstractUnidentifiedLoanEvent & {
  events: UnidentifiedLoanEvent[];
};
export type UnidentifiedDrawDownLoanEvent = AbstractUnidentifiedLoanEvent & {
  drawDown: number;
};
export type UnidentifiedExtendLoanEvent = AbstractUnidentifiedLoanEvent & {
  percentage: number;
  fee: number;
  endDate: string;
};
export type UnidentifiedSetInterestLoanEvent = AbstractUnidentifiedLoanEvent & {
  interest: number;
};
export type UnidentifiedSetupFeeLoanEvent = AbstractUnidentifiedLoanEvent & {
  percentage: number;
  fee: number;
};

/************************************************************************************
 * Journal domain
 ************************************************************************************/
export enum JournalType {
  SETUP_FEE = 'SETUP_FEE',
  DRAW_DOWN = 'DRAW_DOWN',
  EXTENSION = 'EXTENSION',
  PAYMENT = 'PAYMENT',
  PAYOFF = 'PAYOFF',
}

export interface Journal {
  id: number;
  type: JournalType;
  loanId: number;
  account: number;
  debit: number;
  credit: number;
  description: string;
  created: string;
}

/************************************************************************************
 * Report domain
 ************************************************************************************/
export type CellData = {
  heading: string;
  dataIndex: string[] | never[];
};

export type LoanReport = Loan & {
  days: number;
  capitalisedDebtAtStart: number;
  capitalisedDebtAtEnd: number;
  accruedInterest: number;
  payout: number;
  payment: number;
};

export type ReportType =
  | DayReport
  | DaysReport
  | MonthReport
  | MonthsReport
  | QuarterReport
  | QuartersReport
  | YearReport
  | YearsReport;

export interface PeriodReport extends TypeAware {
  accruedInterest: number;
  capitalisedDebt: number;
  drawDown: number;
  setupFee: number;
  extensionFee: number;
  payment: number;
  payoff: number;
}

export interface JournalsReport extends PeriodReport {
  journals: Journal[];
}

export interface DayReport extends JournalsReport {
  date: string;
  loanInterest: number;
  adjustedInterest: number;
}

export interface DaysReport extends JournalsReport {
  days: DayReport[];
}

export interface MonthReport extends PeriodReport {
  yearMonth: string;
  days: DayReport[];
}

export interface MonthsReport extends JournalsReport {
  months: MonthReport[];
}

export interface QuarterReport extends PeriodReport {
  yearQuarter: string;
  months: MonthReport[];
}

export interface QuartersReport extends JournalsReport {
  quarters: QuarterReport[];
}

export interface YearReport extends PeriodReport {
  year: number;
  quarters: QuarterReport[];
}

export interface YearsReport extends JournalsReport {
  years: YearReport[];
}

/******************************************************************************
 * PartyRole domain
 *****************************************************************************/
export type PartyRoles =
  // | BackOffice
  Broker | Developer | Lender | Borrower | User;

export type Broker = PartyRole;
export type Developer = PartyRole;
export type Lender = PartyRole;

/*
export interface BackOffice extends PartyRole {
  role: AuthorizationRole;
}
*/

/*export enum GrantedAuthorityRole {
  ADMIN,
  USER,
  EXTERNAL,
}*/

export interface Borrower extends PartyRole {
  contacts: Map<string, ContactMedium[]>;
  kyc: boolean;
  risk: Risk;
  borrowerType: BorrowerType;
  customerManagerId: string;
  bankAccountNumber: string | undefined;
}

export interface User extends PartyRole {
  authorizationRoles: AuthorizationRole[];
  password?: string;
}

export interface PartyRole {
  type: string;
  id?: number;
  party: Party;
  contactMediums: ContactMedium[];
}

export enum Risk {
  VERY_LOW = '5: Very Low',
  LOW = '4: Low',
  NORMAL = '3: Normal',
  HIGH = '2: High',
  VERY_HIGH = '1: Very High',
}

export enum InternalRisk {
  LOW = 'LOW',
  NORMAL = 'NORMAL',
  HIGH = 'HIGH',
}

export enum BorrowerType {
  MUNICIPAL = 'MUNICIPAL',
  HOUSING_COOPERATIVE = 'HOUSING_COOPERATIVE',
  NON_FINANCIAL_COMPANIES = 'NON_FINANCIAL_COMPANIES',
  BUSINESS_HOUSEHOLD = 'BUSINESS_HOUSEHOLD',
  OTHER_HOUSEHOLD = 'OTHER_HOUSEHOLD',
  NON_PROFIT_ORGANIZATION = 'NON_PROFIT_ORGANIZATION',
}

export enum PartyRoleType {
  BORROWER = 'BORROWER',
  LENDER = 'LENDER',
  BROKER = 'BROKER',
  DEVELOPER = 'DEVELOPER',
  USER = 'USER',
}

/******************************************************************************
 * Create/UpdatePartyRole domain
 *****************************************************************************/

/*
export type CreatePartyRolesRequest =
  | CreateBorrowerRequest
  | CreateUserRequest
  | CreateBrokerRequest
  | CreateDeveloperRequest
  | CreateLenderRequest;

export interface CreateBorrowerRequest extends CreatePartyRoleRequest {
  contacts: Map<string, ContactMedium[]>;
  kyc: boolean;
  risk: Risk;
  borrowerType: BorrowerType;
  customerManagerId: string;
  bankAccountNumber: string | undefined;
}

export interface CreateUserRequest extends CreatePartyRoleRequest {
  password: string;
  authorizationRoles: AuthorizationRole[];
}

export type CreateBrokerRequest = CreatePartyRoleRequest;
export type CreateDeveloperRequest = CreatePartyRoleRequest;
export type CreateLenderRequest = CreatePartyRoleRequest;

export interface CreatePartyRoleRequest {
  type: PartyRoleType;
  id: number;
  partyId: string;
  contactMediums: ContactMedium[];
}
*/

/******************************************************************************
 * Party domain
 *****************************************************************************/
export enum PartyType {
  INDIVIDUAL = 'INDIVIDUAL',
  ORGANISATION = 'ORGANISATION',
}

export enum PartyState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type AbstractParty = {
  id: number;
  version: number;
  type: string;
  identificationNumber: string;
  created: string;
  state: string;
  name: Name;
  contactMediums?: ContactMedium[] | null;
};

export type Individual = AbstractParty & {
  name: IndividualName;
  gender: Gender | null;
  citizenships: Country[];
  birthDate: string;
};

export type Organisation = AbstractParty & {
  name: OrganisationName;
  organisationType: OrganisationType;
};

export type Party = Individual | Organisation;

export type AbstractUnidentifiedParty = {
  type: string;
  identificationNumber: {
    number: string;
  };
  state: string;
  name: Name;
  contactMediums?: ContactMedium[] | null;
};

export type UnidentifiedIndividual = AbstractUnidentifiedParty & {
  name: IndividualName;
  gender: Gender | null;
  citizenships: Country[];
  birthDate: string;
};

export type UnidentifiedOrganisation = AbstractUnidentifiedParty & {
  name: OrganisationName;
  organisationType: OrganisationType;
};

export type UnidentifiedParty =
  | UnidentifiedIndividual
  | UnidentifiedOrganisation;

export class PartyName {
  id: number;

  type: string;

  name: string;

  constructor(theId: number, theType: string, theName: string) {
    this.id = theId;
    this.type = theType;
    this.name = theName;
  }
}

export class IndividualName extends PartyName {
  firstName: string;

  lastName: string;

  constructor(theId: number, theFirstName: string, theLastName: string) {
    super(theId, 'INDIVIDUAL', theFirstName + ' ' + theLastName);
    this.firstName = theFirstName;
    this.lastName = theLastName;
  }
}

export class OrganisationName extends PartyName {
  constructor(theId: number, theName: string) {
    super(theId, 'ORGANISATION', theName);
  }
}

export type Name = IndividualName | OrganisationName;

export enum Gender {
  UNKNOWN = 'UNKNOWN',
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum OrganisationType {
  LIMITED_COMPANY = 'Limited Company', //Aktiebolag
  SOLE_TRADER = 'Sole Trader', //Enskild näringsverksamhet
  TRADING_COMPANY = 'Trading Company', //Handelsbolag
  PARTNERSHIP = 'Partnership', //Enkelt bolag
  CLOSE_COMPANY = 'Close Comapny', //Fåmansföretag
  ECONOMIC_ASSOCIATION = 'Economic Association', //Förening
}

/************************************************************************************
 * Settings domain
 ************************************************************************************/
export enum SettingType {
  BIG_DECIMAL = 'BIG_DECIMAL',
  INTEGER = 'INTEGER',
  LONG = 'LONG',
}

export type SettingDescriptor = {
  name: string;
  enum: string;
  type: SettingType;
  value: string;
};

export type AbstractSetting = {
  id: string;
  name: string;
  description: string;
  created: string;
  type: string;
};

export type BigDecimalSetting = AbstractSetting & {
  value: number;
};

export type IntegerSetting = AbstractSetting & {
  value: number;
};

export type LongSetting = AbstractSetting & {
  value: number;
};

export type Setting = BigDecimalSetting | IntegerSetting | LongSetting;

export type AbstractUnidentifiedSetting = {
  name: string;
  description: string;
  created: string;
};

export type UnidentifiedBigDecimalSetting = AbstractUnidentifiedSetting & {
  value: number;
};

export type UnidentifiedIntegerSetting = AbstractUnidentifiedSetting & {
  value: number;
};

export type UnidentifiedSetting =
  | UnidentifiedBigDecimalSetting
  | UnidentifiedIntegerSetting
  | UnidentifiedIntegerSetting;

/************************************************************************************
 *  Contact mediums gets used in party and party roles to describe way of contacting
 *  someone.
 ************************************************************************************/
export interface Street {
  name: string;
  segment: string;
}

export interface PostalCode {
  code: string;
}

export interface City {
  name: string;
}

export interface StateOrProvince {
  name: string;
}

export type AbstractContactMedium = {
  id: number;
  type: string;
};

export type EmailContact = AbstractContactMedium & {
  address: string;
};

export type BankContact = AbstractContactMedium & {
  bic: string;
  iban: string;
  bankGiro: string;
};

export type WebContact = AbstractContactMedium & {
  address: string;
};

export type NamedEmailContact = EmailContact & {
  name: string;
};

export type PostalContact = AbstractContactMedium & {
  street: {
    name: string;
    segment: string;
  };
  city: {
    name: string;
  };
  stateOrProvince: {
    name: string;
  };

  postalCode: {
    code: string;
  };

  country: Country;
};

export type NamedPostalContact = PostalContact & {
  name: string;
};

export const COUNTRIES: Country[] = [
  {
    countryCode: 'SE',
    name: 'Sweden',
  },
  {
    countryCode: 'UK',
    name: 'Great Britian',
  },
];
export type Country = {
  countryCode: string;
  name: string;
};

export type TelephoneContact = AbstractContactMedium & {
  number: string;
  numberType: keyof typeof TelephoneNumberType;
};

export type NamedTelephoneContact = TelephoneContact & {
  name: string;
};

export enum TelephoneNumberType {
  LAND_LINE = 'LAND_LINE',
  MOBILE = 'MOBILE',
  SKYPE = 'SKYPE',
}

export type ContactMedium =
  | TelephoneContact
  | EmailContact
  | PostalContact
  | NamedTelephoneContact
  | NamedEmailContact
  | NamedPostalContact
  | BankContact
  | WebContact;

export enum ContactMediumType {
  EMAIL = 'EMAIL',
  POSTAL = 'POSTAL',
  TELEPHONE = 'TELEPHONE',
  NAMED_EMAIL = 'NAMED_EMAIL',
  NAMED_POSTAL = 'NAMED_POSTAL',
  NAMED_TELEPHONE = 'NAMED_TELEPHONE',
  WEB = 'WEB',
  BANK = 'BANK',
}

export interface CreditRequest {
  lender: string;
  borrower: string;
  security: Security;
  agreementId: string;
}

export interface Security {
  bail: boolean;
  pledge: boolean;
}

/************************************************************************************
 *  Requests
 ************************************************************************************/

/*export interface CreateOrganisationRequest {
  name?: OrganisationName;
  identificationNumber?: string;
  type: string;
  contactMediums?: ContactMedium[] | null;
}*/

export interface SubmitCreditResponse {
  id: number;
  lender: string;
  borrower: string;
  agreements: Agreement[];
}

/*export interface CreateLoanRequest {
  name: string;
  lender: string;
  borrower: CreateBorrowerRequest;
  broker: CreateBrokerRequest | undefined;
  developer: CreateDeveloperRequest | undefined;
  customerManager: CreateUserRequest | undefined;
  customerSupport: CreateUserRequest | undefined;
  registrationDate: string;
  startDate: string;
  endDate: string;
  type: string;
  interest: {
    accruedInterestPeriod: string;
    capitalised: boolean;
    interest: string;
  };
  defaultingInterest: number;
  currency: string;
  limit: string;
  risk: number;
}

export type PatchLoanRequest = {
  loanId: number;
  operations: Operation[];
};*/

/************************************************************************************
 *  BankId domain
 ************************************************************************************/

export enum BankIdAuthenticationStatus {
  UNHANDLED = 'unhandled',
  PENDING = 'pending',
  COMPLETED = 'complete',
  FAILED = 'failed',
  CANCELLED = 'cancelled',
  UNKNOWN = 'unknown',
}

export type CollectResponse = {
  hintCode: string;
  status: BankIdAuthenticationStatus;
};

export type CancelResponse = {
  status: BankIdAuthenticationStatus;
};

export type BankIdAuthenticateRequest = {
  pno: string;
  tokenStartRequired: boolean;
};

export type BankIdAuthenticateResponse = {
  orderRef: string;
  autoStartToken: string;
};

export type PaginatedResult<T> = {
  totalPages: number;
  totalElements: number;
  size: 0;
  content: T[];
};

export type PaginationQuery = {
  page: number;
  size: number;
  sort: string[];
};

export const DEFAULT_PAGINATED_RESULT = {
  totalPages: 1,
  totalElements: 0,
  size: 0,
  content: [],
};

export enum AuthorizationRole {
  ADMIN = 'ADMIN',
  BACK_OFFICE_ROLE = 'BACK_OFFICE_ROLE',
  READ = 'READ',
  EXECUTE_LOAN_EVENTS = 'EXECUTE_LOAN_EVENTS',
  EXECUTE_CAPITALISED_LOAN_EVENT = 'EXECUTE_CAPITALISED_LOAN_EVENT',
  EXECUTE_COMPOSITE_LOAN_EVENT = 'EXECUTE_COMPOSITE_LOAN_EVENT',
  EXECUTE_DRAW_DOWN_EVENT = 'EXECUTE_DRAW_DOWN_EVENT',
  EXECUTE_EXTEND_LOAN_EVENT = 'EXECUTE_EXTEND_LOAN_EVENT',
  EXECUTE_SETUP_FEE_LOAN_EVENT = 'EXECUTE_SETUP_FEE_LOAN_EVENT',
  CREATE_LOAN = 'CREATE_LOAN',
  UPDATE_LOAN = 'UPDATE_LOAN',
  DELETE_LOAN = 'DELETE_LOAN',
  CREATE_BUILDING_CREDIT_LOAN = 'CREATE_BUILDING_CREDIT_LOAN',
  CREATE_INTEREST_BEARING_REVERSE_CREDIT_LOAN = 'CREATE_INTEREST_BEARING_REVERSE_CREDIT_LOAN',
  CREATE_LENDER = 'CREATE_LENDER',
  CREATE_ROLE = 'CREATE_ROLE',
  CREATE_ORGANISATION = 'CREATE_ORGANISATION',
  UPDATE_ORGANISATION = 'UPDATE_ORGANISATION',
  DELETE_ORGANISATION = 'DELETE_ORGANISATION',
  CREATE_INDIVIDUAL = 'CREATE_INDIVIDUAL',
  UPDATE_INDIVIDUAL = 'UPDATE_INDIVIDUAL',
  DELETE_INDIVIDUAL = 'DELETE_INDIVIDUAL',
}

export type CreateInvoiceRequest = {
  loanId: number;
  from: string;
  to: string;
};

export type CreateLoanExcelDocumentRequest = {
  query: string | undefined;
  sort: string[];
  cellData: CellData[];
};
