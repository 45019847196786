import React, { useCallback, useState } from 'react';

import { PartyRoles, PartyRoleType } from 'api/types';
import { useSubmitPartyRole } from 'hooks/withPartyRole';
import { Select } from 'antd';
import RoleForm from 'components/forms/RoleForm';

const CreateRoleView: React.FC = ({}) => {
  const [createPartyRole, createPartyRoleStatus] = useSubmitPartyRole();

  /*  const handleCreatePartyRole = useCallback(
    (data: PartyRoles) => {
      if (data.request && 'partyId' in data.request) {
        createPartyRole(data.request);
      }
    },
    [createPartyRole]
  );*/
  const [selectedRoleType, setSelectedRoleType] = useState<
    PartyRoleType | undefined
  >(undefined);

  return (
    <div className="mt-10">
      <h1 className="mb-0 py-5">Create Role</h1>
      <div className="flex flex-col mt-10">
        <Select
          placeholder={'Select role type'}
          options={Object.entries(PartyRoleType).map(([value, name]) => ({
            label: name,
            value: value,
          }))}
          style={{ width: '100%' }}
          onSelect={setSelectedRoleType}
        />
        <br />
        <RoleForm
          onCreateOrUpdate={createPartyRole}
          partyRoleType={selectedRoleType}
          isUpdate={false}
          isLoading={createPartyRoleStatus === 'pending'}
        />
      </div>
    </div>
  );
};

export default CreateRoleView;
