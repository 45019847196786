import React from 'react';

import { Form, Input, Select } from 'antd';
import { TelephoneNumberType } from 'api/types';

type TelephoneContactFormProps = {
  named: boolean;
};

const TelephoneContactForm: React.FC<TelephoneContactFormProps> = ({
  named,
}) => {
  return (
    <>
      <Form.Item
        hidden={!named}
        name={['name']}
        label="Name"
        required
        rules={[
          {
            required: named,
          },
        ]}
      >
        <Input type="string" />
      </Form.Item>
      <Form.Item
        name="number"
        label="Number"
        required
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input type="string" />
      </Form.Item>
      <Form.Item name="numberType" label="Number type" required>
        <Select
          options={Object.entries(TelephoneNumberType).map(([value, name]) => ({
            label: name,
            value: value,
          }))}
          allowClear
          style={{ width: '100%' }}
        />
      </Form.Item>
    </>
  );
};
export default TelephoneContactForm;
