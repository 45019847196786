import React from 'react';

import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useLocation, useNavigate } from 'react-router-dom';
import ListRoles from 'views/Roles/ListRoles';
import CreateRole from 'views/Roles/CreateRole';

const routesOrder = ['/roles/lenders/create', '/roles/lenders/list'];

const RolesView = () => {
  const location = useLocation();

  const defaultIndex = routesOrder.indexOf(location.pathname);

  const navigate = useNavigate();

  return (
    <div>
      <div className="card">
        <Tabs
          onSelect={(idx) => {
            navigate(routesOrder[idx]);
          }}
          defaultIndex={defaultIndex === -1 ? 0 : defaultIndex}
        >
          <TabList>
            <Tab>List Roles</Tab>
            <Tab>Create Role</Tab>
          </TabList>

          <TabPanel>
            <ListRoles />
          </TabPanel>
          <TabPanel>
            <CreateRole />
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

export default RolesView;
