import { useCallback, useState } from 'react';
import { SorterResult } from 'antd/es/table/interface';

const useSorter = <T>() => {
  const [sort, setSort] = useState<string[]>([]);

  const handleSort = useCallback(
    (
      sorts: {
        direction: 'ascend' | 'descend' | undefined | null;
        field: any;
      }[]
    ) => {
      setSort(
        sorts.map((dir) => {
          return `${dir.field},${dir.direction === 'ascend' ? 'asc' : 'desc'}`;
        })
      );
    },
    []
  );

  const handleTableSort = useCallback(
    (s: SorterResult<T> | SorterResult<T>[]) => {
      let payload: {
        direction: 'ascend' | 'descend' | undefined | null;
        field: any;
      }[] = [];

      if (Array.isArray(s)) {
        payload = s.map((i) => ({
          field: i.columnKey,
          direction: i.order,
        }));
      } else {
        payload.push({
          field: s.columnKey,
          direction: s.order,
        });
      }

      handleSort(payload);
    },
    [handleSort]
  );

  return { sort, handleSort: handleTableSort };
};

export default useSorter;
