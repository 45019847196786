import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  weekStart: 1,
});

import 'react-tabs/style/react-tabs.css';

import './index.css';
import { Loading } from 'components/Loading';
import { ChakraProvider } from '@chakra-ui/react';
import Contexts from './contexts/Contexts';
// import 'antd/dist/antd.min.css';

// Create a client
const queryClient = new QueryClient();

const isDevServer =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

ReactDOM.render(
  <React.StrictMode>
    <Contexts>
      <Router>
        <ChakraProvider>
          <QueryClientProvider client={queryClient}>
            <Loading />
            {isDevServer && <ReactQueryDevtools initialIsOpen={false} />}
            <App />
          </QueryClientProvider>
        </ChakraProvider>
      </Router>
    </Contexts>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
