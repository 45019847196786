import React from 'react';

import { Form, Input } from 'antd';

type EmailContactFormProps = {
  named: boolean;
};

const WebContactForm: React.FC<EmailContactFormProps> = ({ named }) => {
  return (
    <>
      <Form.Item
        hidden={!named}
        name={['name']}
        label="Name"
        required
        rules={[
          {
            required: named,
          },
        ]}
      >
        <Input type="string" />
      </Form.Item>
      <Form.Item
        name="address"
        label="Web Address"
        required
        rules={[
          {
            type: 'url',
            required: true,
          },
        ]}
      >
        <Input type="text" />
      </Form.Item>
    </>
  );
};
export default WebContactForm;
