import { useEffect, useRef } from 'react';
import {
  DaysReport,
  DEFAULT_PAGINATED_RESULT,
  Loan,
  LoanDetails,
  LoanReport,
  MonthsReport,
  PaginatedResult,
  PaginationQuery,
  QuartersReport,
  YearsReport,
} from 'api/types';
import {
  UseMutateFunction,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import {
  createLoan,
  getDaysInterestReport,
  getLoanDetails,
  getLoans,
  getLoansByIdentificationNumber,
  getLoansPaginated,
  getMonthsInterestReport,
  getQuartersInterestReport,
  getYearsInterestReport,
  updateLoan,
} from 'api/loanClient';
import { message } from 'antd';
import { AxiosError } from 'axios';

/************************************************************************************
 *  Loan
 ***********************************************************************************/
export function useLoans(): [Loan[], boolean] {
  const { data, isLoading } = useQuery({
    queryKey: [getLoans.name],
    queryFn: getLoans,
    initialData: [],
  });
  return [data, isLoading];
}

export function useLoansQuery(
  pagination: PaginationQuery,
  query?: string
): [PaginatedResult<LoanReport>, boolean, boolean] {
  const initialData = useRef<PaginatedResult<LoanReport>>(
    DEFAULT_PAGINATED_RESULT as PaginatedResult<LoanReport>
  );

  const { data, isLoading, isPending, isError } = useQuery({
    queryKey: [
      getLoansPaginated.name,
      JSON.stringify(query),
      JSON.stringify(pagination),
    ],
    queryFn: () => getLoansPaginated(pagination, query),
    retry: 0,
    initialData:
      initialData.current ||
      (DEFAULT_PAGINATED_RESULT as PaginatedResult<LoanReport>),
  });

  useEffect(() => {
    if (!isPending) {
      initialData.current = data;
    }
  }, [JSON.stringify(data), isPending]); // eslint-disable-line
  return [data, isLoading, isError];
}

export function useLoansByIdentificationNumber(
  identficationNumber: string | undefined
): [Loan[], boolean] {
  const { data, isLoading } = useQuery({
    queryKey: [getLoansByIdentificationNumber.name, identficationNumber],
    queryFn: () => getLoansByIdentificationNumber(identficationNumber),
    initialData: [],
    enabled: !!identficationNumber,
  });
  return [data, isLoading];
}

export const useSubmitLoan = (): [
  UseMutateFunction<void, Error, Loan, unknown>,
  'error' | 'idle' | 'pending' | 'success'
] => {
  const { mutate, status } = useMutation({
    mutationFn: createLoan,
    onError: () => {
      message.error('Error while submitting loan request');
    },
    onSuccess: () => {
      message.success('Successfully submitted loan request!');
    },
  });

  return [mutate, status];
};

export const useUpdateLoan = (): [
  UseMutateFunction<void, Error, Loan, unknown>,
  'error' | 'idle' | 'pending' | 'success'
] => {
  const { mutate, status } = useMutation({
    mutationFn: updateLoan,
    onError: () => {
      message.error('Error while updating loan');
    },
    onSuccess: () => {
      message.success('Successfully updated loan!');
    },
  });

  return [mutate, status];
};

/************************************************************************************
 *  Report
 ***********************************************************************************/
export function useDaysInterestReports(
  enable: () => boolean,
  loanId: number | undefined
): [DaysReport | undefined, boolean] {
  const query = useQuery({
    queryKey: [getDaysInterestReport.name, loanId],
    queryFn: () => getDaysInterestReport(loanId),
    enabled: enable(),
    initialData: undefined,
    retry: (failureCount, error) =>
      skipRetryIfMissingReport(failureCount, error),
  });
  return [query.data, query.isLoading];
}

export function useMonthInterestReports(
  enable: () => boolean,
  loanId: number | undefined
): [MonthsReport | undefined, boolean] {
  const query = useQuery({
    queryKey: [getMonthsInterestReport.name, loanId],
    queryFn: () => getMonthsInterestReport(loanId),
    enabled: enable(),
    initialData: undefined,
    retry: (failureCount, error) =>
      skipRetryIfMissingReport(failureCount, error),
  });
  return [query.data, query.isLoading];
}

export function useQuarterInterestReports(
  enable: () => boolean,
  loanId: number | undefined
): [QuartersReport | undefined, boolean] {
  const query = useQuery({
    queryKey: [getQuartersInterestReport.name, loanId],
    queryFn: () => getQuartersInterestReport(loanId),
    enabled: enable(),
    initialData: undefined,
    retry: (failureCount, error) =>
      skipRetryIfMissingReport(failureCount, error),
  });
  return [query.data, query.isLoading];
}

export function useYearInterestReports(
  enable: () => boolean,
  loanId: number | undefined
): [YearsReport | undefined, boolean] {
  const query = useQuery({
    queryKey: [getYearsInterestReport.name, loanId],
    queryFn: () => getYearsInterestReport(loanId),
    enabled: enable(),
    initialData: undefined,
    retry: (failureCount, error) =>
      skipRetryIfMissingReport(failureCount, error),
  });
  return [query.data, query.isLoading];
}

/************************************************************************************
 *  Loan Details
 ***********************************************************************************/
export function useLoanDetails(
  loanId: number | undefined
): [LoanDetails | undefined, boolean] {
  const { data, isLoading } = useQuery({
    queryKey: [getLoanDetails.name, loanId],
    queryFn: () => getLoanDetails(loanId),
    initialData: undefined,
    enabled: !!loanId,
  });
  return [data, isLoading];
}

function skipRetryIfMissingReport(failureCount: number, error: Error): boolean {
  if (failureCount > 2) {
    return false;
  }
  if (error instanceof AxiosError) {
    const axiosError = error as AxiosError;
    if (axiosError.response?.status === 404) {
      if (axiosError.response.data) {
        const m: any = axiosError.response.data;
        if (m.errors) {
          message.warning(m.errors[0]?.message);
        }
      }
      return false;
    }
  }
  return true;
}
