import axios from 'axios';
import {
  Explain,
  ExplainReferenceType,
  LoanEvent,
  Setting,
  UnidentifiedLoanEvent,
} from 'api/types';

import { ENDPOINT_BASE } from 'constants/index';

const EXPLAIN_PATH = 'explains';
const baseUrlLoanEvents = `${ENDPOINT_BASE}${EXPLAIN_PATH}`;

const client = axios.create({
  withCredentials: true,
  baseURL: baseUrlLoanEvents,
});

/********************************************************************
 *  Explains
 *******************************************************************/
export async function getExplains(
  eventId: number,
  type: ExplainReferenceType
): Promise<Explain[]> {
  const response = await client.get<Explain[]>(`/${eventId}/${type}`);
  return response.data;
}
