import React, { useCallback } from 'react';
import { InternalRisk, Loan, LoanDetails } from 'api/types';
import { Form, Input, Tag } from 'antd';
import { createTagFromInternalRisk } from 'utils/risk';

type LoanDetailsProps = {
  loan: Loan | undefined;
  loanDetails: LoanDetails | undefined;
};

const readonlyStyle = { backgroundColor: 'rgb(240,240,240)' };
const readonlyStyleItalic = {
  backgroundColor: 'rgb(240,240,240)',
  fontStyle: 'italic',
};

const LoanDetailCurrentStatus: React.FC<LoanDetailsProps> = ({
  loan,
  loanDetails,
}) => {
  const formatCurrencySwedish = useCallback(
    (value) => {
      return new Intl.NumberFormat('sv-SE', {
        style: 'currency',
        currency: loan ? loan.currency : 'SEK',
      })
        .format(value)
        .replace('kr', '');
    },
    [loan]
  );

  const getCapitalisedDebtWithSyndication = useCallback((): string => {
    if (loan?.capitalisedDebt) {
      const debt =
        loan.capitalisedDebt - (loan.syndicated ? loan.syndicated : 0);
      return formatCurrencySwedish(debt);
    }
    return 'unknown';
  }, [formatCurrencySwedish, loan]);

  return (
    <>
      <Form.Item label="Internal Risk">
        {createTagFromInternalRisk(loan?.risk)}
      </Form.Item>

      <Form.Item label="LTV">
        <Input readOnly style={readonlyStyle} value={'Not implemented'} />
      </Form.Item>
      <Form.Item label="Market Value">
        <Input readOnly style={readonlyStyle} value={'Not implemented'} />
      </Form.Item>
      <Form.Item label="Valuation Type">
        <Input readOnly style={readonlyStyle} value={'Not implemented'} />
      </Form.Item>
      <Form.Item label="UC Class">
        <Input readOnly style={readonlyStyle} value={'Not implemented'} />
      </Form.Item>
      <Form.Item label="Credit Limit">
        <Input
          readOnly
          style={readonlyStyle}
          value={formatCurrencySwedish(loan?.limit)}
        />
      </Form.Item>
      <Form.Item
        name={['loan', 'interest', 'interest']}
        label="Interest"
        initialValue={loan?.interest.interest}
      >
        <Input readOnly style={readonlyStyle} />
      </Form.Item>
      <Form.Item label="Syndicated">
        <Input readOnly style={readonlyStyle} value={'Not implemented'} />
      </Form.Item>
      <Form.Item label="Syndicated Amount">
        <Input
          readOnly
          style={readonlyStyle}
          value={formatCurrencySwedish(loan?.syndicated)}
        />
      </Form.Item>
      <Form.Item label="Loan Claim">
        <Input readOnly style={readonlyStyle} value={'Not implemented'} />
      </Form.Item>
      {/*Loan Claim subsection START*/}
      <Form.Item
        label="Inspection Fee"
        labelCol={{ span: 11 }}
        wrapperCol={{ span: 11 }}
      >
        <Input readOnly style={readonlyStyle} value={'Not implemented'} />
      </Form.Item>
      <Form.Item
        label="Unpayed Issued Interest Invoices (non-overdue)"
        labelCol={{ span: 11 }}
        wrapperCol={{ span: 11 }}
      >
        <Input readOnly style={readonlyStyle} value={'Not implemented'} />
      </Form.Item>
      <Form.Item
        label="Unpayed Issued Interest Invoices (overdue)"
        labelCol={{ span: 11 }}
        wrapperCol={{ span: 11 }}
      >
        <Input readOnly style={readonlyStyle} value={'Not implemented'} />
      </Form.Item>
      <Form.Item
        label="Nominal Amount"
        labelCol={{ span: 11 }}
        wrapperCol={{ span: 11 }}
      >
        <Input readOnly style={readonlyStyle} value={'Not implemented'} />
      </Form.Item>
      <Form.Item
        label="Amount Paid"
        labelCol={{ span: 13 }}
        wrapperCol={{ span: 9 }}
        style={{ fontStyle: 'italic' }}
      >
        <Input readOnly style={readonlyStyleItalic} value={'Not implemented'} />
      </Form.Item>
      <Form.Item
        label="Setup Fee"
        labelCol={{ span: 13 }}
        wrapperCol={{ span: 9 }}
        style={{ fontStyle: 'italic' }}
      >
        <Input readOnly style={readonlyStyleItalic} value={'Not implemented'} />
      </Form.Item>
      <Form.Item
        label="Accumulated Capitalised Interest"
        labelCol={{ span: 11 }}
        wrapperCol={{ span: 11 }}
      >
        <Input readOnly style={readonlyStyle} value={'Not implemented'} />
      </Form.Item>
      {/*Loan Claim subsection END*/}
      <Form.Item label="Capitalised Debt">
        <Input
          readOnly
          style={readonlyStyle}
          value={formatCurrencySwedish(loan?.capitalisedDebt)}
        />
      </Form.Item>
      <Form.Item label="Capitalised Debt (incl. Syndication)">
        <Input
          readOnly
          style={readonlyStyle}
          value={getCapitalisedDebtWithSyndication()}
        />
      </Form.Item>
    </>
  );
};

export default LoanDetailCurrentStatus;
