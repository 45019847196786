import React from 'react';

import { Form, Input } from 'antd';

type EmailContactFormProps = {
  named: boolean;
};

const BankContactForm: React.FC<EmailContactFormProps> = ({ named }) => {
  return (
    <>
      <Form.Item
        hidden={!named}
        name={['name']}
        label="Name"
        required
        rules={[
          {
            required: named,
          },
        ]}
      >
        <Input type="string" />
      </Form.Item>
      <Form.Item
        name={'bic'}
        label="BIC"
        required
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input type="string" />
      </Form.Item>
      <Form.Item
        name={'iban'}
        label="IBAN"
        required
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input type="string" />
      </Form.Item>
      <Form.Item
        name={'bankgiro'}
        label="Bankgiro"
        required
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input type="string" />
      </Form.Item>
    </>
  );
};
export default BankContactForm;
