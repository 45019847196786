import { Col, Row, Statistic } from 'antd';
import { PeriodReport, JournalsReport, Loan } from 'api/types';
import {
  ACCRUED_INTEREST_LABEL,
  CAPITALISED_DEBT_LABEL,
  DRAW_DOWN_LABEL,
  EXTENSION_FEE_LABEL,
  PAYMENT_LABEL,
  PAYOFF_LABEL,
  SETUP_FEE_LABEL,
} from 'constants/index';
import { useCallback } from 'react';

type LoanReportStatisticProps = {
  report: PeriodReport;
  loan: Loan;
};

const LoanReportStatisticView: React.FC<LoanReportStatisticProps> = ({
  report,
  loan,
}) => {
  const formatCurrencySwedish = useCallback(
    (value) => {
      return new Intl.NumberFormat('sv-SE', {
        style: 'currency',
        currency: loan.currency,
      })
        .format(value)
        .replace('kr', '');
    },
    [loan.currency]
  );

  return (
    <>
      <Row>
        <Statistic
          title={CAPITALISED_DEBT_LABEL}
          value={formatCurrencySwedish(report.capitalisedDebt)}
          precision={0}
        />
      </Row>
      <Row>
        <Statistic
          title={ACCRUED_INTEREST_LABEL}
          value={formatCurrencySwedish(report.accruedInterest)}
          precision={0}
        />
      </Row>
      <Row>
        <Statistic
          title={DRAW_DOWN_LABEL}
          value={formatCurrencySwedish(report.drawDown)}
          precision={0}
        />
      </Row>
      <Row>
        <Statistic
          title={SETUP_FEE_LABEL}
          value={formatCurrencySwedish(report.setupFee)}
          precision={0}
        />
      </Row>
      <Row>
        <Statistic
          title={EXTENSION_FEE_LABEL}
          value={formatCurrencySwedish(report.extensionFee)}
          precision={0}
        />
      </Row>
      <Row>
        <Statistic
          title={PAYMENT_LABEL}
          value={formatCurrencySwedish(report.payment)}
          precision={0}
        />
      </Row>
      <Row>
        <Statistic
          title={PAYOFF_LABEL}
          value={formatCurrencySwedish(report.payoff)}
          precision={0}
        />
      </Row>
    </>
  );
};
export default LoanReportStatisticView;
