import axios from 'axios';

import { ENDPOINT_BASE } from 'constants/index';
import {
  Individual,
  Loan,
  PartyRoles,
  PartyRoleType,
  UnidentifiedIndividual,
} from './types';

const INDIVIDUAL_PATH = 'individual';
const baseUrl = `${ENDPOINT_BASE}${INDIVIDUAL_PATH}`;

const client = axios.create({
  withCredentials: true,
  baseURL: baseUrl,
});

export async function addIndividual(
  request: UnidentifiedIndividual
): Promise<Individual> {
  const response = await client.post<Individual>('', request);

  return response.data;
}

export async function getIndividual(
  identificationNumber: string | undefined
): Promise<Individual> {
  if (!identificationNumber) {
    throw new Error('IdentificationNumber missing');
  }
  const response = await client.get<Individual>(`/${identificationNumber}`);
  return response.data;
}

export async function getIndividuals(): Promise<Individual[]> {
  const response = await client.get<Individual[]>('');
  return response.data;
}

export async function deleteIndividual(id: string): Promise<void> {
  await client.delete(`/${id}`);
}

export async function updateIndividual(
  individual: Individual
): Promise<Individual> {
  const response = await client.put<Individual>('', individual);
  return response.data;
}
