import { Card, Col, Row } from 'antd';
import {
  ContactMediumType,
  EmailContact,
  Organisation,
  PostalContact,
  TelephoneContact,
} from 'api/types';

type ListOrganisationModalProps = {
  organisation: Organisation;
};

const ListOrganisationModal: React.FC<ListOrganisationModalProps> = ({
  organisation,
}) => {
  return (
    <Row gutter={16}>
      {organisation?.contactMediums?.length
        ? organisation?.contactMediums?.map((contactMedium) => {
            switch (contactMedium.type) {
              case ContactMediumType.EMAIL:
                const email = contactMedium as EmailContact;
                return (
                  <Col span={6}>
                    <Card bordered={false} title="Email">
                      <span>
                        <b>Address:</b> {email.address}
                      </span>
                    </Card>
                  </Col>
                );
              case ContactMediumType.POSTAL:
                const postal = contactMedium as PostalContact;
                return (
                  <Col span={6}>
                    <Card bordered={false} title="Postal Address">
                      <div>
                        <b>Street:</b> {postal.street?.name}
                      </div>
                      <div>
                        <b>Segment:</b> {postal.street?.segment}
                      </div>
                      <div>
                        <b>City:</b> {postal.city.name}
                      </div>
                      <div>
                        <b>State:</b> {postal.stateOrProvince?.name}
                      </div>
                      <div>
                        <b>Country:</b> {postal.country?.name}
                      </div>
                    </Card>
                  </Col>
                );
              case ContactMediumType.TELEPHONE:
                const telephone = contactMedium as TelephoneContact;
                return (
                  <Col span={6}>
                    <Card bordered={false} title="Telephone">
                      <div>
                        <b>Number:</b> {telephone.number}
                      </div>
                      <div>
                        <b>Type:</b> {telephone.numberType}
                      </div>
                    </Card>
                  </Col>
                );
            }
          })
        : 'No Contact Mediums.'}
    </Row>
  );
};
export default ListOrganisationModal;
