import React, { useCallback, useState } from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Modal, Popconfirm, Select, Table } from 'antd';
import {
  AbstractParty,
  Broker,
  Name,
  PartyRoles,
  PartyRoleType,
  Risk,
} from 'api/types';
import ListRolesExpanded from './ListRolesExpanded';
import {
  useDeletePartyRole,
  useGetPartyRoles,
  useUpdatePartyRole,
} from 'hooks/withPartyRole';
import { useQueryClient } from '@tanstack/react-query';
import { getPartyRolesOfType } from 'api/partyRoleClient';
import RoleForm from 'components/forms/RoleForm';

function getLabelName(party: AbstractParty | never): string {
  if ('name' in party) {
    const name: Name = party.name;
    if ('firstName' in name) {
      return name.firstName + ' ' + name.lastName;
    } else if ('name' in name) {
      return name.name;
    }
    return '-';
  }
  return '-';
}

const ViewText = 'Roles';

const ListRolesView: React.FC = () => {
  const queryClient = useQueryClient();

  const deletePartyRole = useDeletePartyRole();

  const handleDelete = (id) => {
    deletePartyRole.mutate(id);
  };

  const [selectedRoleType, setSelectedRoleType] = useState<
    PartyRoleType | undefined
  >(undefined);

  const [partyRolesList, isLoadingPartyRoles] =
    useGetPartyRoles(selectedRoleType);

  const [originalPartyRole, setOriginalPartyRole] = useState<
    PartyRoles | undefined
  >(undefined);

  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);

  const [updatePartyRole, updatePartyRoleStatus] = useUpdatePartyRole();

  /*  const handleUpdatePartyRole = useCallback(
    (data: PartyRoles) => {
      if (data.request && 'partyId' in data.request) {
        updatePartyRole(data.request);
      }
    },
    [updatePartyRole]
  );*/

  const handleSelectRoleType = useCallback(
    (value: string) => {
      queryClient.resetQueries({
        queryKey: [getPartyRolesOfType.name, PartyRoleType[value]],
      });
      setSelectedRoleType(PartyRoleType[value]);
    },
    [queryClient]
  );

  const handleEditPartyRole = (partyRole: PartyRoles) => {
    setOriginalPartyRole(partyRole);
    setIsModalOpened(true);
  };

  const onClose = () => {
    setOriginalPartyRole(undefined);
    setIsModalOpened(false);
  };

  const columns = [
    {
      title: 'Role',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Associated Party',
      dataIndex: 'party',
      key: 'party',
      render: (item) => {
        return getLabelName(item);
      },
    },
    /* KYC (only for Borrower)*/
    {
      title: 'KYC Approved',
      dataIndex: 'kyc',
      key: 'kyc',
      hidden: PartyRoleType.BORROWER != selectedRoleType,
      render: (item) => {
        return item === true ? 'Yes' : 'No';
      },
    },
    /* Risk (only for Borrower)*/
    {
      title: 'Risk',
      dataIndex: 'risk',
      key: 'risk',
      hidden: PartyRoleType.BORROWER != selectedRoleType,
      render: (item) => {
        return Risk[item];
      },
    },
    /* Borrower type (only for Borrower)*/
    {
      title: 'Borrower type',
      dataIndex: 'borrowerType',
      key: 'borrowerType',
      hidden: PartyRoleType.BORROWER != selectedRoleType,
    },
    /* Customer Manager (only for Borrower)*/
    {
      title: 'Customer Manager',
      dataIndex: 'customerManagerId',
      key: 'customerManagerId',
      hidden: PartyRoleType.BORROWER != selectedRoleType,
    },

    /* Authorization Roles (only for User)*/
    {
      title: 'Authorization',
      dataIndex: 'authorizationRoles',
      key: 'authorizationRoles',
      hidden: PartyRoleType.USER != selectedRoleType,
      render: (item) => {
        return item.toString();
      },
    },

    {
      title: '',
      dataIndex: '',
      key: 'x',
      render: (partyRole) => (
        <div className="flex flex-row">
          <Popconfirm
            title="Please confirm"
            description="Are you sure you want to delete this role?"
            onConfirm={() => handleDelete(partyRole.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">
              <DeleteOutlined style={{ color: 'red' }} />
            </Button>
          </Popconfirm>
          <Button type="link" onClick={() => handleEditPartyRole(partyRole)}>
            <EditOutlined />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="mt-10">
      <h1 className="mb-0 py-5">{`${ViewText}`}</h1>
      <div className="flex flex-col mt-5">
        <Select
          placeholder={'Select role type'}
          options={Object.entries(PartyRoleType).map(([value, name]) => ({
            label: name,
            value: value,
          }))}
          style={{ width: '33%' }}
          onSelect={handleSelectRoleType}
        />
        <Table
          dataSource={partyRolesList}
          columns={columns}
          pagination={{ defaultPageSize: 10 }}
          loading={isLoadingPartyRoles}
          rowKey={(partyRole) => (partyRole.id ? partyRole.id.toString() : '')}
          expandable={{
            expandedRowRender: (partyRole) => (
              <ListRolesExpanded partyRole={partyRole} />
            ),
          }}
        />
        <Modal
          open={isModalOpened}
          onCancel={onClose}
          width={800}
          footer={null}
          destroyOnClose
          title="Update Role"
        >
          <RoleForm
            initialData={originalPartyRole}
            partyRoleType={selectedRoleType}
            onCreateOrUpdate={updatePartyRole}
            isUpdate={true}
            isLoading={updatePartyRoleStatus === 'pending'}
            callback={onClose}
          />
        </Modal>
      </div>
    </div>
  );
};

export default ListRolesView;
