import React, { useEffect, useState } from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Modal, Popconfirm, Table } from 'antd';
import {
  useDeleteIndividual,
  useGetIndividuals,
  useUpdateIndividual,
} from 'hooks/withIndividual';
import { Individual } from 'api/types';
import { getDateString } from 'utils/date';

import IndividualForm from 'components/forms/IndividualForm';
import ListIndividualExpanded from './ListIndividualExpanded';

const ListIndividualsView: React.FC = () => {
  const deleteIndividual = useDeleteIndividual();

  const handleDelete = (id) => {
    deleteIndividual.mutate(id);
  };

  const [individuals, isIndividualsLoading] = useGetIndividuals();

  const [originalIndividual, setOriginalIndividual] = useState<
    Individual | undefined
  >(undefined);

  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);

  const [updateIndividual, updateIndividualStatus] = useUpdateIndividual();

  const handleEditIndividual = (individual: Individual) => {
    setOriginalIndividual(individual);
    setIsModalOpened(true);
  };

  const onClose = () => {
    setOriginalIndividual(undefined);
    setIsModalOpened(false);
  };

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (item) => {
        return item
          ? Object.values(item).length > 2
            ? Object.values(item)[2]
            : '-'
          : '-';
      },
    },

    {
      title: 'Identification Number',
      dataIndex: 'identificationNumber',
      key: 'identificationNumber',
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
    },
    {
      title: 'Created',
      dataIndex: 'created',
      key: 'created',
      render: (data) => getDateString(data),
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      render: (individual) => (
        <div className="flex flex-row">
          <Popconfirm
            title="Please confirm"
            description="Are you sure you want to delete this individual?"
            onConfirm={() => handleDelete(individual.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">
              <DeleteOutlined style={{ color: 'red' }} />
            </Button>
          </Popconfirm>
          <Button type="link" onClick={() => handleEditIndividual(individual)}>
            <EditOutlined />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="mt-10">
      <h1 className="mb-0 py-5">List Individuals</h1>
      <div className="flex flex-col mt-5">
        <Table
          dataSource={individuals}
          columns={columns}
          pagination={{ defaultPageSize: 10 }}
          loading={isIndividualsLoading}
          rowKey={(individual) => individual.id}
          expandable={{
            expandedRowRender: (individual) => (
              <ListIndividualExpanded individual={individual} />
            ),
          }}
        />
        <Modal
          open={isModalOpened}
          onCancel={onClose}
          width={800}
          footer={null}
          destroyOnClose
          title="Update Individual"
        >
          <IndividualForm
            initialData={originalIndividual}
            onUpdate={updateIndividual}
            isLoading={updateIndividualStatus === 'pending'}
            callback={onClose}
          />
        </Modal>
      </div>
    </div>
  );
};

export default ListIndividualsView;
