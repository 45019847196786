import { message } from 'antd';
import { PartyRoles, PartyRoleType } from 'api/types';

import {
  UseMutateFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {
  createPartyRole,
  deletePartyRole,
  getPartyRolesOfType,
  updatePartyRole,
} from 'api/partyRoleClient';

export const useSubmitPartyRole = (): [
  UseMutateFunction<PartyRoles, Error, PartyRoles, unknown>,
  'error' | 'idle' | 'pending' | 'success'
] => {
  const { mutate, status } = useMutation({
    mutationFn: createPartyRole,
    onError: () => {
      message.error('Error while creating the party role!');
    },
    onSuccess: () => {
      message.success('Successfully added party role!');
    },
  });

  return [mutate, status];
};

export const useGetPartyRoles = (
  roleType: PartyRoleType | undefined
): [PartyRoles[], boolean] => {
  const query = useQuery({
    queryKey: [getPartyRolesOfType.name, roleType],
    queryFn: () => {
      return roleType ? getPartyRolesOfType(roleType) : [];
    },
    initialData: [],
    enabled: !!roleType,
  });

  return [query.data, query.isLoading];
};

export const useDeletePartyRole = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: deletePartyRole,
    onError: () => {
      message.error('Error when deleting the party role!');
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [getPartyRolesOfType.name] });
      message.success('Successfully deleted the party role!');
    },
  });

  return mutation;
};

export const useUpdatePartyRole = (): [
  UseMutateFunction<PartyRoles, Error, PartyRoles, unknown>,
  'error' | 'idle' | 'pending' | 'success'
] => {
  const queryClient = useQueryClient();

  const { mutate, status } = useMutation({
    mutationFn: updatePartyRole,
    onError: () => {
      message.error('Error while updating the party role!');
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [getPartyRolesOfType.name],
      });
      message.success('Successfully updated party role!');
    },
  });

  return [mutate, status];
};
