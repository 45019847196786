import React, { useCallback } from 'react';

import { Button, DatePicker, Form } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { Loan } from 'api/types';

const DATE_FORMAT = 'YYYY-MM-DD';

type InvoiceFormProps = {
  isLoading?: boolean;
  loan: Loan | undefined;
  onSubmit: (loanId: number, from: string, to: string) => void;
};

export type FormData = {
  from: Dayjs;
  to: Dayjs;
};

const InvoiceForm: React.FC<InvoiceFormProps> = ({
  isLoading,
  loan,
  onSubmit,
}) => {
  const [form] = Form.useForm<FormData>();

  const handleSubmit = useCallback(
    (values: FormData) => {
      if (loan && loan.id) {
        onSubmit(
          loan.id,
          values.from.format(DATE_FORMAT),
          values.to.format(DATE_FORMAT)
        );
      }
    },
    [loan, onSubmit]
  );

  return (
    <Form
      form={form}
      name="complex-form"
      onFinish={handleSubmit}
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 600 }}
      initialValues={{
        loanId: loan?.id,
        from: dayjs(loan?.startDate),
        to: dayjs(loan?.endDate),
      }}
    >
      <>
        <Form.Item
          label="Start Date"
          name="from"
          rules={[{ required: true, message: 'Please input!' }]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          label="End Date"
          name="to"
          rules={[{ required: true, message: 'Please input!' }]}
        >
          <DatePicker />
        </Form.Item>

        <Form.Item label=" " colon={false}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Submit
          </Button>
        </Form.Item>
      </>
    </Form>
  );
};

export default InvoiceForm;
