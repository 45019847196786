import React, { useCallback, useState } from 'react';

import SupportSharpIcon from '@mui/icons-material/SupportSharp';
import PersonIcon from '@mui/icons-material/Person';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import SettingsIcon from '@mui/icons-material/Settings';

import Footer from './Footer';
import Header from './Header';
import Sidebar, { LinkItem } from './Sidebar';
import classNames from 'classnames';
import { useFormLoginLogout } from 'hooks/withFormLogin';

const links: LinkItem[] = [
  {
    path: '/organisations',
    icon: PersonIcon,
    label: 'Parties',
  },
  {
    path: '/roles',
    icon: PersonIcon,
    label: 'Roles',
  },
  {
    path: '/loans',
    icon: AccountBalanceWalletIcon,
    label: 'Loans',
  },
  {
    path: '/settings',
    icon: SettingsIcon,
    label: 'Settings',
  },
];

const externalLink: LinkItem = {
  path: 'http://github.com/',
  icon: SupportSharpIcon,
  label: 'Documentation',
};
type ChildProp = {
  children: React.ReactNode;
};

const Layout: React.FC<ChildProp> = ({ children }) => {
  const [postFormLogout, isLoadingLogout] = useFormLoginLogout();

  const handleLogOut = useCallback(
    (e) => {
      if (!isLoadingLogout) {
        postFormLogout();
        e.preventDefault();
        sessionStorage.clear();
        window.location.href = '/';
      }
    },
    [isLoadingLogout, postFormLogout]
  );

  const [isOpened, setIsOpened] = useState(false);

  const handleToggleIsOpened = useCallback(() => {
    setIsOpened((value) => !value);
  }, [setIsOpened]);

  return (
    <>
      <Header handleToggleIsOpened={handleToggleIsOpened} isOpened={isOpened} />
      <div className="flex overflow-hidden bg-white pt-16 w-full">
        <Sidebar
          links={links}
          externalLink={externalLink}
          handleLogOut={handleLogOut}
          isOpened={isOpened}
        />
        <div
          className={classNames(
            'bg-gray-900',
            'opacity-50',
            'fixed',
            'inset-0',
            'z-10',
            'lg:hidden',
            { hidden: !isOpened }
          )}
          id="sidebarBackdrop"
          onClick={handleToggleIsOpened}
        ></div>
        <div
          id="main-content"
          className="h-full w-full bg-gray-50 relative overflow-y-auto lg:ml-64"
        >
          <main>{children}</main>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Layout;
