import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useLocation, useNavigate } from 'react-router-dom';

const routesOrder = ['/interest/reference/create', '/interest/reference/list'];

const InterestView = () => {
  const location = useLocation();

  const defaultIndex = routesOrder.indexOf(location.pathname);

  const navigate = useNavigate();
  return (
    <div>
      <div className="card">
        <Tabs
          onSelect={(idx) => {
            navigate(routesOrder[idx]);
          }}
          defaultIndex={defaultIndex === -1 ? 0 : defaultIndex}
        ></Tabs>
      </div>
    </div>
  );
};

export default InterestView;
