import { message } from 'antd';
import {
  addIndividual,
  getIndividuals,
  deleteIndividual,
  updateIndividual,
  getIndividual,
} from 'api/individualClient';
import { Loan, Individual, UnidentifiedIndividual } from 'api/types';

import {
  useQuery,
  useMutation,
  UseMutateFunction,
  useQueryClient,
} from '@tanstack/react-query';

export const useSubmitIndividual = (): [
  UseMutateFunction<Individual, Error, UnidentifiedIndividual, unknown>,
  'error' | 'idle' | 'pending' | 'success'
] => {
  const { mutate, status } = useMutation({
    mutationFn: addIndividual,
    onError: () => {
      message.error('Error while creating the individual!');
    },
    onSuccess: () => {
      message.success('Successfuly added individual!');
    },
  });

  return [mutate, status];
};

export const useGetIndividuals = (
  disabled?: boolean
): [Individual[], boolean] => {
  const query = useQuery({
    queryKey: [getIndividuals.name],
    queryFn: getIndividuals,
    initialData: [],
    enabled: !disabled,
  });

  return [query.data, query.isLoading];
};

export function useGetIndividual(
  identficationNumber: string | undefined
): [Individual | undefined, boolean] {
  const { data, isLoading } = useQuery({
    queryKey: [getIndividual.name, identficationNumber],
    queryFn: () => getIndividual(identficationNumber),
    enabled: !!identficationNumber,
  });
  return [data, isLoading];
}

export const useDeleteIndividual = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: deleteIndividual,
    onError: () => {
      message.error('Error when deleting the individual!');
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [getIndividuals.name] });
      message.success('Successfully deleted the individual!');
    },
  });

  return mutation;
};

export const useUpdateIndividual = (): [
  UseMutateFunction<Individual, Error, Individual, unknown>,
  'error' | 'idle' | 'pending' | 'success'
] => {
  const queryClient = useQueryClient();

  const { mutate, status } = useMutation({
    mutationFn: updateIndividual,
    onError: () => {
      message.error('Error while updating the individual!');
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [getIndividuals.name],
      });
      message.success('Successfully updated individual!');
    },
  });

  return [mutate, status];
};
