import React, { useCallback } from 'react';

import { UnidentifiedOrganisation } from 'api/types';

import { useSubmitOrganisation } from 'hooks/withOrganisation';

import OrganisationForm from 'components/forms/OrganisationForm';

const CreateOrganisationView: React.FC = ({}) => {
  const [handleCreateOrganisation, status] = useSubmitOrganisation();

  const onCreate = useCallback(
    (payload: UnidentifiedOrganisation) => {
      handleCreateOrganisation(payload);
    },
    [handleCreateOrganisation]
  );

  return (
    <div className="mt-10">
      <h1 className="mb-0 py-5">Create Organisation</h1>
      <div className="flex flex-col mt-10">
        <OrganisationForm
          onCreate={onCreate}
          isLoading={status === 'pending'}
        />
      </div>
    </div>
  );
};

export default CreateOrganisationView;
