import React, { useCallback, useState } from 'react';
import { Dayjs } from 'dayjs';
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Tag,
} from 'antd';
import { useGetLenders } from 'hooks/withLender';
import { useSubmitLoan } from 'hooks/withLoan';
import {
  Borrower,
  Broker,
  Building,
  Developer,
  EnergyClass,
  EnvironmentalClassifications,
  FoundationMaterial,
  Interest,
  InternalRisk,
  Lender,
  Loan,
  Name,
  Seniority,
  SocialAspects,
  User,
} from 'api/types';
import RoleInput, {
  CreateRoleRequests,
  LoanRoleType,
} from 'components/forms/RoleInput/RoleInput';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 7 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const { Option } = Select;

const items = ['Low', 'Normal', 'High'];

function riskToTag(risk: string): React.ReactElement {
  let color;
  switch (risk.toUpperCase()) {
    case 'HIGH':
      color = 'red';
      break;
    case 'NORMAL':
      color = 'yellow';
      break;
    case 'LOW':
      color = 'green';
      break;
    default:
      color = 'grey';
  }
  return (
    <Tag color={color} key={risk}>
      {risk}
    </Tag>
  );
}

function riskToEnum(risk: string): InternalRisk {
  switch (risk.toUpperCase()) {
    case 'HIGH':
      return InternalRisk.HIGH;
    case 'NORMAL':
      return InternalRisk.NORMAL;
    case 'LOW':
      return InternalRisk.LOW;
    default:
      return InternalRisk.HIGH;
  }
}

type FormData = {
  name: string;
  lender: number;
  borrower: Borrower;
  broker: Broker;
  developer: Developer;
  customerManager: User;
  customerSupport: User;
  registrationDate: Dayjs;
  startDate: Dayjs;
  endDate: Dayjs;
  type: string;
  interest: Interest;
  defaultingInterest: number;
  limit: number;
  currency: string;
  risk: string;
  building: Building;
};

function getLabelName(name: Name): string {
  if ('firstName' in name) {
    return name.firstName + ' ' + name.lastName;
  } else if ('name' in name) {
    return name.name;
  }
  return 'Name type is not defined';
}

const CreateLoanView: React.FC = () => {
  const [form] = Form.useForm<FormData>();

  const [submittable, setSubmittable] = useState<boolean>(false);

  const [createRoleRequests, setCreateRoleRequests] =
    useState<CreateRoleRequests>({});

  // Watch all values
  const values = Form.useWatch([], form);

  React.useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch((e) => {
        setSubmittable(false);
      });
  }, [form, values]);

  const { data: lenders, isLoading: lendersLoading } = useGetLenders();
  const [submitLoan] = useSubmitLoan();

  const lenderOptions = lenders?.map((lender) => ({
    value: lender.id,
    label: `${getLabelName(lender.party.name)} ${
      lender.party.identificationNumber
    }`,
  }));

  const getLender = useCallback(
    (lenderId: number): Lender | undefined => {
      return lenders?.find((lender) => lender.id == lenderId);
    },
    [lenders]
  );

  const [selectedCurrency, setSelectedCurrency] = useState('SEK');

  const formatCurrencySwedish = useCallback(
    (value) => {
      return new Intl.NumberFormat('sv-SE', {
        style: 'currency',
        currency: selectedCurrency,
      })
        .format(value)
        .replace('kr', '');
    },
    [selectedCurrency]
  );

  const handleSubmit = useCallback(
    (data: FormData) => {
      if (createRoleRequests) {
        const request: Loan = {
          name: data.name,
          lender: getLender(data.lender),
          borrower: createRoleRequests.borrower,
          broker: createRoleRequests.broker,
          developer: createRoleRequests.developer,
          customerManager: createRoleRequests.customerManager,
          customerSupport: createRoleRequests.customerSupport,
          type: data.type,
          interest: {
            accruedInterestPeriod: data.interest.accruedInterestPeriod,
            capitalised: data.interest.capitalised,
            interest: data.interest.interest,
          },
          defaultingInterest: data.defaultingInterest,
          limit: data.limit,
          currency: data.currency,
          registrationDate: data.registrationDate.toJSON(),
          startDate: data.startDate.toJSON(),
          endDate: data.endDate.toJSON(),
          risk: riskToEnum(data.risk),
          building: {
            completed: data.building.completed,
            numberOfApartments: data.building.numberOfApartments,
            seniority: data.building.seniority,
            environmentalClassifications:
              data.building.environmentalClassifications,
            foundationMaterial: data.building.foundationMaterial,
            energyClass: data.building.energyClass,
            socialAspects: data.building.socialAspects,
            renovation: data.building.renovation,
          },
        };
        submitLoan(request);
      }
    },
    [createRoleRequests, getLender, submitLoan]
  );

  return (
    <div className="mt-10">
      <h1 className="mb-10 py-5">Create loan</h1>
      <Form
        {...formItemLayout}
        autoComplete="false"
        form={form}
        onFinish={handleSubmit}
        style={{ width: '600px' }}
        initialValues={{
          type: 'REVERSE',
          interest: {
            interest: 0.01,
            capitalised: true,
          },
          limit: 1000,
          currency: 'SEK',
        }}
      >
        <Form.Item
          label="Lender"
          name="lender"
          rules={[{ required: true, message: 'Please select a lender!' }]}
        >
          <Select
            showSearch
            placeholder="Company name / org-number"
            loading={lendersLoading}
            filterOption
            options={lenderOptions}
            optionFilterProp="label"
          />
        </Form.Item>

        {/* Borrower Role */}
        <RoleInput
          form={form}
          label="Borrower"
          loanRoleType={LoanRoleType.BORROWER}
          required={true}
          setCreateRoleRequests={setCreateRoleRequests}
          initialData={createRoleRequests}
        />

        {/* Broker Role */}
        <RoleInput
          form={form}
          label="Broker"
          loanRoleType={LoanRoleType.BROKER}
          required={false}
          setCreateRoleRequests={setCreateRoleRequests}
          initialData={createRoleRequests}
        />

        {/* Developer Role */}
        <RoleInput
          form={form}
          label="Developer"
          loanRoleType={LoanRoleType.DEVELOPER}
          required={false}
          setCreateRoleRequests={setCreateRoleRequests}
          initialData={createRoleRequests}
        />

        {/* Customer Manager Role */}
        <RoleInput
          form={form}
          label="Customer Manager"
          loanRoleType={LoanRoleType.CUSTOMER_MANAGER}
          required={false}
          setCreateRoleRequests={setCreateRoleRequests}
          initialData={createRoleRequests}
        />

        {/* Customer Support Role */}
        <RoleInput
          form={form}
          label="Customer Support"
          loanRoleType={LoanRoleType.CUSTOMER_SUPPORT}
          required={false}
          setCreateRoleRequests={setCreateRoleRequests}
          initialData={createRoleRequests}
        />

        <Form.Item
          label="Loan Name"
          name="name"
          required
          rules={[{ required: false, message: 'Please input!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Loan Limit"
          name="limit"
          rules={[{ required: true, message: 'Please input the loan limit!' }]}
        >
          <InputNumber
            style={{ width: '100%' }}
            min={0}
            decimalSeparator=","
            formatter={formatCurrencySwedish}
          />
        </Form.Item>
        <Form.Item
          label="Currency"
          name="currency"
          rules={[{ required: true, message: 'Please select the currency!' }]}
        >
          <Select
            showSearch
            filterOption={true}
            options={[
              { label: 'SEK', value: 'SEK' },
              { label: 'EUR', value: 'EUR' },
            ]}
            optionFilterProp="label"
            onChange={(value) => setSelectedCurrency(value)}
          />
        </Form.Item>
        <Form.Item
          label="Loan Type"
          name="type"
          rules={[{ required: true, message: 'Please select a loan type!' }]}
        >
          <Select placeholder="Select a loan type">
            <Select.Option value="REVERSE">REVERSE</Select.Option>
            <Select.Option value="BUILDING_CREDIT">
              BUILDING CREDIT
            </Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Capitalised"
          name={['interest', 'capitalised']}
          rules={[{ required: true, message: 'Please make a selection!' }]}
        >
          <Radio.Group>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label="Risk"
          name="risk"
          rules={[{ required: true, message: 'Please select loan risk!' }]}
        >
          <Select
            placeholder="Please select loan risk!"
            style={{ width: '100%' }}
          >
            {items.map((item) => (
              <Option key={item} value={item} label={item}>
                {riskToTag(item)}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Interest"
          name={['interest', 'interest']}
          rules={[{ required: true, message: 'Choose interest!' }]}
        >
          <InputNumber style={{ width: '100%' }} min={0} step={0.1} />
        </Form.Item>
        <Form.Item
          label="Defaulting Interest"
          name={'defaultingInterest'}
          rules={[{ required: true, message: 'Choose defaulting interest!' }]}
        >
          <InputNumber style={{ width: '100%' }} min={0} step={0.1} />
        </Form.Item>
        <Form.Item
          label="Registration Date"
          name="registrationDate"
          rules={[{ required: true, message: 'Please input!' }]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          label="Start Date"
          name="startDate"
          rules={[{ required: true, message: 'Please input!' }]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          label="End Date"
          name="endDate"
          rules={[{ required: true, message: 'Please input!' }]}
        >
          <DatePicker />
        </Form.Item>

        <Form.Item
          label="Accrued Interest Period"
          name={['interest', 'accruedInterestPeriod']}
          rules={[
            { required: true, message: 'Please select an interest period!' },
          ]}
        >
          <Select placeholder="Select an interest period">
            <Select.Option value="MONTH">MONTH</Select.Option>
            <Select.Option value="QUARTER">QUARTER</Select.Option>
            <Select.Option value="YEAR">YEAR</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Is the building complete"
          name={['building', 'completed']}
          rules={[{ required: true, message: 'Please make a selection!' }]}
        >
          <Radio.Group>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label="Number of Appartments"
          name={['building', 'numberOfApartments']}
          rules={[{ required: true, message: 'Number of Appartments!' }]}
        >
          <InputNumber style={{ width: '100%' }} min={0} step={1} />
        </Form.Item>

        <Form.Item
          label="Seniority"
          name={['building', 'seniority']}
          rules={[{ required: true, message: 'Please make a selection!' }]}
        >
          <Radio.Group>
            <Radio value={Seniority.JUNIOR}>Junior</Radio>
            <Radio value={Seniority.SENIOR}>Senior</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label="Classifications"
          name={['building', 'environmentalClassifications']}
          rules={[{ required: true, message: 'Please make a selection!' }]}
        >
          <Select
            mode="multiple"
            options={Object.entries(EnvironmentalClassifications).map(
              ([value, name]) => ({
                label: name,
                value: value,
              })
            )}
          />
        </Form.Item>

        <Form.Item
          label="Material"
          name={['building', 'foundationMaterial']}
          rules={[{ required: true, message: 'Please make a selection!' }]}
        >
          <Radio.Group>
            <Radio value={FoundationMaterial.NOT_WOOD}>Not Wood</Radio>
            <Radio value={FoundationMaterial.WOOD}>Wood</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label="Energy Classification"
          name={['building', 'energyClass']}
          rules={[{ required: true, message: 'Please make a selection!' }]}
        >
          <Select style={{ width: '100%' }}>
            {Object.entries(EnergyClass).map(([value, name]) => (
              <Option key={value} value={value} label={name}>
                {value}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Social Aspects"
          name={['building', 'socialAspects']}
          rules={[{ required: true, message: 'Please make a selection!' }]}
        >
          <Select
            mode="multiple"
            options={Object.entries(SocialAspects).map(([value, name]) => ({
              label: name,
              value: value,
            }))}
          />
        </Form.Item>

        <Form.Item
          label="Renovation"
          name={['building', 'renovation']}
          rules={[{ required: true, message: 'Please make a selection!' }]}
        >
          <Radio.Group>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 7, span: 16 }}>
          <Button type="primary" htmlType="submit" disabled={!submittable}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateLoanView;
