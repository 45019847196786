import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Spin } from 'antd';
import { StyleProvider } from '@ant-design/cssinjs';

import Layout from 'components/Layout';
import OrganisationView from 'views/Party/Organisation';
import LoansView from 'views/Loans';
import LedgerView from 'views/Journal';
import InvoiceView from 'views/Invoice';
import WelcomeView from 'views/Welcome';
import InterestView from 'views/Interest';
import SettingView from 'views/Setting';
import LoginView from 'views/Login/Login';
import RolesView from 'views/Roles';

import { useAuthentication } from 'hooks/withAuthentication';

const App: React.FC = () => {
  const [isAuthenticated, isAuthenticatedLoading] = useAuthentication();

  if (isAuthenticatedLoading) {
    return (
      <Spin
        size="large"
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      />
    );
  }

  return (
    <StyleProvider hashPriority="high">
      {isAuthenticated ? (
        <Layout>
          <Routes>
            <Route path="/" element={<WelcomeView />} />
            <Route path="/organisations/*" element={<OrganisationView />} />
            <Route path="/roles/*" element={<RolesView />} />
            <Route path="/loans/*" element={<LoansView />} />
            <Route path="/settings/*" element={<SettingView />} />
            <Route path="/interest/*" element={<InterestView />} />
            <Route path="/ledger/*" element={<LedgerView />} />
            <Route path="/invoice/*" element={<InvoiceView />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Layout>
      ) : (
        <Routes>
          <Route path="/login" element={<LoginView />} />
          <Route path="/login/:userId" element={<LoginView />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      )}
    </StyleProvider>
  );
};

export default App;
