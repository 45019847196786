import { Setting, UnidentifiedSetting } from 'api/types';
import { useCreateSetting, useUpdateSetting } from 'hooks/withSetting';
import React, { useCallback } from 'react';
import SettingForm from 'components/forms/SettingForm';

type CreateSettingViewProps = {
  initialData?: Setting;
};

const CreateSettingView: React.FC<CreateSettingViewProps> = ({
  initialData,
}) => {
  const [handleCreate, createSettingStatus] = useCreateSetting();
  const [handleUpdate, updateSettingStatus] = useUpdateSetting();

  const onCreate = useCallback(
    (setting: UnidentifiedSetting) => {
      handleCreate(setting);
    },
    [handleCreate]
  );

  const onUpdate = useCallback(
    (setting: Setting) => {
      handleUpdate(setting);
    },
    [handleUpdate]
  );

  return (
    <div className="mt-10">
      <h1 className="mb-0 py-5">Create setting</h1>
      <div className="flex flex-col mt-10">
        <SettingForm
          onCreate={onCreate}
          onUpdate={onUpdate}
          initialData={initialData}
        />
      </div>
    </div>
  );
};
export default CreateSettingView;
