import {
  Borrower,
  Broker,
  Developer,
  Lender,
  Loan,
  PartyRoles,
  PartyRoleType,
  User,
} from 'api/types';
import {
  Button,
  Form,
  FormInstance,
  Input,
  Modal,
  Popconfirm,
  Space,
} from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useCallback, useState } from 'react';
import RoleForm from 'components/forms/RoleForm';

export enum LoanRoleType {
  BORROWER = 'BORROWER',
  BROKER = 'BROKER',
  DEVELOPER = 'DEVELOPER',
  CUSTOMER_MANAGER = 'CUSTOMER_MANAGER',
  CUSTOMER_SUPPORT = 'CUSTOMER_SUPPORT',
}

export function createCreateRoleRequest(
  loan: Loan | undefined
): CreateRoleRequests {
  if (loan) {
    return {
      borrower: loan.borrower,
      broker: loan.broker,
      customerManager: loan.customerManager,
      customerSupport: loan.customerSupport,
      developer: loan.developer,
      lender: loan.lender,
    };
  } else {
    throw new Error('No Loan is selected!');
  }
}

export type CreateRoleRequests = {
  borrower?: Borrower | undefined;
  broker?: Broker | undefined;
  customerManager?: User | undefined;
  customerSupport?: User | undefined;
  developer?: Developer | undefined;
  lender?: Lender | undefined;
};

type RoleInputProps = {
  form: FormInstance;
  label: string;
  loanRoleType: LoanRoleType;
  required: boolean;
  setCreateRoleRequests: React.Dispatch<
    React.SetStateAction<CreateRoleRequests>
  >;
  initialData: CreateRoleRequests | undefined;
};

const RoleInput: React.FC<RoleInputProps> = ({
  form,
  label,
  loanRoleType,
  required,
  setCreateRoleRequests,
  initialData,
}) => {
  const [modalWindowOpen, setModalWindowOpen] = useState<boolean>(false);

  const getSelectedCreateRoleRequest = useCallback(():
    | PartyRoles
    | undefined => {
    switch (loanRoleType) {
      case LoanRoleType.BORROWER:
        return initialData?.borrower;
      case LoanRoleType.BROKER:
        return initialData?.broker;
      case LoanRoleType.DEVELOPER:
        return initialData?.developer;
      case LoanRoleType.CUSTOMER_MANAGER:
        return initialData?.customerManager;
      case LoanRoleType.CUSTOMER_SUPPORT:
        return initialData?.customerSupport;
    }
  }, [initialData, loanRoleType]);

  const getSelectedPartyName = useCallback((): string | undefined => {
    const selectedCreateRoleRequest = getSelectedCreateRoleRequest();
    const partyName = selectedCreateRoleRequest?.party?.name.name;
    const partyId = selectedCreateRoleRequest?.party?.identificationNumber;

    return selectedCreateRoleRequest && partyName && partyId
      ? partyName + ' ' + partyId
      : undefined;
  }, [getSelectedCreateRoleRequest]);

  const getSelectedPartyNameFromData = useCallback(
    (data: PartyRoles | undefined): string | undefined => {
      const partyName = data?.party?.name.name;
      const partyId = data?.party?.identificationNumber;

      return data ? partyName + ' ' + partyId : undefined;
    },
    []
  );

  const onCreateRoleClicked = useCallback(() => {
    setModalWindowOpen(true);
  }, []);

  const setCreateRoleRequestData = useCallback(
    (data: PartyRoles | undefined) => {
      //Required for Form validation
      form.setFieldValue(loanRoleType, getSelectedPartyNameFromData(data));
      setCreateRoleRequests((prevState) => {
        switch (loanRoleType) {
          case LoanRoleType.BORROWER:
            return { ...prevState, borrower: data as Borrower };
          case LoanRoleType.BROKER:
            return { ...prevState, broker: data as Broker };
          case LoanRoleType.DEVELOPER:
            return { ...prevState, developer: data as Developer };
          case LoanRoleType.CUSTOMER_MANAGER:
            return { ...prevState, customerManager: data as User };
          case LoanRoleType.CUSTOMER_SUPPORT:
            return { ...prevState, customerSupport: data as User };
        }
      });
    },
    [form, getSelectedPartyNameFromData, loanRoleType, setCreateRoleRequests]
  );

  const onCreateRoleRequest = useCallback(
    (data: PartyRoles) => {
      setCreateRoleRequestData(data);
    },
    [setCreateRoleRequestData]
  );

  const onDeleteRole = useCallback(() => {
    setCreateRoleRequestData(undefined);
  }, [setCreateRoleRequestData]);

  const onClose = useCallback(() => {
    setModalWindowOpen(false);
  }, []);

  /* const getPartyRoleRequestByType = useCallback(():
    | PartyRoles
    | undefined => {
    const selectedCreateRoleRequest = getSelectedCreateRoleRequest();
    return selectedCreateRoleRequest?.request;
  }, [getSelectedCreateRoleRequest]);*/

  const modalTitle = useCallback((): string => {
    let role;
    switch (loanRoleType) {
      case LoanRoleType.BORROWER:
        role = 'borrower';
        break;
      case LoanRoleType.BROKER:
        role = 'broker';
        break;
      case LoanRoleType.DEVELOPER:
        role = 'developer';
        break;
      case LoanRoleType.CUSTOMER_MANAGER:
        role = 'customer manager';
        break;

      case LoanRoleType.CUSTOMER_SUPPORT:
        role = 'customer support';
        break;
      default:
        role = '-';
    }
    return 'Create' + ' ' + role;
  }, [loanRoleType]);

  const getPartyRoleTypeFromLoanRoleType = useCallback(() => {
    switch (loanRoleType) {
      case LoanRoleType.BORROWER:
        return PartyRoleType.BORROWER;
      case LoanRoleType.BROKER:
        return PartyRoleType.BROKER;
      case LoanRoleType.DEVELOPER:
        return PartyRoleType.DEVELOPER;
      case LoanRoleType.CUSTOMER_MANAGER:
        return PartyRoleType.USER;
      case LoanRoleType.CUSTOMER_SUPPORT:
        return PartyRoleType.USER;
    }
  }, [loanRoleType]);

  return (
    <div>
      <Form.Item
        label={label}
        name={loanRoleType}
        rules={[{ required: required, message: `Create ${label}` }]}
      >
        <Space>
          <Input
            disabled={true}
            value={getSelectedPartyName()}
            style={{ width: '350px' }}
          />
          {getSelectedPartyName() === undefined ? (
            <Button type="link" onClick={() => onCreateRoleClicked()}>
              <PlusOutlined />
            </Button>
          ) : (
            <>
              <Button type="link" onClick={() => onCreateRoleClicked()}>
                <EditOutlined />
              </Button>
              <Popconfirm
                title="Please confirm"
                description="Are you sure you want to delete this role?"
                onConfirm={() => onDeleteRole()}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link">
                  <DeleteOutlined style={{ color: 'red' }} />
                </Button>
              </Popconfirm>
            </>
          )}
        </Space>
      </Form.Item>{' '}
      {/*Create Role*/}
      <Modal
        open={modalWindowOpen}
        onCancel={onClose}
        width={800}
        footer={null}
        destroyOnClose
        title={modalTitle()}
      >
        <RoleForm
          initialData={getSelectedCreateRoleRequest()}
          partyRoleType={getPartyRoleTypeFromLoanRoleType()}
          onCreateOrUpdate={onCreateRoleRequest}
          isUpdate={getSelectedPartyName() != undefined}
          callback={onClose}
        />
      </Modal>
    </div>
  );
};

export default RoleInput;
