import React from 'react';

const CreateAccountView: React.FC = () => {
  return (
    <div className="mt-10">
      <h1 className="mb-0 py-5">Create a debit/credit account</h1>
      <div className="flex flex-col mt-5">Create account form</div>
    </div>
  );
};

export default CreateAccountView;
