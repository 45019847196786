import axios from 'axios';

import { ENDPOINT_BASE } from 'constants/index';
import { CreateInvoiceRequest } from './types';

const INVOICE_PATH = 'invoice';
const baseUrl = `${ENDPOINT_BASE}`;

const client = axios.create({
  withCredentials: true,
  baseURL: baseUrl,
  responseType: 'blob',
});

export async function getInvoice(request: CreateInvoiceRequest): Promise<Blob> {
  if (!request) {
    throw new Error('request is missing');
  }
  const response = await client.get(
    `${INVOICE_PATH}?loanId=${request.loanId}&from=${request.from}&to=${request.to}`,
    {
      responseType: 'blob',
    }
  );
  return response.data;
}
