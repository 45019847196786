import { Loan, LoanDetails } from 'api/types';
import React, { useCallback } from 'react';
import { Form, Input } from 'antd';

type LoanDetailsProps = {
  loan: Loan | undefined;
  loanDetails: LoanDetails | undefined;
};

const readonlyStyle = { backgroundColor: 'rgb(240,240,240)' };

const LoanDetailInterest: React.FC<LoanDetailsProps> = ({
  loan,
  loanDetails,
}) => {
  const formatCurrencySwedish = useCallback(
    (value) => {
      return new Intl.NumberFormat('sv-SE', {
        style: 'currency',
        currency: loan ? loan.currency : 'SEK',
      })
        .format(value)
        .replace('kr', '');
    },
    [loan]
  );

  return (
    <>
      <Form.Item label="Current Limit">
        <Input
          readOnly
          style={readonlyStyle}
          value={formatCurrencySwedish(loan?.limit)}
        />
      </Form.Item>
      <Form.Item label="Original Limit">
        <Input
          readOnly
          style={readonlyStyle}
          value={formatCurrencySwedish(loanDetails?.originalLimit)}
        />
      </Form.Item>
      <Form.Item
        name={['loan', 'interest', 'interest']}
        label="Current Interest"
        initialValue={loan?.interest.interest}
      >
        <Input readOnly style={readonlyStyle} />
      </Form.Item>
      <Form.Item
        name={['loanDetails', 'originalInterest']}
        label="Original Interest"
        initialValue={loanDetails?.originalInterest}
      >
        <Input readOnly style={readonlyStyle} />
      </Form.Item>
      <Form.Item label="Interest Calculating Method">
        <Input readOnly style={readonlyStyle} value={'Actual / 365'} />
      </Form.Item>
      <Form.Item
        //name={['loan', 'limit']}
        label="Original STIBOR"
        //initialValue={loan?.limit}
      >
        <Input readOnly style={readonlyStyle} value={'Not implemented'} />
      </Form.Item>
      <Form.Item
        //name={['loan', 'limit']}
        label="Current STIBOR"
        //initialValue={loan?.limit}
      >
        <Input readOnly style={readonlyStyle} value={'Not implemented'} />
      </Form.Item>
      <Form.Item label="Current Setup Fee">
        <Input
          readOnly
          style={readonlyStyle}
          value={formatCurrencySwedish(loan?.setupFee)}
        />
      </Form.Item>
      <Form.Item label="Original Setup Fee">
        <Input readOnly style={readonlyStyle} value={'Not implemented'} />
      </Form.Item>
      <Form.Item
        name={['loan', 'interest', 'capitalised']}
        label="Capitalised"
        initialValue={loan?.interest.capitalised}
      >
        <Input readOnly style={readonlyStyle} />
      </Form.Item>
      <Form.Item
        name={['loan', 'interest', 'accruedInterestPeriod']}
        label="Accured Interest Period"
        initialValue={loan?.interest.accruedInterestPeriod}
      >
        <Input readOnly style={readonlyStyle} />
      </Form.Item>
      <Form.Item
        //name={['loan', 'limit']}
        label="Capitalised Period"
        //initialValue={loan?.limit}
      >
        <Input readOnly style={readonlyStyle} value={'Not implemented'} />
      </Form.Item>
    </>
  );
};

export default LoanDetailInterest;
