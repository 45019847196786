import React from 'react';

const CreateInterestInvoiceView: React.FC = () => {
  return (
    <div className="mt-10">
      <h1 className="mb-0 py-5">Create Interest Invoice</h1>
      <div className="flex flex-col mt-5">
        An interest invoice can be created at any time during the month.
        Depending on the accrued interest period selected, it will span a month,
        a quarter or a year.
      </div>
    </div>
  );
};

export default CreateInterestInvoiceView;
