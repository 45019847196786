import React, { useCallback, useState } from 'react';
import { Button, Form, Radio, RadioChangeEvent } from 'antd';
import { LAYOUT, LAYOUT_TAIL } from 'constants/index';
import EmailContactForm from './EmailContactForm';
import PostalContactForm from './PostalContactForm';
import TelephoneContactForm from './TelephoneContactForm';
import {
  Country,
  ContactMedium,
  ContactMediumType,
  EmailContact,
  PostalContact,
  TelephoneContact,
  NamedEmailContact,
  NamedPostalContact,
  NamedTelephoneContact,
  BankContact,
  WebContact,
} from 'api/types';
import BankContactForm from 'components/drawer/ContactMediumModal/BankContactForm';
import WebContactForm from 'components/drawer/ContactMediumModal/WebContactForm';

type ContactMediumModalProps = {
  onSubmit: (data: ContactMedium) => void;
  initialData?: ContactMedium | undefined;
  allowedContactMediaTypes: ContactMediumType[];
};

const ContactMediumModal: React.FC<ContactMediumModalProps> = ({
  onSubmit,
  initialData,
  allowedContactMediaTypes,
}) => {
  const [form] = Form.useForm();

  const [type, setType] = useState<string>(() => {
    return initialData?.type ?? allowedContactMediaTypes[0];
  });

  /** Handles the submit button and recreates a complete contact medium according to the type before submitting to parent  */
  const handleSubmitButton = useCallback(
    (data: ContactSubmitType) => {
      switch (type) {
        case ContactMediumType.EMAIL:
          onSubmit({
            ...data,
            type,
          } as EmailContact);
          break;
        case ContactMediumType.POSTAL:
          onSubmit({
            ...data,
            type,
          } as PostalContact);
          break;
        case ContactMediumType.TELEPHONE:
          onSubmit({
            ...data,
            type,
          } as TelephoneContact);
          break;
        case ContactMediumType.NAMED_EMAIL:
          onSubmit({
            ...data,
            type,
          } as NamedEmailContact);
          break;
        case ContactMediumType.NAMED_POSTAL:
          onSubmit({
            ...data,
            type,
          } as NamedPostalContact);
          break;
        case ContactMediumType.NAMED_TELEPHONE:
          onSubmit({
            ...data,
            type,
          } as NamedTelephoneContact);
          break;
        case ContactMediumType.BANK:
          onSubmit({
            ...data,
            type,
          } as BankContact);
          break;
        case ContactMediumType.WEB:
          onSubmit({
            ...data,
            type,
          } as WebContact);
          break;
      }
    },
    [onSubmit, type]
  );

  /** Handles the type selection done when the user chooses which contact medium type to create or edit */
  const handleSetType = useCallback((event: RadioChangeEvent) => {
    const value = event?.target?.value;
    if (event?.target?.value) {
      setType(value);
    }
  }, []);

  return (
    <Form
      {...LAYOUT}
      form={form}
      onFinish={handleSubmitButton}
      autoComplete="off"
      initialValues={initialData}
    >
      {(() => {
        /** When the contact medium is undefined the user an choose either of the contact mediums types to create */
        if (initialData === undefined) {
          return (
            <Form.Item
              {...LAYOUT}
              label="Type"
              //initialValue={ContactMediumType.TELEPHONE}
            >
              <Radio.Group onChange={handleSetType} value={type}>
                {allowedContactMediaTypes.map((contactType) => (
                  <Radio.Button
                    value={contactType.valueOf()}
                    key={contactType.valueOf()}
                  >
                    {contactType}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </Form.Item>
          );
        }
        return null;
      })()}
      {(() => {
        switch (type) {
          case ContactMediumType.EMAIL: {
            return <EmailContactForm named={false} />;
          }
          case ContactMediumType.POSTAL: {
            return <PostalContactForm named={false} />;
          }
          case ContactMediumType.TELEPHONE: {
            return <TelephoneContactForm named={false} />;
          }
          case ContactMediumType.NAMED_EMAIL: {
            return <EmailContactForm named={true} />;
          }
          case ContactMediumType.NAMED_POSTAL: {
            return <PostalContactForm named={true} />;
          }
          case ContactMediumType.NAMED_TELEPHONE: {
            return <TelephoneContactForm named={true} />;
          }
          case ContactMediumType.BANK: {
            return <BankContactForm named={false} />;
          }
          case ContactMediumType.WEB: {
            return <WebContactForm named={false} />;
          }
        }
        return null;
      })()}
      <Form.Item {...LAYOUT_TAIL}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export type ContactSubmitType = {
  name: string;

  address: string;

  street: {
    name: string;
    segment: string;
  };

  city: {
    name: string;
  };

  stateOrProvince: {
    name: string;
  };

  postalCode: {
    code: string;
  };

  country: Country;

  number: string;
  numberType: string;

  id: number;
  type: string;

  bic: string;
  iban: string;
  bankGiro: string;
};

export default ContactMediumModal;
