import axios from 'axios';

import { ENDPOINT_BASE } from 'constants/index';
import { Individual, Organisation, UnidentifiedOrganisation } from './types';

const ORGANISATION_PATH = 'organisation';
const baseUrl = `${ENDPOINT_BASE}${ORGANISATION_PATH}`;

const client = axios.create({
  withCredentials: true,
  baseURL: baseUrl,
});

export async function addOrganisation(
  request: UnidentifiedOrganisation
): Promise<Organisation> {
  const response = await client.post<Organisation>('', request);

  return response.data;
}

export async function getOrganisation(
  identificationNumber: string | undefined
): Promise<Organisation> {
  if (!identificationNumber) {
    throw new Error('IdentificationNumber missing');
  }
  const response = await client.get<Organisation>(`/${identificationNumber}`);
  return response.data;
}

export async function getOrganisations(): Promise<Organisation[]> {
  const response = await client.get<Organisation[]>('');
  return response.data;
}

export async function deleteOrganisation(id: string): Promise<void> {
  await client.delete(`/${id}`);
}

export async function updateOrganisation(
  organisation: Organisation
): Promise<Organisation> {
  const response = await client.put<Organisation>('', organisation);
  return response.data;
}
