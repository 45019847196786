import { useCallback, useState } from 'react';

const usePagination = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const handleChange = useCallback((newPage: number, newPageSize: number) => {
    setPage((currentPage: number) => {
      if (currentPage !== newPage) {
        return newPage;
      }

      return currentPage;
    });
    setPageSize((currentPageSize: number) => {
      if (currentPageSize !== newPageSize) {
        return newPageSize;
      }

      return currentPageSize;
    });
  }, []);

  return { handleChangePage: handleChange, page, size: pageSize };
};

export default usePagination;
