import React from 'react';

import FormLogin from './FormLogin';

const LoginView: React.FC = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 w-full">
      <div className="px-8 py-6 mt-4 text-left bg-white shadow-lg max-w-xl w-full">
        <h1 className="h-full w-full flex leading-none mb-4">Log In</h1>
        <FormLogin />
      </div>
    </div>
  );
};

export default LoginView;
